/**
 * @since 0.5.0
 */
import * as t from 'io-ts';
import { pipe } from 'fp-ts/es6/pipeable';
import { chain } from 'fp-ts/es6/Either';
/**
 * @example
 * import { DateFromUnixTime } from 'io-ts-types/es6/DateFromUnixTime'
 * import { right } from 'fp-ts/es6/Either'
 *
 * const date = new Date(1973, 10, 30)
 * const input = date.getTime() / 1000
 * assert.deepStrictEqual(DateFromUnixTime.decode(input), right(date))
 *
 * @since 0.5.0
 */
export var DateFromUnixTime = new t.Type('DateFromUnixTime', function (u) { return u instanceof Date; }, function (u, c) {
    return pipe(t.Int.validate(u, c), chain(function (n) {
        var d = new Date(n * 1000);
        return isNaN(d.getTime()) ? t.failure(u, c) : t.success(d);
    }));
}, function (a) { return Math.floor(a.getTime() / 1000); });
