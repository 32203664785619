/**
 * @since 0.5.0
 */
import * as t from 'io-ts';
import { pipe } from 'fp-ts/es6/pipeable';
import { chain } from 'fp-ts/es6/Either';
/**
 * @example
 * import { BooleanFromString } from 'io-ts-types/es6/BooleanFromString'
 * import { right } from 'fp-ts/es6/Either'
 * import { PathReporter } from 'io-ts/es6/PathReporter'
 *
 * assert.deepStrictEqual(BooleanFromString.decode('true'), right(true))
 * assert.deepStrictEqual(BooleanFromString.decode('false'), right(false))
 * assert.deepStrictEqual(PathReporter.report(BooleanFromString.decode('a')), ['Invalid value "a" supplied to : BooleanFromString'])
 *
 * @since 0.5.0
 */
export var BooleanFromString = new t.Type('BooleanFromString', t.boolean.is, function (u, c) {
    return pipe(t.string.validate(u, c), chain(function (s) { return (s === 'true' ? t.success(true) : s === 'false' ? t.success(false) : t.failure(u, c)); }));
}, String);
