import { BasicCalculator } from './basicCalculator';
import { ExtendedCalculator } from './extendedCalculator';
import { HasFromNumber } from './fromNumber';
import { HasToNumber } from './toNumber';
import * as E from 'fp-ts/lib/Either';
import * as t from 'io-ts';

export type ScaledNumber = {
  /**
   * Scaled numeric value (integer), ex: 100 for 1.00 when {@link scale} is 2 (value / 10^scale)
   * TODO: use int type
   */
  readonly value: number;
  /**
   * Scale of the {@link value}, ex: 2 for 1.00
   * @default 0
   */
  readonly scale?: number;
};

export type ScaledNumberZero = {
  readonly value: 0;
  readonly scale?: number;
};

const ONE: ScaledNumber = {
  value: 1,
};

export const ScaledNumberZero = {
  JSON: t.intersection(
    [
      t.type(
        {
          value: t.literal(0),
        },
        '!',
      ),
      t.partial(
        {
          scale: t.number,
        },
        '?',
      ),
    ],
    'ScaledNumberZero',
  ),
};

export type ScaledNumberModule = ExtendedCalculator<ScaledNumber> &
  HasFromNumber<ScaledNumber> &
  HasToNumber<ScaledNumber> & {
    /**
     * Find min value from given list
     * @param a at least one value is required
     * @param rest optional other values to compare
     * @returns minimal {@link ScaledNumber} value
     */
    readonly min: (a: ScaledNumber, ...rest: ScaledNumber[]) => ScaledNumber;
    /**
     * Find max value from given list
     * @param a at least one value is required
     * @param rest optional other values to compare
     * @returns maximal {@link ScaledNumber} value
     */
    readonly max: (a: ScaledNumber, ...rest: ScaledNumber[]) => ScaledNumber;
    /**
     * Create {@link ScaledNumber} from an integer value
     * if {@link value} is not an integer, it will be rounded
     * @returns {@link ScaledNumber} with {@link scale} = 0
     */
    readonly fromInteger: (value: number) => ScaledNumber;
    /**
     * Convert a {@link ScaledNumber} value to a string representation
     * WARNING: this is not a UI formatting function, is used for serialization and is dual for {@link fromStr}
     */
    readonly toStr: (value: ScaledNumber) => string;
    /**
     * Try to parse a {@link ScaledNumber} from a string representation (see {@link fromStr})
     */
    readonly fromStr: (str: string) => E.Either<Error, ScaledNumber>;
    /**
     * JSON codec for {@link ScaledNumber}
     */
    readonly JSON: t.Type<ScaledNumber, ScaledNumber, unknown>;
    /**
     * 1
     */
    readonly ONE: ScaledNumber;
  };

const ZERO: ScaledNumber = {
  value: 0,
  scale: 0,
};

const zero = (): ScaledNumber => ZERO;

const add = (a: ScaledNumber, b: ScaledNumber): ScaledNumber => {
  const aScale: number = a.scale ?? 0;
  const bScale: number = b.scale ?? 0;
  const scale: number = Math.max(aScale, bScale);

  const scaledA = aScale === scale ? a.value : a.value * Math.pow(10, scale - aScale);
  const scaledB = bScale === scale ? b.value : b.value * Math.pow(10, scale - bScale);

  return {
    value: scaledA + scaledB,
    scale,
  };
};

const sub = (a: ScaledNumber, b: ScaledNumber): ScaledNumber => {
  const aScale: number = a.scale ?? 0;
  const bScale: number = b.scale ?? 0;
  const scale: number = Math.max(aScale, bScale);

  const scaledA = aScale === scale ? a.value : a.value * Math.pow(10, scale - aScale);
  const scaledB = bScale === scale ? b.value : b.value * Math.pow(10, scale - bScale);

  return {
    value: scaledA - scaledB,
    scale,
  };
};

const mul = (a: ScaledNumber, b: ScaledNumber): ScaledNumber => {
  const aScale: number = a.scale ?? 0;
  const bScale: number = b.scale ?? 0;
  const scale: number = aScale + bScale;

  return {
    value: Math.round(a.value * b.value),
    scale,
  };
};

/**
 * Divide value {@link a} by {@link b} rounding value to {@link decimals}
 */
const div = (a: ScaledNumber, b: ScaledNumber, decimals: number): ScaledNumber => {
  return {
    value: Math.round((toNumber(a) / toNumber(b)) * Math.pow(10, decimals)),
    scale: decimals,
  };
};

/**
 * Round {@link ScaledNumber} to a given scale.
 * If new {@link decimals} is greater than {@link a.scale}, the value will be multiplied to match new scale
 * If new {@link decimals} is lower than {@link a.scale}, the value will be divided and rounded to match new scale
 */
const round = (a: ScaledNumber, decimals: number): ScaledNumber => {
  const aScale: number = a.scale ?? 0;
  if (aScale === decimals) {
    return a;
  } else if (aScale > decimals) {
    return {
      value: Math.round(a.value / Math.pow(10, aScale - decimals)),
      scale: decimals,
    };
  } else {
    return {
      value: a.value * Math.pow(10, decimals - aScale),
      scale: decimals,
    };
  }
};

/**
 * Compare two {@link ScaledNumber} values
 * @returns -1 if a < b, 0 if a === b, 1 if a > b
 */
const compare = (a: ScaledNumber, b: ScaledNumber): -1 | 0 | 1 => {
  const scale = Math.max(a.scale ?? 0, b.scale ?? 0);
  const scaledA = round(a, scale);
  const scaledB = round(b, scale);

  if (scaledA.value < scaledB.value) {
    return -1;
  } else if (scaledA.value > scaledB.value) {
    return 1;
  } else {
    return 0;
  }
};

/**
 * Find min value from given list
 * @param a at least one value is required
 * @param rest optional other values to compare
 * @returns minimal {@link ScaledNumber} value
 */
const min = (a: ScaledNumber, ...rest: ScaledNumber[]): ScaledNumber => {
  let min = a;

  for (const n of rest) {
    const cmp = compare(n, min);

    min = cmp < 0 ? n : min;
  }

  return min;
};

/**
 * Find max value from given list
 * @param a at least one value is required
 * @param rest optional other values to compare
 * @returns maximal {@link ScaledNumber} value
 */
const max = (a: ScaledNumber, ...rest: ScaledNumber[]): ScaledNumber => {
  let max = a;

  for (const n of rest) {
    const cmp = compare(n, max);

    max = cmp > 0 ? n : max;
  }

  return max;
};

/**
 * Create {@link ScaledNumber} from a number value and a scale to give number of decimals
 * The {@link value} will be truncated at given {@link decimals}
 * @returns {@link ScaledNumber} with {@link scale} = {@link decimals}
 */
const fromNumber = (value: number, decimals: number): ScaledNumber => ({
  value: Math.round(value * Math.pow(10, decimals)),
  scale: decimals,
});

/**
 * Convert {@link ScaledNumber} to a number value
 */
const toNumber = (value: ScaledNumber): number => value.value / Math.pow(10, value.scale ?? 0);

/**
 * Create {@link ScaledNumber} from an integer value
 * if {@link value} is not an integer, it will be truncated
 * @returns {@link ScaledNumber} with {@link scale} = 0
 */
const fromInteger = (value: number): ScaledNumber => fromNumber(value, 0);

const toStr = (value: ScaledNumber): string => {
  if (value.scale === 0 || value.scale === undefined) {
    return value.value.toString();
  } else {
    const div = Math.pow(10, value.scale);
    const absValue = Math.abs(value.value);
    const sign = value.value < 0 ? '-' : '';
    const intStr = Math.trunc(absValue / div).toString();
    const decimalStr = (absValue % div).toString();
    const padding = '0'.repeat(Math.max(0, value.scale - decimalStr.length));

    return `${sign}${intStr}.${padding}${decimalStr}`;
  }
};

/**
 * Parse a {@link ScaledNumber} from a string
 * optimistic version, assumes string is only digits and dot as decimals separator, ex: 1234.992
 */
const fromStr = (str: string): E.Either<Error, ScaledNumber> => {
  const firstDotIndex = str.indexOf('.');

  if (firstDotIndex === -1) {
    const value = Number.parseInt(str);
    if (Number.isNaN(value)) {
      return E.left(new Error(`Failed to parse ScaledNumber from string "${str}"`));
    }

    return E.right({
      value: value,
      scale: 0,
    });
  } else {
    const decimalSlice = str.slice(firstDotIndex + 1);
    const secondDotIndex = decimalSlice.indexOf('.');

    if (secondDotIndex !== -1) {
      return E.left(new Error(`Failed to parse ScaledNumber from string "${str}": extra dot found in decimals`));
    }

    const intSlice = str.slice(0, firstDotIndex);
    const value = Number.parseInt(intSlice + decimalSlice);

    if (Number.isNaN(value)) {
      return E.left(new Error(`Failed to parse ScaledNumber from string "${str}"`));
    }

    return E.right({
      value: value,
      scale: str.length - firstDotIndex - 1,
    });
  }
};

const ScaledNumberBasicCalculator: BasicCalculator<ScaledNumber> = {
  zero,
  add,
  sub,
  mul,
  div,
  round,
  compare,
};

const ScaledNumberExtendedCalculator: ExtendedCalculator<ScaledNumber> =
  ExtendedCalculator.build(ScaledNumberBasicCalculator);

const JSON: t.Type<ScaledNumber, ScaledNumber, unknown> = t.exact(
  t.intersection(
    [
      t.type(
        {
          value: t.number,
        },
        '!',
      ),
      t.partial(
        {
          scale: t.number,
        },
        '?',
      ),
    ],
    'ScaledNumber',
  ),
  'Exact<ScaledNumber>',
);
export const ScaledNumber: ScaledNumberModule = {
  ...ScaledNumberExtendedCalculator,
  min,
  max,
  fromInteger,
  fromNumber,
  toNumber,
  toStr,
  fromStr,
  JSON,
  ONE,
};
