/**
 * **This module is experimental**
 *
 * Experimental features are published in order to get early feedback from the community.
 *
 * A feature tagged as _Experimental_ is in a high state of flux, you're at risk of it changing without notice.
 *
 * @since 2.3.0
 */
import * as O from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/pipeable';
import * as _ from './internal';
import * as RM from 'fp-ts/es6/ReadonlyMap';
// -------------------------------------------------------------------------------------
// constructors
// -------------------------------------------------------------------------------------
/**
 * @category constructors
 * @since 2.3.8
 */
export var index = _.index;
/**
 * @category constructors
 * @since 2.3.0
 */
export var fromAt = function (at) {
    return index(function (i) { return _.lensComposePrism(_.prismSome())(at.at(i)); });
};
/**
 * Lift an instance of `Index` using an `Iso`.
 *
 * @category constructors
 * @since 2.3.0
 */
export var fromIso = function (iso) { return function (sia) {
    return index(function (i) { return pipe(iso, _.isoAsOptional, _.optionalComposeOptional(sia.index(i))); });
}; };
/**
 * @category constructors
 * @since 2.3.7
 */
export var indexReadonlyArray = _.indexReadonlyArray;
/**
 * @category constructors
 * @since 2.3.8
 */
export var indexReadonlyNonEmptyArray = _.indexReadonlyNonEmptyArray;
/**
 * @category constructors
 * @since 2.3.7
 */
export var indexReadonlyRecord = _.indexReadonlyRecord;
/**
 * @category constructors
 * @since 2.3.7
 */
export var indexReadonlyMap = function (E) {
    var lookupE = RM.lookup(E);
    var insertAtE = RM.insertAt(E);
    return function () {
        return index(function (key) {
            return _.optional(function (s) { return lookupE(key, s); }, function (next) {
                var insert = insertAtE(key, next);
                return function (s) {
                    return pipe(lookupE(key, s), O.fold(function () { return s; }, function (prev) { return (next === prev ? s : insert(s)); }));
                };
            });
        });
    };
};
// -------------------------------------------------------------------------------------
// deprecated
// -------------------------------------------------------------------------------------
/**
 * Use `indexReadonlyArray` instead.
 *
 * @category constructors
 * @since 2.3.2
 * @deprecated
 */
export var indexArray = _.indexReadonlyArray;
/**
 * Use `indexReadonlyRecord` instead.
 *
 * @category constructors
 * @since 2.3.2
 * @deprecated
 */
export var indexRecord = _.indexReadonlyRecord;
