import { DiscountPercent, DiscountPercentModule } from './discountPercent';
import { ScaledNumber } from './scaledNumber';
import * as t from 'io-ts';

export type DiscountPercentScaledNumber = DiscountPercent<ScaledNumber>;

export type DiscountPercentScaledNumberModule = DiscountPercentModule<ScaledNumber> & {
  readonly JSON: t.Type<DiscountPercent<ScaledNumber>, ScaledNumber>;
};

const DiscountPercentN = DiscountPercent.build(ScaledNumber);

const JSON: t.Type<DiscountPercent<ScaledNumber>, ScaledNumber> = DiscountPercentN.json(
  t.intersection(
    [
      t.type(
        {
          value: t.number,
        },
        '!',
      ),
      t.partial(
        {
          scale: t.number,
        },
        '?',
      ),
    ],
    'DiscountPercent<ScaledNumber>',
  ),
);

export const DiscountPercentScaledNumber: DiscountPercentScaledNumberModule = {
  ...DiscountPercentN,
  JSON: JSON,
};
