import { PageId } from '../pages/page-id';
import { BillingCycle } from './billing-cycle';
import { BillingInfo } from './billing-info';
import { SubscriptionId } from './subscription-id';
import { SubscriptionTier } from './subscriptionTier';
import { optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

export const SubscriptionInfo = t.intersection(
  [
    t.type({
      _id: SubscriptionId,
      active: t.boolean,
      pageId: PageId,
      tier: SubscriptionTier.JSON,
      billingCycle: BillingCycle,
      linkedSubscriptions: t.array(t.string),
      discountPercent: t.number,
      expires: DateFromISOString,
      paymentExpires: DateFromISOString,
      validUntil: DateFromISOString,
      trialStarted: t.boolean,
      shouldForceManualApproval: t.boolean,
    }),
    t.partial({
      seatCount: optionull(t.number),
      autoChargeError: t.string,
      customPrice: optionull(t.number),
      billing: optionull(BillingInfo),
      autoCharge: optionull(t.boolean),
      extraFee: optionull(t.number),
      threeDSAuthorizationUrl: optionull(t.string),
    }),
  ],
  'SubscriptionInfo',
);

export type SubscriptionInfo = t.TypeOf<typeof SubscriptionInfo>;

/**
 * Returns true if the subscription is expired at {@link now}
 */
export const subscriptionIsExpired = (subscription: SubscriptionInfo, now: Date) =>
  subscription.paymentExpires.getTime() < now.getTime();

/**
 * @returns true if the subscription payment is about to expire (in less than 48 hours)
 */
export const paymentIsAboutToExpire = (subscription: SubscriptionInfo, now: Date) =>
  !subscriptionIsExpired(subscription, now) && subscription.paymentExpires.getTime() - now.getTime() < 48 * 3600 * 1000;
