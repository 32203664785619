import { Negative } from './negative';
import { ScaledNumber } from './scaledNumber';

export type NegativeScaledNumber = Negative<ScaledNumber>;

const NegativeScaledNumberN = Negative.build(ScaledNumber);

const MINUS_ONE: Negative<ScaledNumber> = NegativeScaledNumberN.unsafeFrom(ScaledNumber.fromInteger(-1));

export const NegativeScaledNumber = {
  ...NegativeScaledNumberN,
  MINUS_ONE,
};
