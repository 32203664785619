import * as styleColors from './styles/colors';
import { styles as layoutStyles } from './styles/layout';
import styleSizes from './styles/sizes';
import { styles as textStyles } from './styles/text';

export { default as Button } from './components/Button';
export { default as Header } from './components/Header';
export { default as Icon } from './components/Icon';
export * from './components/Icon';
export { default as List } from './components/List';
export { default as Logo } from './components/Logo';
export { default as Spacer } from './components/Spacer';
export { default as HSpacer } from './components/HSpacer';

export { default as Text } from './components/Text';
export { default as D1 } from './components/Text/D1';
export { default as D2 } from './components/Text/D2';
export { default as H1 } from './components/Text/H1';
export { default as H2 } from './components/Text/H2';
export { default as H2s } from './components/Text/H2s';
export { default as H3s } from './components/Text/H3s';
export { default as Title } from './components/Text/Title';
export { default as Body } from './components/Text/Body';
export { default as SmallBody } from './components/Text/SmallBody';
export { default as Label } from './components/Text/Label';

export { default as TextInput } from './components/TextInput';
export { default as TypeSafeTextInput } from './components/TypeSafeTextInput';
export { default as SearchTextInput } from './components/SearchTextInput';
export { default as LoginButton } from './components/Button/LoginButton';
export { default as Checkbox } from './components/Checkbox';
export { default as CheckboxLayout } from './components/CheckboxLayout';
export { default as DismissKeyboard } from './components/DismissKeyboard';
export { default as DismissKeyboardView } from './components/DismissKeyboardView';
export { default as AvoidKeyboard } from './components/AvoidKeyboard';
export { default as PageBox } from './components/PageBox';
export { default as MeroHeader } from './components/MeroHeader';
export { default as Tooltip } from './components/Tooltip';
export { default as Row } from './components/Layout/Row';
export { default as Column } from './components/Layout/Column';
export { default as Switch } from './components/Switch';
export { default as InputWithLabel } from './components/InputWithLabel';
export { default as Select } from './components/Select';
export { default as Line } from './components/Line';
export { default as Avatar } from './components/Avatar';
export { default as NotificationsStack } from './components/NotificationsStack';
export type { NotificationsStackHandle } from './components/NotificationsStack';
export { default as UserNotification } from './components/UserNotification';
export { default as UserNotificationSkeleton } from './components/UserNotification/UserNotificationSkeleton';
export { default as ListItem } from './components/ListItem';
export { default as SimpleListItem } from './components/SimpleListItem';
export { default as SelectButton } from './components/SelectButton';
export { default as FormCard } from './components/FormCard';
export { default as Null } from './components/Null';
export { default as CircleButton } from './components/CircleButton';
export { default as LogoIcon } from './components/LogoIcon';
export { default as BottomNotification } from './components/BottomNotification';
export { default as ModalOverlay } from './components/ModalOverlay';
export { default as ConfirmBox } from './components/ConfirmBox';
export { default as SafeAreaView } from './components/SafeAreaView';
export { default as ReadMore } from './components/ReadMore';
export { default as Review } from './components/Review';
export { default as MeroLoading } from './components/MeroLoading';
export { default as PhotoSlider } from './components/PhotoSlider';

export { default as AppleSignInButton } from './components/AppleSignInButton';
export { default as PhoneSignInButton } from './components/PhoneSignInButton';
export { default as PageCard } from './components/PageCard';

export { default as PinInput } from './components/PinInput';

export { default as UserAppointmentListItem } from './components/calendar/UserAppointmentListItem';
export { default as AppointmentStatus } from './components/AppointmentStatus';
export { default as UserAppointmentDate } from './components/calendar/UserAppointmentDate';
export { default as UserAppointmentRecurrenceRule } from './components/calendar/UserAppointmentRecurrenceRule';
export { default as UserAppointmentSummary } from './components/calendar/UserAppointmentSummary';

export { ToastProvider, useToast } from './components/Toast';

export * from './hooks';

export * from './utils/format';

export const colors = styleColors;
export const sizes = styleSizes;
export const styles = {
  text: textStyles,
  layout: layoutStyles,
};
