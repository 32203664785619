import { JSONable } from '../../common';
import { PositiveInt, Zero } from '../../numbers';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

/**
 * Event ends repeating (start is) before given {@link OnDate['date']}
 */
export type OnDate = {
  readonly date: Date;
  readonly times?: Zero | PositiveInt | null;
};

/**
 * Event recurrence ends on specific date
 */
const JSON: t.Type<OnDate, JSONable> = t.intersection(
  [
    t.type(
      {
        /**
         * When date is defined - ocurrence ends at given time
         */
        date: DateFromISOString,
      },
      '!',
    ),
    t.partial(
      {
        /**
         * Field required for backwrads compatibility
         */
        times: t.union([Zero, PositiveInt.JSON, t.null]),
      },
      '?',
    ),
  ],
  'OnDate',
);

export const OnDate = {
  JSON,
  is: JSON.is,
};
