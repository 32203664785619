import * as O from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/pipeable';
import * as RM from 'fp-ts/es6/ReadonlyMap';
import * as RS from 'fp-ts/es6/ReadonlySet';
import * as _ from './internal';
// -------------------------------------------------------------------------------------
// constructors
// -------------------------------------------------------------------------------------
/**
 * @category constructors
 * @since 2.3.8
 */
export var at = _.at;
/**
 * Lift an instance of `At` using an `Iso`.
 *
 * @category constructors
 * @since 2.3.0
 */
export var fromIso = function (iso) { return function (sia) {
    return at(function (i) { return pipe(iso, _.isoAsLens, _.lensComposeLens(sia.at(i))); });
}; };
/**
 * @category constructors
 * @since 2.3.7
 */
export var atReadonlyRecord = _.atReadonlyRecord;
/**
 * @category constructors
 * @since 2.3.7
 */
export var atReadonlyMap = function (E) {
    var lookupE = RM.lookup(E);
    var deleteAtE = RM.deleteAt(E);
    var insertAtE = RM.insertAt(E);
    return function () {
        return at(function (key) {
            return _.lens(function (s) { return lookupE(key, s); }, O.fold(function () { return deleteAtE(key); }, function (a) { return insertAtE(key, a); }));
        });
    };
};
/**
 * @category constructors
 * @since 2.3.7
 */
export var atReadonlySet = function (E) {
    var elemE = RS.elem(E);
    var insertE = RS.insert(E);
    var removeE = RS.remove(E);
    return at(function (a) {
        var insert = insertE(a);
        var remove = removeE(a);
        return _.lens(function (s) { return elemE(a, s); }, function (b) { return function (s) { return (b ? insert(s) : remove(s)); }; });
    });
};
// -------------------------------------------------------------------------------------
// deprecated
// -------------------------------------------------------------------------------------
/**
 * Use `atReadonlyRecord` instead.
 *
 * @category constructors
 * @since 2.3.2
 * @deprecated
 */
export var atRecord = _.atReadonlyRecord;
