/**
 * @since 0.3.2
 */
import * as t from 'io-ts';
/**
 * Changes the output type of the given runtime type
 *
 * @example
 * import * as t from 'io-ts'
 * import { mapOutput } from 'io-ts-types/es6/mapOutput'
 * import { optionFromNullable } from 'io-ts-types/es6/optionFromNullable'
 * import { none, some } from 'fp-ts/es6/Option'
 *
 * // Input: t.Type<Option<number>, number | null, t.mixed>
 * const Input = optionFromNullable(t.number)
 *
 * const toUndefined = <A>(x: A | null): A | undefined => (x === null ? undefined : x)
 *
 * // Output: t.Type<Option<number>, number | undefined, t.mixed>
 * const Output = mapOutput(Input, toUndefined)
 *
 * assert.strictEqual(Output.encode(none), undefined)
 * assert.strictEqual(Output.encode(some(1)), 1)
 *
 * @since 0.3.2
 */
export function mapOutput(codec, f, name) {
    if (name === void 0) { name = codec.name; }
    return new t.Type(name, codec.is, codec.validate, function (a) { return f(codec.encode(a)); });
}
