import * as t from 'io-ts';

export const PageGiftCardSettings = t.type(
  {
    perSalonEnabled: t.boolean,
    universalEnabled: t.boolean,
  },
  'PageGiftCardSettings',
);

export type PageGiftCardSettings = t.TypeOf<typeof PageGiftCardSettings>;
