/**
 * @since 0.5.0
 */
import * as t from 'io-ts';
import { withValidate } from './withValidate';
import { orElse } from 'fp-ts/es6/Either';
/**
 * Returns a clone of the given codec that always succeed using the given value `a` if the original codec fails
 *
 * @example
 * import { withFallback } from 'io-ts-types/es6/withFallback'
 * import * as t from 'io-ts'
 * import { right } from 'fp-ts/es6/Either'
 *
 * const T = withFallback(t.number, -1)
 *
 * assert.deepStrictEqual(T.decode(1), right(1))
 * assert.deepStrictEqual(T.decode('a'), right(-1))
 *
 * @since 0.5.0
 */
export function withFallback(codec, a, name) {
    if (name === void 0) { name = "withFallback(" + codec.name + ")"; }
    return withValidate(codec, function (u, c) { return orElse(function () { return t.success(a); })(codec.validate(u, c)); }, name);
}
