import { CheckoutTransactionId } from '../../checkout/checkoutTransactionId';
import { PageId } from '../../pages/page-id';
import { ProductId, ProductMeasure } from '../products';
import { Inventory } from './inventory';
import { InventoryDestination } from './inventoryDestination';
import { InventoryId } from './inventoryId';
import { InventoryOperation } from './inventoryOperation';
import { InventoryOperationId } from './inventoryOperationId';
import { OperationUserPreview } from './operationUserPreview';
import { ProductStock } from './productStock';
import { ProductStockId } from './productStockId';
import { SavedInventoryOperation } from './savedInventoryOperation';
import { StockDecreaseReason } from './stockDecreaseReason';
import { StockDescription } from './stockDescription';
import { StockIncreaseReason } from './stockIncreaseReason';
import { StockValue } from './stockValue';
import { UpdateProductStockPayload } from './updateProductStockPayload';
import {
  JSONable,
  ObjectId,
  NonNegativeScaledNumber,
  Paged,
  ScaledNumber,
  PositiveScaledNumber,
} from '@mero/shared-sdk';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

export const InventoryIdC: t.Type<InventoryId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is InventoryId => true,
  'InventoryId',
);

export const PageIdC: t.Type<PageId, string> = t.brand(ObjectId, (_id: ObjectId): _id is PageId => true, 'PageId');

export const ProductIdC: t.Type<ProductId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is ProductId => true,
  'ProductId',
);

export const CheckoutTransactionIdC: t.Type<CheckoutTransactionId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is CheckoutTransactionId => true,
  'CheckoutTransactionId',
);

export const InventoryOperationIdC: t.Type<InventoryOperationId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is InventoryOperationId => true,
  'InventoryOperationId',
);

export const ProductStockIdC: t.Type<ProductStockId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is ProductStockId => true,
  'ProductStockId',
);

export const InventoryC: t.Type<Inventory, JSONable> = t.intersection([
  t.type(
    {
      _id: InventoryIdC,
      pageId: PageIdC,
      destination: InventoryDestination.JSON,
      isDefault: t.boolean,
    },
    '*',
  ),
  t.partial({
    details: t.type({
      description: tt.NonEmptyString,
      name: tt.NonEmptyString,
      address: tt.NonEmptyString,
      city: tt.NonEmptyString,
      county: tt.NonEmptyString,
    }),
  }),
]);

const OperationBaseC = t.type(
  {
    _id: InventoryOperationIdC,
    isStockManagementEnabled: t.boolean,
    createdAt: tt.DateFromISOString,
    productId: ProductIdC,
    createdBy: OperationUserPreview.JSON,
    pageId: PageIdC,
  },
  'ProductInvetoryOperationBase',
);

const StockValueC: t.Type<StockValue, JSONable> = t.type(
  {
    value: NonNegativeScaledNumber.JSON,
    unit: ProductMeasure.Unit.JSON,
  },
  'StockValue',
);

const StockIncreaseC: t.Type<InventoryOperation.StockIncrease, JSONable> = t.intersection(
  [
    OperationBaseC,
    t.type(
      {
        type: t.literal('StockIncrease'),
        reason: StockIncreaseReason.All.JSON,
        newValue: StockValueC,
        adjustment: StockValueC,
        oldValue: StockValueC,
        inventoryId: InventoryIdC,
      },
      '!',
    ),
  ],
  'StockIncrease',
);

const StockDecreaseC: t.Type<InventoryOperation.StockDecrease, JSONable> = t.intersection(
  [
    OperationBaseC,
    t.type(
      {
        type: t.literal('StockDecrease'),
        reason: StockDecreaseReason.All.JSON,
        newValue: StockValueC,
        adjustment: StockValueC,
        oldValue: StockValueC,
        inventoryId: InventoryIdC,
      },
      '!',
    ),
  ],
  'StockDecrease',
);

const StockManagementStatusUpdateC: t.Type<InventoryOperation.StockManagementStatusUpdate, JSONable> = t.intersection(
  [
    OperationBaseC,
    t.type(
      {
        type: t.literal('StockManagementStatusUpdate'),
        newStatus: t.boolean,
      },
      '!',
    ),
  ],
  'StockManagementStatusUpdate',
);

const TransactionStockConsumptionC: t.Type<InventoryOperation.TransactionStockConsumption, JSONable> = t.intersection(
  [
    OperationBaseC,
    t.type(
      {
        type: t.literal('TransactionStockConsumption'),
        newValue: StockValueC,
        oldValue: StockValueC,
        adjustment: StockValueC,
        checkoutTransactionId: CheckoutTransactionIdC,
        inventoryId: InventoryIdC,
      },
      '!',
    ),
  ],
  'TransactionStockConsumption',
);

export const TransactionCancelledConsumptionC: t.Type<InventoryOperation.TransactionCancelledConsumption, JSONable> =
  t.intersection(
    [
      OperationBaseC,
      t.type(
        {
          type: t.literal('TransactionCancelledConsumption'),
          oldValue: StockValueC,
          newValue: StockValueC,
          adjustment: StockValueC,
          checkoutTransactionId: CheckoutTransactionIdC,
          inventoryId: InventoryIdC,
        },
        '!',
      ),
    ],
    'UpdateStatusOperation',
  );

export const InventoryOperationC: t.Type<InventoryOperation.Any, JSONable> = t.union(
  [
    StockIncreaseC,
    StockDecreaseC,
    StockManagementStatusUpdateC,
    TransactionCancelledConsumptionC,
    TransactionStockConsumptionC,
  ],
  'InventoryOperation',
);

export const ProductStockC: t.Type<ProductStock, JSONable> = t.intersection(
  [
    StockValueC,
    t.type(
      {
        _id: ProductStockIdC,
        productId: ProductIdC,
        inventoryId: InventoryIdC,
        version: t.number,
        pageId: PageIdC,
      },
      '!',
    ),
  ],
  'ProductStock',
);

export const InventoryArrayC: t.Type<Inventory[], JSONable> = t.array(InventoryC, 'InventoryArray');

export const SavedInventoryOperationArrayC: t.Type<SavedInventoryOperation[], JSONable> = t.array(
  t.union([
    t.intersection([
      t.union([StockIncreaseC, StockDecreaseC, TransactionCancelledConsumptionC, TransactionStockConsumptionC]),
      t.type({
        adjustmentQuantity: ScaledNumber.JSON,
      }),
    ]),
    StockManagementStatusUpdateC,
  ]),
  'SavedInventoryOperationArray',
);

export const UpdateProductStockPayloadC: t.Type<UpdateProductStockPayload, JSONable> = t.union([
  t.type(
    {
      type: t.literal('StockIncrease'),
      reason: StockIncreaseReason.All.JSON,
      newStockValue: NonNegativeScaledNumber.JSON,
      oldStockValue: NonNegativeScaledNumber.JSON,
      adjustmentValue: PositiveScaledNumber.JSON,
    },
    'StockIncrease',
  ),
  t.type(
    {
      type: t.literal('StockDecrease'),
      reason: StockDecreaseReason.All.JSON,
      newStockValue: NonNegativeScaledNumber.JSON,
      oldStockValue: NonNegativeScaledNumber.JSON,
      adjustmentValue: PositiveScaledNumber.JSON,
    },
    'StockDecrease',
  ),
]);

export const StockDescriptionC: t.Type<StockDescription, JSONable> = t.type(
  {
    stock: NonNegativeScaledNumber.JSON,
    measureUnit: ProductMeasure.Unit.JSON,
    measureValue: PositiveScaledNumber.JSON,
    quantity: NonNegativeScaledNumber.JSON,
  },
  'StockDescription',
);

export const ProductsStocksMapC: t.Type<{ [key: string]: StockDescription }, JSONable> = t.record(
  t.string,
  StockDescriptionC,
);

export const PagedSavedInventoryOperationsArrayC: t.Type<Paged<SavedInventoryOperation[]>, JSONable> = Paged.json(
  SavedInventoryOperationArrayC,
);
