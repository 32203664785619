import { ProductMeasure } from '../productMeasure';
import { JSONable, Numbers, PositiveInt, unsafeDecode } from '@mero/shared-sdk';
import * as t from 'io-ts';

// Units of measurement that hold positive integer values (ex: 5 ml)
const UNITS_MAP = {
  buc: {
    name: 'Bucată',
    exponent: Numbers[2],
    order: 0,
    implicitValue: PositiveInt.unsafeFrom(1),
  },
  // set: {
  //   name: 'Set',
  //   exponent: Numbers[0],
  //   order: 1,
  //   implicitValue: undefined,
  // },
  ml: {
    name: 'Mililitri',
    exponent: Numbers[0],
    order: 2,
    implicitValue: undefined,
  },
  g: {
    name: 'Grame',
    exponent: Numbers[3],
    order: 3,
    implicitValue: undefined,
  },
  mg: {
    name: 'Milligrame',
    exponent: Numbers[0],
    order: 4,
    implicitValue: undefined,
  },
  'fl. oz': {
    name: 'Uncii de lichid',
    exponent: Numbers[3],
    order: 5,
    implicitValue: undefined,
  },
  oz: {
    name: 'Uncii',
    exponent: Numbers[3],
    order: 6,
    implicitValue: undefined,
  },
  l: {
    name: 'Litri',
    exponent: Numbers[3],
    order: 7,
    implicitValue: undefined,
  },
  cm: {
    name: 'Centimetri',
    exponent: Numbers[1],
    order: 8,
    implicitValue: undefined,
  },
  mm: {
    name: 'Millimetri',
    exponent: Numbers[0],
    order: 9,
    implicitValue: undefined,
  },
  kg: {
    name: 'Kilograme',
    exponent: Numbers[3],
    order: 10,
    implicitValue: undefined,
  },
  m: {
    name: 'Metri',
    exponent: Numbers[3],
    order: 11,
    implicitValue: undefined,
  },
} as const;

/**
 * Exact ProductMeasure unit type
 */
export type ExactUnit = keyof typeof UNITS_MAP;

const JSON: t.Type<ExactUnit, JSONable> = t.keyof(UNITS_MAP);

type SpecMap = { [Key in ExactUnit]: ProductMeasure.Spec };

const UNITS_SPEC_MAP: SpecMap = Object.fromEntries(
  Object.entries(UNITS_MAP).map(([unit, s]) => {
    const spec: ProductMeasure.Spec = {
      name: s.name,
      unit: ProductMeasure.Unit.unsafeFrom(unit),
      exponent: s.exponent,
      order: s.order,
      implicitValue: s.implicitValue,
    };

    return [unit, spec];
  }),
) as SpecMap;

const UNITS_SPEC_LIST: ProductMeasure.Spec[] = Object.values(UNITS_SPEC_MAP).sort(
  (desc1, desc2) => desc1.order - desc2.order,
);

const listSpecs = (): ProductMeasure.Spec[] => {
  return UNITS_SPEC_LIST;
};

const getSpec = (unit: ExactUnit): ProductMeasure.Spec => {
  return UNITS_SPEC_MAP[unit];
};

const listUnits = (): string[] => {
  return Object.keys(UNITS_MAP);
};

const fromString = (unit: string): t.Validation<ExactUnit> => {
  return JSON.decode(unit);
};

const unsafeFromString = (unit: string): ExactUnit => {
  return unsafeDecode(JSON, unit);
};

export const ExactUnit = {
  JSON,
  fromString,
  unsafeFromString,
  getSpec,
  listSpecs,
  listUnits,
};
