import * as t from 'io-ts';

/**
 * @experimental
 */
export type Ok<A> = {
  readonly isOk: true;
  readonly value: A;
};

/**
 * Build new Ok<A> value
 * @experimental
 */
export const ok = <A>(value: A): Ok<A> => {
  return {
    isOk: true,
    value: value,
  };
};

/**
 * Build new Err<E> value
 * @experimental
 */
export type Err<E> = {
  readonly isOk: false;
  readonly error: E;
};

/**
 * @experimental
 */
export const err = <E>(error: E): Err<E> => {
  return {
    isOk: false,
    error: error,
  };
};

/**
 * @experimental
 */
export type Result<A, E> = Ok<A> | Err<E>;

/**
 * Build new Result<A, E> codec using given value and error codecs
 * @param value codec
 * @param error codec
 * @experimental
 */
const json = <A extends t.Mixed, E extends t.Mixed>(
  value: A,
  error: E,
): t.Type<Result<t.TypeOf<A>, t.TypeOf<E>>, Result<t.OutputOf<A>, t.OutputOf<E>>> => {
  return t.union(
    [
      t.type(
        {
          isOk: t.literal(true),
          value: value,
        },
        `Ok<${value.name}>`,
      ),
      t.type(
        {
          isOk: t.literal(false),
          error: error,
        },
        `Err<${error.name}>`,
      ),
    ],
    `Result<${value.name}, ${error.name}>`,
  );
};

/**
 * Check if given {@link result} is {@link Ok}
 *
 * Use this function for filter operations where type refinement is needed, to avoid writing lambda function with explicit return type
 * otherwise - check result.isOk field directly
 * @experimental
 */
const isOk = <A, E>(result: Result<A, E>): result is Ok<A> => {
  return result.isOk;
};

/**
 * Check if given {@link result} is {@link Err}
 *
 * Use this function for filter operations where type refinement is needed, to avoid writing lambda function with explicit return type
 * otherwise - check result.isOk field directly
 * @experimental
 */
const isErr = <A, E>(result: Result<A, E>): result is Err<E> => {
  return !result.isOk;
};

export const Result = {
  json,
  isOk,
  isErr,
};
