import { NumberCalculator } from './numberCalculator';
import { Positive, PositiveModule } from './positive';
import * as t from 'io-ts';

export interface PositiveNumberBrand {
  readonly PositiveNumber: unique symbol;
}

/**
 * Any number greater than 0
 */
export type PositiveNumber = Positive<number>;

type PostiveNumberModule = PositiveModule<number> & {
  readonly JSON: t.Type<PositiveNumber, number>;
};

const PositiveNumberN = Positive.build(NumberCalculator);

export const PositiveNumber: PostiveNumberModule = {
  ...PositiveNumberN,
  JSON: PositiveNumberN.json(t.number),
};
