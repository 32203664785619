import { AppointmentHistoryRecordId } from './appointment-history-record-id';
import { AppointmentId } from './appointment-id';
import {
  AppointmentCancelledByClientHistoryType,
  AppointmentCancelledByProHistoryType,
  AppointmentConfirmedHistoryType,
  AppointmentCreatedByClientHistoryType,
  AppointmentCreatedByProHistoryType,
  AppointmentMarkedAsNoShowHistoryType,
  AppointmentModifiedByProHistoryType,
  AppointmentRefusedHistoryType,
} from './appointmentHistoryType';
import { OptionalDefinedString, StrictPhoneNumber } from '@mero/shared-sdk';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

/**
 * AppointmentHistory records common fields
 */
export const AppointmentHistoryBase = t.type(
  {
    _id: AppointmentHistoryRecordId,
    appointmentId: AppointmentId,
    date: DateFromISOString,
  },
  'AppointmentHistoryBase',
);
export interface AppointmentHistoryBase extends t.TypeOf<typeof AppointmentHistoryBase> {}

/**
 * Appointment created by client history record
 */
export const AppointmentCreatedByClientHistoryRecord = t.intersection(
  [
    t.type({
      type: AppointmentCreatedByClientHistoryType,
      payload: t.type({
        start: DateFromISOString,
        client: t.type({
          firstname: t.string,
          lastname: t.string,
        }),
        worker: t.type({
          firstname: t.string,
          lastname: t.string,
        }),
      }),
    }),
    AppointmentHistoryBase,
  ],
  'AppointmentCreatedByClientHistoryRecord',
);

export interface AppointmentCreatedByClientHistoryRecord
  extends t.TypeOf<typeof AppointmentCreatedByClientHistoryRecord> {}

/**
 * Appointment created by pro history record
 */
export const AppointmentCreatedByProHistoryRecord = t.intersection(
  [
    t.type({
      type: AppointmentCreatedByProHistoryType,
      payload: t.type({
        start: DateFromISOString,
        worker: t.type({
          firstname: t.string,
          lastname: t.string,
        }),
        byUser: t.type({
          firstname: t.string,
          lastname: t.string,
          phone: StrictPhoneNumber,
        }),
      }),
    }),
    AppointmentHistoryBase,
  ],
  'AppointmentCreatedByProHistoryRecord',
);

export interface AppointmentCreatedByProHistoryRecord extends t.TypeOf<typeof AppointmentCreatedByProHistoryRecord> {}

/**
 * Client cancelled an appointment history record
 */
export const AppointmentCancelledByClientHistoryRecord = t.intersection(
  [
    t.type({
      type: AppointmentCancelledByClientHistoryType,
      payload: t.type({
        client: t.type({
          firstname: t.string,
          lastname: t.string,
        }),
        reason: OptionalDefinedString,
      }),
    }),
    AppointmentHistoryBase,
  ],
  'AppointmentCancelledByClientHistoryRecord',
);

export interface AppointmentCancelledByClientHistoryRecord
  extends t.TypeOf<typeof AppointmentCancelledByClientHistoryRecord> {}

/**
 * Pro cancelled an appointment history record
 */
export const AppointmentCancelledByProHistoryRecord = t.intersection(
  [
    t.type({
      type: AppointmentCancelledByProHistoryType,
      payload: t.type({
        byUser: t.type({
          firstname: t.string,
          lastname: t.string,
          phone: StrictPhoneNumber,
        }),
        reason: OptionalDefinedString,
      }),
    }),
    AppointmentHistoryBase,
  ],
  'AppointmentCancelledByProHistoryRecord',
);

export interface AppointmentCancelledByProHistoryRecord
  extends t.TypeOf<typeof AppointmentCancelledByProHistoryRecord> {}

/**
 * Pro modified an appointment history record
 */
export const AppointmentModifiedByProHistoryRecord = t.intersection(
  [
    t.type({
      type: AppointmentModifiedByProHistoryType,
      payload: t.type({
        byUser: t.type({
          firstname: t.string,
          lastname: t.string,
          phone: StrictPhoneNumber,
        }),
        worker: t.type({
          firstname: t.string,
          lastname: t.string,
        }),
        start: t.type({
          from: DateFromISOString,
          to: DateFromISOString,
        }),
      }),
    }),
    AppointmentHistoryBase,
  ],
  'AppointmentModifiedByProHistoryRecord',
);

export interface AppointmentModifiedByProHistoryRecord extends t.TypeOf<typeof AppointmentModifiedByProHistoryRecord> {}

/**
 * Pro confirmed an appointment history record
 */
export const AppointmentConfirmedHistoryRecord = t.intersection(
  [
    t.type({
      type: AppointmentConfirmedHistoryType,
      payload: t.type({
        byUser: t.type({
          firstname: t.string,
          lastname: t.string,
          phone: StrictPhoneNumber,
        }),
      }),
    }),
    AppointmentHistoryBase,
  ],
  'AppointmentConfirmedHistoryRecord',
);

export interface AppointmentConfirmedHistoryRecord extends t.TypeOf<typeof AppointmentConfirmedHistoryRecord> {}

/**
 * Pro refused an appointment history record
 */
export const AppointmentRefusedHistoryRecord = t.intersection(
  [
    t.type({
      type: AppointmentRefusedHistoryType,
      payload: t.type({
        byUser: t.type({
          firstname: t.string,
          lastname: t.string,
          phone: StrictPhoneNumber,
        }),
      }),
    }),
    AppointmentHistoryBase,
  ],
  'AppointmentRefusedHistoryRecord',
);

export interface AppointmentRefusedHistoryRecord extends t.TypeOf<typeof AppointmentRefusedHistoryRecord> {}

/**
 * Pro marked an appointment as no show history record
 */
export const AppointmentMarkedAsNoShowHistoryRecord = t.intersection(
  [
    t.type({
      type: AppointmentMarkedAsNoShowHistoryType,
      payload: t.type({
        byUser: t.type({
          firstname: t.string,
          lastname: t.string,
          phone: StrictPhoneNumber,
        }),
      }),
    }),
    AppointmentHistoryBase,
  ],
  'AppointmentMarkedAsNoShowHistoryRecord',
);

export interface AppointmentMarkedAsNoShowHistoryRecord
  extends t.TypeOf<typeof AppointmentMarkedAsNoShowHistoryRecord> {}

export const AppointmentHistoryRecord = t.union(
  [
    AppointmentCreatedByClientHistoryRecord,
    AppointmentCreatedByProHistoryRecord,
    AppointmentCancelledByClientHistoryRecord,
    AppointmentCancelledByProHistoryRecord,
    AppointmentModifiedByProHistoryRecord,
    AppointmentConfirmedHistoryRecord,
    AppointmentRefusedHistoryRecord,
    AppointmentMarkedAsNoShowHistoryRecord,
  ],
  'AppointmentHistoryRecord',
);

export type AppointmentHistoryRecord =
  | AppointmentCreatedByClientHistoryRecord
  | AppointmentCreatedByProHistoryRecord
  | AppointmentCancelledByClientHistoryRecord
  | AppointmentCancelledByProHistoryRecord
  | AppointmentModifiedByProHistoryRecord
  | AppointmentConfirmedHistoryRecord
  | AppointmentMarkedAsNoShowHistoryRecord
  | AppointmentRefusedHistoryRecord;

export const AppointmentHistoryRecordArray = t.array(AppointmentHistoryRecord);

export type AppointmentHistoryRecordArray = t.TypeOf<typeof AppointmentHistoryRecordArray>;
