import { CheckoutReportPaymentsByType } from './checkoutReportPaymentsByType';
import { CheckoutReportTotals } from './checkoutReportTotals';
import { JSONable, MeroUnits, PositiveInt, Zero } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutSaleOwnerSalesReportTotalsByType<Unit extends MeroUnits.Any> = {
  /**
   * Number of sold bookings that was assigned to this sale owner (PRO)
   */
  readonly appointmentsCount: Zero | PositiveInt;
  /**
   * Number of sold memberships that was assigned to this sale owner (PRO)
   */
  readonly membershipsCount: Zero | PositiveInt;
  /**
   * Number of sold products that was assigned to this sale owner
   */
  readonly productsCount: number;
  /**
   * Total number of sold services that was assigned to this sale owner (including services on booking)
   */
  readonly servicesCount: Zero | PositiveInt;
  /**
   * Total number of custom amount items that was assigned to this sale owner
   */
  readonly amountsCount: Zero | PositiveInt;
  /**
   * Totals for all types
   */
  readonly all: CheckoutReportTotals<Unit>;
  /**
   * Totals for services type
   */
  readonly services: CheckoutReportTotals<Unit>;
  /**
   * Totals for products type
   */
  readonly products: CheckoutReportTotals<Unit>;
  /**
   * Totals for memberships type
   */
  readonly memberships: CheckoutReportTotals<Unit>;
  /**
   * Totals for amount type
   */
  readonly amounts: CheckoutReportTotals<Unit>;
  /**
   * Totals for delivered items (sum of services, products and amount, including membership paid items)
   */
  readonly delivered: CheckoutReportTotals<Unit>;
  /**
   * Payments by type
   */
  readonly payments: CheckoutReportPaymentsByType<Unit>;
};

const json = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutSaleOwnerSalesReportTotalsByType<Unit>, JSONable> => {
  return t.type(
    {
      appointmentsCount: t.union([Zero, PositiveInt.JSON]),
      membershipsCount: t.union([Zero, PositiveInt.JSON]),
      productsCount: t.number,
      servicesCount: t.union([Zero, PositiveInt.JSON]),
      amountsCount: t.union([Zero, PositiveInt.JSON]),
      all: CheckoutReportTotals.json(unit),
      services: CheckoutReportTotals.json(unit),
      products: CheckoutReportTotals.json(unit),
      memberships: CheckoutReportTotals.json(unit),
      amounts: CheckoutReportTotals.json(unit),
      delivered: CheckoutReportTotals.json(unit),
      payments: CheckoutReportPaymentsByType.json(unit),
    },
    `CheckoutSaleOwnerSalesReportTotalsByType<${unit}>`,
  );
};

export const CheckoutSaleOwnerSalesReportTotalsByType = {
  json,
};
