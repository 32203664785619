import { BusinessHours } from '../business';
import { CalendarId } from '../calendar/calendar-id';
import { CalendarSettings } from '../calendar/calendar-settings';
import { BusinessCategoryId, Price, ServiceGroupId, ServiceId } from '../services';
import { GroupedMergedServices } from '../services/group-with-merged-services';
import { WorkerId } from '../workers';
import { PageDiscountSettings } from './page-discount-settings';
import { PageGiftCardSettings } from './page-gift-card-settings';
import { PageId } from './page-id';
import { PageImage } from './pageImage';
import { PageOnlinePaymentsSettings } from './pageOnlinePaymentsSettings';
import { Tracking } from './tracking';
import { UserPageRole } from './user-page-role';
import { Location, ProfileImage, UserId, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const Service = t.intersection(
  [
    t.type({
      _id: ServiceId,
      categoryId: BusinessCategoryId,
      name: t.string,
      description: t.string,
      durationInMinutes: t.number,
      default: t.boolean,
      availableDays: t.number,
      automaticApproval: t.boolean,
      price: Price,
      color: t.string,
    }),
    t.partial({
      private: t.boolean,
      groupIds: t.union([t.array(ServiceGroupId), t.null]),
      isCustom: t.union([t.boolean, t.null]),
    }),
  ],
  'Service',
);

export type Service = t.TypeOf<typeof Service>;

export const PageProfileWorker = t.type(
  {
    _id: WorkerId.JSON,
    user: t.type({
      _id: UserId,
      firstname: t.string,
      lastname: t.string,
    }),
    calendar: t.type({
      _id: CalendarId,
      settings: CalendarSettings,
    }),
    profilePhoto: ProfileImage,
    services: t.array(Service),
  },
  'PageProfileWorker',
);

export type PageProfileWorker = t.TypeOf<typeof PageProfileWorker>;

export const PageProfile = t.intersection(
  [
    t.type(
      {
        _id: PageId,
        slug: t.string,
        name: t.string,
        phoneNo: t.string,
        location: Location,
        description: t.string,
        profilePhoto: ProfileImage,
        images: t.array(PageImage.JSON),
        workers: t.array(PageProfileWorker),
        people: t.array(UserPageRole),
        businessHours: BusinessHours.JSON,
        mergedAndGroupedServices: GroupedMergedServices,
        feedback: t.type(
          {
            score: t.number,
            total: t.number,
          },
          'Feedback',
        ),
        giftCardSettings: PageGiftCardSettings,
        discountSettings: PageDiscountSettings,
        isSearchable: t.boolean,
        subscriptionFriendlyTier: t.string,
        hasPromoPrice: t.boolean,
      },
      '!',
    ),
    t.partial(
      {
        hideReviews: t.boolean,
        tracking: Tracking,
        hasOnlinePaymentsEnabled: optionull(t.boolean),
        onlinePaymentsSettings: optionull(PageOnlinePaymentsSettings.JSON),
        isPromotedUntil: t.string,
      },
      '?',
    ),
  ],
  'PageProfile',
);

export type PageProfile = t.TypeOf<typeof PageProfile>;
