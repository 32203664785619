import * as MeroApi from '@mero/api-sdk';
import {
  AppointmentCancelledByClientNotificationType,
  AppointmentCancelledByWorkerNotificationType,
  AppointmentConfirmedNotificationType,
  AppointmentMadeByPageNotificationType,
  AppointmentModifiedNotificationType,
  AppointmentOpenningNotificationType,
  AppointmentReminderNotificationType,
  AppointmentRequestedNotificationType,
  ClientAppointmentMadeNotificationType,
  ClientAppointmentPaymentReceivedNotificationType,
  GenericMarketplaceNotificationType,
  PaymentSuccessfulNotificationType,
  PrivateWorkerClientJoinRequestFulfilledNotificationType,
  PrivateWorkerClientJoinRequestNotificationType,
  WorkerInviteFulfilledNotificationType,
  WorkerInviteNotificationType,
  GenericProNotificationType,
  AppointmentReviewReminderNotificationType,
  AppointmentMadeByAnotherProNotificationType,
  MeroSmsIssueNotificationType,
  AppointmentCancelledByAnotherProNotificationType,
  AppointmentModifiedByAnotherProNotificationType,
  GenericAppointmentActionNotificationType,
} from '@mero/api-sdk/dist/notifications';
import * as React from 'react';
import { StyleProp, View, ViewStyle, StyleSheet } from 'react-native';

import { WHITE } from '../../styles/colors';
import AppointmentCancelledByClientNotification from './AppointmentCancelledByClientNotification';
import AppointmentCancelledByWorkerNotification from './AppointmentCancelledByWorkerNotification';
import AppointmentConfirmedNotification from './AppointmentConfirmedNotification';
import AppointmentMadeByPageNotification from './AppointmentMadeByPageNotification';
import AppointmentModifiedNotification from './AppointmentModifiedNotification';
import AppointmentOpenningNotification from './AppointmentOpenningNotification';
import AppointmentReminderNotification from './AppointmentReminderNotification';
import AppointmentRequestedNotification from './AppointmentRequestedNotification';
import AppointmentReviewReminderNotification from './AppointmentReviewReminderNotification';
import ClientAppointmentMadeNotification from './ClientAppointmentMadeNotification';
import ClientAppointmentPaymentReceivedNotification from './ClientAppointmentPaymentReceivedNotification';
import GenericMarketplaceNotification from './GenericMarketplaceNotification';
import GenericProNotification from './GenericProNotification';
import PaymentSuccessfulNotification from './PaymentSuccessfulNotification';
import PrivateWorkerClientJoinRequestFulfilledNotification from './PrivateWorkerClientJoinRequestFulfilledNotification';
import PrivateWorkerClientJoinRequestNotification from './PrivateWorkerClientJoinRequestNotification';
import WorkerInviteFulfilledNotification from './WorkerInviteFulfilledNotification';
import WorkerInviteNotification from './WorkerInviteNotification';

type UserNotificationContentProps = {
  readonly notification: MeroApi.notifications.Notification;
};

const UserNotificationContent: React.FC<UserNotificationContentProps> = ({
  notification,
}: UserNotificationContentProps) => {
  switch (notification.type) {
    case WorkerInviteNotificationType.value:
      return <WorkerInviteNotification notification={notification} />;
    case WorkerInviteFulfilledNotificationType.value:
      return <WorkerInviteFulfilledNotification notification={notification} />;
    case PrivateWorkerClientJoinRequestNotificationType.value:
      return <PrivateWorkerClientJoinRequestNotification notification={notification} />;
    case PrivateWorkerClientJoinRequestFulfilledNotificationType.value:
      return <PrivateWorkerClientJoinRequestFulfilledNotification notification={notification} />;
    case AppointmentRequestedNotificationType.value:
      return <AppointmentRequestedNotification notification={notification} />;
    case AppointmentConfirmedNotificationType.value:
      return <AppointmentConfirmedNotification notification={notification} />;
    case AppointmentMadeByPageNotificationType.value:
      return <AppointmentMadeByPageNotification notification={notification} />;
    case AppointmentCancelledByClientNotificationType.value:
      return <AppointmentCancelledByClientNotification notification={notification} />;
    case AppointmentCancelledByWorkerNotificationType.value:
      return <AppointmentCancelledByWorkerNotification notification={notification} />;
    case AppointmentModifiedNotificationType.value:
      return <AppointmentModifiedNotification notification={notification} />;
    case AppointmentOpenningNotificationType.value:
      return <AppointmentOpenningNotification notification={notification} />;
    case AppointmentReminderNotificationType.value:
      return <AppointmentReminderNotification notification={notification} />;
    case ClientAppointmentMadeNotificationType.value:
      return <ClientAppointmentMadeNotification notification={notification} />;
    case PaymentSuccessfulNotificationType.value:
      return <PaymentSuccessfulNotification notification={notification} />;
    case ClientAppointmentPaymentReceivedNotificationType.value:
      return <ClientAppointmentPaymentReceivedNotification notification={notification} />;
    case GenericMarketplaceNotificationType.value:
      return <GenericMarketplaceNotification notification={notification} />;
    case GenericProNotificationType.value:
      return <GenericProNotification notification={notification} />;
    case AppointmentReviewReminderNotificationType.value:
      return <AppointmentReviewReminderNotification notification={notification} />;
    case AppointmentMadeByAnotherProNotificationType.value:
    // FIXME: implement
    case MeroSmsIssueNotificationType.value:
    // FIXME: implement
    case AppointmentCancelledByAnotherProNotificationType.value:
    // FIXME: implement
    case AppointmentModifiedByAnotherProNotificationType.value:
    // FIXME: implement
    case GenericAppointmentActionNotificationType.value:
      // FIXME: implement
      return null;
  }
};

type UserNotificationProps = {
  readonly notification: MeroApi.notifications.Notification;
  readonly style?: StyleProp<ViewStyle>;
};

const UserNotification: React.FC<UserNotificationProps> = ({ notification, style }: UserNotificationProps) => {
  return (
    <View style={StyleSheet.compose({ padding: 16, backgroundColor: notification.seen ? WHITE : '#F2F2FE' }, style)}>
      <UserNotificationContent notification={notification} />
    </View>
  );
};

export default UserNotification;
