import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type PageBillingDetailsUsageLabel = 'MeroSubscription' | 'MeroGiftCards' | 'UserPayments';

const JSON: t.Type<PageBillingDetailsUsageLabel, JSONable> = t.union(
  [t.literal('MeroSubscription'), t.literal('MeroGiftCards'), t.literal('UserPayments')],
  'PageBillingDetailsUsageLabel',
);

const MERO_SUBSCRIPTION = 'MeroSubscription' as const;
const MERO_GIFT_CARDS = 'MeroGiftCards' as const;
const USER_PAYMENTS = 'UserPayments' as const;

export const PageBillingDetailsUsageLabel = {
  JSON,
  MERO_SUBSCRIPTION,
  MERO_GIFT_CARDS,
  USER_PAYMENTS,
};
