import { RequireAdvancePaymentOption } from './requireAdvancePaymentOption';
import { JSONable, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type PageOnlinePaymentsSettings = {
  readonly requireAdvancePayment: RequireAdvancePaymentOption;
  readonly advancePaymentTos: string | undefined;
};

const JSON: t.Type<PageOnlinePaymentsSettings, JSONable> = t.type(
  {
    requireAdvancePayment: RequireAdvancePaymentOption.JSON,
    advancePaymentTos: optionull(t.string),
  },
  'PageOnlinePaymentsSettings',
);

const DEFAULT: PageOnlinePaymentsSettings = {
  requireAdvancePayment: RequireAdvancePaymentOption.DEFAULT,
  advancePaymentTos: undefined,
};

export const PageOnlinePaymentsSettings = {
  JSON,
  DEFAULT,
};
