import { UserOrderDetails } from '../userOrderDetails';
import { UserOrderId, UserOrderIdBrand } from '../userOrderId';
import { UserOrderPaymentDetails } from '../userOrderPaymentDetails';
import { UserOrderPaymentId } from '../userOrderPaymentId';
import { UserOrderPaymentPreview } from '../userOrderPaymentPreview';
import { UserOrderPaymentStatusPreview } from '../userOrderPaymentStatusPreview';
import { JSONable, MeroUnits, Money, Option, ScaledNumber } from '@mero/shared-sdk';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

export const UserOrderPaymentIdC: t.Type<UserOrderPaymentId, string> = t.brand(
  t.string,
  (_id: string): _id is UserOrderPaymentId => true,
  'UserOrderPaymentId',
);

export const UserOrderIdC: t.Type<UserOrderId, JSONable> = t.brand(
  t.string,
  (_id: string): _id is t.Branded<string, UserOrderIdBrand> => true,
  'UserOrderId',
);

export const UserOrderPaymentStatusPreviewC: t.Type<UserOrderPaymentStatusPreview, JSONable> = t.union(
  [
    t.type(
      {
        type: t.literal('new'),
      },
      'New',
    ),
    t.type(
      {
        type: t.literal('started'),
      },
      'Started',
    ),
    t.type(
      {
        type: t.literal('succeeded'),
      },
      'Succeeded',
    ),
    t.type(
      {
        type: t.literal('failed'),
      },
      'Failed',
    ),
  ],
  'UserOrderPaymentStatusPreview',
);

export const UserOrderPaymentPreviewC: t.Type<UserOrderPaymentPreview, JSONable> = t.type(
  {
    _id: UserOrderPaymentIdC,
    status: UserOrderPaymentStatusPreviewC,
    byUser: t.type(
      {
        firstname: Option.json(t.string),
        lastname: Option.json(t.string),
      },
      'ByUser',
    ),
    amount: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
    refunds: Option.json(Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON)),
    total: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
    paidAt: tt.DateFromISOString,
  },
  'UserOrderPaymentPreview',
);

export const UserOrderDetailsC: t.Type<UserOrderDetails, JSONable> = t.type(
  {
    _id: UserOrderIdC,
    byUser: t.type(
      {
        firstname: Option.json(t.string),
        lastname: Option.json(t.string),
        phone: Option.json(t.string),
      },
      'ByUser',
    ),
    items: t.array(
      t.union(
        [
          t.type(
            {
              type: t.literal('Appointment'),
              title: t.string,
              quantity: ScaledNumber.JSON,
              price: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
              appointmentId: t.string,
              occurrenceIndex: Option.json(t.number),
            },
            'Appointment',
          ),
          t.type(
            {
              type: t.literal('SalonGiftCard'),
              title: t.string,
              quantity: ScaledNumber.JSON,
              price: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
              giftCardId: t.string,
            },
            'SalonGiftCard',
          ),
        ],
        'Item',
      ),
    ),
    subtotal: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
    discount: Option.json(Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON)),
    vat: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
    total: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
    company: t.type(
      {
        name: t.string,
        regNo: t.string,
        fiscalCode: t.string,
        countryCode: t.string,
        city: t.string,
        address: t.string,
      },
      'Company',
    ),
    createdAt: tt.DateFromISOString,
  },
  'UserOrderDetails',
);

export const UserOrderPaymentDetailsC: t.Type<UserOrderPaymentDetails, JSONable> = t.type(
  {
    _id: UserOrderPaymentIdC,
    status: UserOrderPaymentStatusPreviewC,
    byUser: t.type(
      {
        firstname: Option.json(t.string),
        lastname: Option.json(t.string),
        phone: Option.json(t.string),
      },
      'ByUser',
    ),
    amount: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
    refunds: Option.json(Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON)),
    total: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
    order: UserOrderDetailsC,
    paidAt: tt.DateFromISOString,
  },
  'UserOrderPaymentDetails',
);
