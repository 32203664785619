import { ClientId } from '../../clients/client-id';
import { CheckoutReportPercents } from './checkoutReportPercents';
import { CheckoutReportTotals } from './checkoutReportTotals';
import {
  HasId,
  HasOptionalFirstLastName,
  JSONable,
  MeroUnits,
  PortionPercentScaledNumber,
  PositiveInt,
  Zero,
} from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutClientSalesReportItem<Unit extends MeroUnits.Any> = {
  readonly client: HasId<ClientId> & HasOptionalFirstLastName;
  /**
   * Number of sold bookings that was assigned to this sale owner (PRO)
   */
  readonly appointmentsCount: Zero | PositiveInt;
  /**
   * Client appointments count percent from total appointments count
   */
  readonly appointmentsPercent: PortionPercentScaledNumber;
  /**
   * Number of sold memberships that was assigned to this client
   */
  readonly membershipsCount: Zero | PositiveInt;
  /**
   * Client memberships count percent from total memeberships count
   */
  readonly membershipsPercent: PortionPercentScaledNumber;
  /**
   * Number of sold products that was assigned to this client
   */
  readonly productsCount: number;
  /**
   * Client products count percent from total products count
   */
  readonly productsPercent: PortionPercentScaledNumber;
  /**
   * Total number of sold services that was assigned to this (including services on booking)
   */
  readonly servicesCount: Zero | PositiveInt;
  /**
   * Client services count percent from total products count
   */
  readonly servicesPercent: PortionPercentScaledNumber;
  /**
   * Items totals
   */
  readonly totals: CheckoutReportTotals<Unit>;
  /**
   * Totals percents
   */
  readonly percents: CheckoutReportPercents;
};

const json = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<CheckoutClientSalesReportItem<Unit>, JSONable> => {
  return t.type(
    {
      client: t.intersection([HasId.json(ClientId), HasOptionalFirstLastName], 'Client'),
      appointmentsCount: t.union([Zero, PositiveInt.JSON]),
      appointmentsPercent: PortionPercentScaledNumber.JSON,
      membershipsCount: t.union([Zero, PositiveInt.JSON]),
      membershipsPercent: PortionPercentScaledNumber.JSON,
      productsCount: t.number,
      productsPercent: PortionPercentScaledNumber.JSON,
      servicesCount: t.union([Zero, PositiveInt.JSON]),
      servicesPercent: PortionPercentScaledNumber.JSON,
      totals: CheckoutReportTotals.json(unit),
      percents: CheckoutReportPercents.JSON,
    },
    `CheckoutClientSalesReportItem<${unit}>`,
  );
};

export const CheckoutClientSalesReportItem = {
  json,
};
