import { GetPageMeroGiftCardsBillingResponse } from './http/getPageMeroGiftCardsBillingResponse';
import { GetPageMeroSubscriptionBillingResponse } from './http/getPageMeroSubscriptionBillingResponse';
import { GetPageUserPaymentsBillingResponse } from './http/getPageUserPaymentsBillingResponse';
import { PageBillingDetailsApi } from './pageBillingDetailsApi';
import { PageBillingDetailsId } from './pageBillingDetailsId';
import { SavedPageBillingDetails } from './savedPageBillingDetails';
import { HasId, HttpClient, UnknownApiError, unsafeRight, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const pageBillingDetailsHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): PageBillingDetailsApi => {
  const { apiBaseUrl, http } = env;

  const enc = encodeURIComponent;
  const unknownResponseDecoder = http.decode.response(UnknownApiError, t.unknown);
  const listPageBillingDetailsDecoder = http.decode.response(UnknownApiError, t.array(SavedPageBillingDetails.JSON));
  const addPageBillingDetails = http.decode.response(UnknownApiError, HasId.json(PageBillingDetailsId.JSON));
  const getPageBillingDetails = http.decode.response(UnknownApiError, SavedPageBillingDetails.JSON);
  const getPageMeroSubscriptionBillingDecoder = http.decode.response(
    UnknownApiError,
    GetPageMeroSubscriptionBillingResponse.JSON,
  );
  const getPageMeroGiftCardsBillingDecoder = http.decode.optionalResponse(
    UnknownApiError,
    GetPageMeroGiftCardsBillingResponse.JSON,
  );
  const getPageUserPaymentsBillingDecoder = http.decode.response(
    UnknownApiError,
    GetPageUserPaymentsBillingResponse.JSON,
  );

  return {
    listPageBillingDetails: async (pageId) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${enc(pageId)}/billing-details`,
          },
          listPageBillingDetailsDecoder,
        ),
      );
    },

    addPageBillingDetails: async (params) => {
      const { _id } = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/pro/pages/${enc(params.pageId)}/billing-details`,
            data: params.billing,
          },
          addPageBillingDetails,
        ),
      );

      return _id;
    },

    updatePageBillingDetails: async (params) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/pro/pages/${enc(params.pageId)}/billing-details/${enc(params.billingDetailsId)}`,
            data: params.billing,
          },
          unknownResponseDecoder,
        ),
      );
    },

    getPageBillingDetailsById: async (params) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${enc(params.pageId)}/billing-details/${enc(params.billingDetailsId)}`,
          },
          getPageBillingDetails,
        ),
      );
    },

    deletePageBillingDetails: async (params) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/pro/pages/${enc(params.pageId)}/billing-details/${enc(params.billingDetailsId)}`,
          },
          unknownResponseDecoder,
        ),
      );
    },

    setPageMeroSubscriptionBilling: async (params) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/pro/pages/${enc(params.pageId)}/billing-details-config/mero-subscription`,
            data: {
              billingDetailsId: params.billingDetailsId,
            },
          },
          unknownResponseDecoder,
        ),
      );
    },

    getPageMeroSubscriptionBilling: async (pageId) => {
      const { billingDetails } = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${enc(pageId)}/billing-details-config/mero-subscription`,
          },
          getPageMeroSubscriptionBillingDecoder,
        ),
      );

      return billingDetails;
    },

    setPageMeroGiftCardsBilling: async (params) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/pro/pages/${enc(params.pageId)}/billing-details-config/mero-gift-cards`,
            data: {
              billingDetailsId: params.billingDetailsId,
              settlementAccount: params.settlementAccount,
            },
          },
          unknownResponseDecoder,
        ),
      );
    },

    getPageMeroGiftCardsBilling: async (pageId) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${enc(pageId)}/billing-details-config/mero-gift-cards`,
          },
          getPageMeroGiftCardsBillingDecoder,
        ),
      );
    },

    setPageUserPaymentsBilling: async (params) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/pro/pages/${enc(params.pageId)}/billing-details-config/user-payments`,
            data: {
              billingDetailsId: params.billingDetailsId,
            },
          },
          unknownResponseDecoder,
        ),
      );
    },

    getPageUserPaymentsBilling: async (pageId) => {
      const { billingDetails } = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${enc(pageId)}/billing-details-config/user-payments`,
          },
          getPageUserPaymentsBillingDecoder,
        ),
      );

      return billingDetails;
    },
  };
};
