import { PageId } from '../pages';
import { GiftCard } from './gift-card';
import { HttpClient, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export * from './gift-card-id';
export * from './gift-card';

export interface GiftCardsApi {
  /**
   * Create new page giftcard.
   * @deprecated reverted createNewGiftCard method
   */
  readonly createNewPageGiftCard: (params: { price: number; pageId: PageId }) => Promise<GiftCard>;

  readonly createNewGiftCard: (params: { price: number; pageId?: PageId }) => Promise<GiftCard>;

  /**
   * Find a gift card by code.
   */
  readonly findGiftCard: (params: { code: string }) => Promise<GiftCard>;
}

export const giftCardsHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): GiftCardsApi => {
  const { apiBaseUrl, http } = env;

  const createNewGiftCardDecoder = http.decode.optionalResponse(UnknownApiError, t.type({ result: GiftCard }));
  const findGiftCardDecoder = http.decode.optionalResponse(UnknownApiError, t.type({ result: GiftCard }));

  return {
    createNewPageGiftCard: async ({ price, pageId }) =>
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/gift-cards`,
            data: {
              price,
              pageId,
            },
          },
          createNewGiftCardDecoder,
        ),
      ).result,
    createNewGiftCard: async ({ price, pageId }) =>
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/gift-cards`,
            data: {
              price,
              pageId,
            },
          },
          createNewGiftCardDecoder,
        ),
      ).result,
    findGiftCard: async ({ code }) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/gift-cards`,
            params: {
              code,
            },
          },
          findGiftCardDecoder,
        ),
      ).result,
  };
};
