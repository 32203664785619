import { SubscriptionProApi } from '../subscriptionProApi';
import {
  HttpClient,
  MeroUnits,
  Money,
  ScaledNumber,
  SubscriptionTierId,
  UnknownApiError,
  unsafeRight,
} from '@mero/shared-sdk';
import * as t from 'io-ts';

export const subscriptionProHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): SubscriptionProApi => {
  const { apiBaseUrl, http } = env;

  const enc = encodeURIComponent;
  const estimateSubscriptionUpgradeProRataDecoder = http.decode.response(
    UnknownApiError,
    t.type({ proRata: Money.json(ScaledNumber.JSON, MeroUnits.EUR.JSON) }, 'Response'),
  );
  const upgradeSubscriptionTierDecoder = http.decode.response(UnknownApiError, t.unknown);

  return {
    estimateSubscriptionUpgradeProRata: async ({ pageId, tier }) => {
      const { proRata } = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/pro/pages/${enc(pageId)}/subscription/tier/pro-rata-estimate`,
            params: {
              tier: SubscriptionTierId.JSON.encode(tier),
            },
          },
          estimateSubscriptionUpgradeProRataDecoder,
        ),
      );

      return proRata;
    },
    upgradeSubscriptionTier: async ({ pageId, tier, confirmProRata }) => {
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/pro/pages/${enc(pageId)}/subscription/tier`,
            params: {
              tier: SubscriptionTierId.JSON.encode(tier),
              confirmProRata: confirmProRata,
            },
          },
          upgradeSubscriptionTierDecoder,
        ),
      );
    },
  };
};
