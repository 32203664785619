import { PositiveScaledNumber } from './positiveScaledNumber';
import { ScaledNumber, ScaledNumberZero } from './scaledNumber';
import * as E from 'fp-ts/lib/Either';
import * as t from 'io-ts';

export type NonNegativeScaledNumber = PositiveScaledNumber | ScaledNumberZero;

export const NonNegativeScaledNumber = {
  is: (value: ScaledNumber): value is NonNegativeScaledNumber =>
    PositiveScaledNumber.is(value) || ScaledNumber.isZero(value),

  JSON: t.union([PositiveScaledNumber.json(ScaledNumber.JSON), ScaledNumberZero.JSON]),

  fromStr: (value: string): E.Either<Error, NonNegativeScaledNumber> => {
    const validation = ScaledNumber.fromStr(value);

    if (E.isLeft(validation)) {
      return E.left(new Error('String is not a number'));
    }
    if (!NonNegativeScaledNumber.is(validation.right)) {
      return E.left(new Error('Number is negative'));
    }

    return E.right(validation.right);
  },

  fromScaledNumber: (value: ScaledNumber): E.Either<Error, NonNegativeScaledNumber> => {
    if (!NonNegativeScaledNumber.is(value)) {
      return E.left(new Error('Number is negative'));
    }

    return E.right(value);
  },

  zero: (): NonNegativeScaledNumber => ScaledNumber.zero() as NonNegativeScaledNumber,

  fromNumber: (value: number, decimals: number): E.Either<Error, NonNegativeScaledNumber> => {
    const scaledNumber = ScaledNumber.fromNumber(value, decimals);
    if (!NonNegativeScaledNumber.is(scaledNumber)) {
      return E.left(new Error('Number is negative'));
    }

    return E.right(scaledNumber);
  },

  toNumber: (value: NonNegativeScaledNumber): number => {
    return ScaledNumber.toNumber(value);
  },

  toStr: (value: NonNegativeScaledNumber): string => {
    return NonNegativeScaledNumber.toStr(value);
  },

  add: (a: NonNegativeScaledNumber, b: NonNegativeScaledNumber): NonNegativeScaledNumber => {
    const result = ScaledNumber.add(a, b);
    return result as NonNegativeScaledNumber;
  },

  sub: (a: NonNegativeScaledNumber, b: NonNegativeScaledNumber): E.Either<Error, NonNegativeScaledNumber> => {
    const result = ScaledNumber.sub(a, b);
    if (!NonNegativeScaledNumber.is(result)) {
      return E.left(new Error('Number is negative'));
    }

    return E.right(result);
  },

  mul: (a: NonNegativeScaledNumber, b: NonNegativeScaledNumber): NonNegativeScaledNumber => {
    const result = ScaledNumber.mul(a, b);
    return result as NonNegativeScaledNumber;
  },

  /**
   * @throws Error if subtraction result is a negative value (b > a)
   */
  unsafeSub: (a: NonNegativeScaledNumber, b: NonNegativeScaledNumber): NonNegativeScaledNumber => {
    const result = ScaledNumber.sub(a, b);

    if (!NonNegativeScaledNumber.is(result)) {
      throw new Error('Result of subtraction is negative');
    }

    return result;
  },
};
