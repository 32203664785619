import { withValidate } from './withValidate';
import { mapLeft } from 'fp-ts/es6/Either';
/**
 * Returns a clone of the given codec that sets the given string as error messsage
 *
 * @example
 * import { withMessage } from 'io-ts-types/es6/withMessage'
 * import * as t from 'io-ts'
 * import { PathReporter } from 'io-ts/es6/PathReporter'
 * import { right } from 'fp-ts/es6/Either'
 *
 * const T = withMessage(t.number, () => 'Invalid number')
 *
 * assert.deepStrictEqual(T.decode(1), right(1))
 * assert.deepStrictEqual(PathReporter.report(T.decode(null)), ['Invalid number'])
 *
 * @since 0.4.3
 */
export function withMessage(codec, message) {
    return withValidate(codec, function (i, c) {
        return mapLeft(function () { return [
            {
                value: i,
                context: c,
                message: message(i, c),
                actual: i
            }
        ]; })(codec.validate(i, c));
    });
}
