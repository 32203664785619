import * as t from 'io-ts';

export const BookingStartingPoint = t.union(
  [
    t.literal('my_bookings_book_again'),
    t.literal('home_recent_bookings'),
    t.literal('search_results'),
    t.literal('search_autocomplete'),
    t.literal('top_of_search'),
    t.literal('company_details_deeplink'),
    t.literal('favorites'),
    t.literal('ad_campaign'),
  ],
  'BookingStargingPoint',
);

export type BookingStargingPoint = t.TypeOf<typeof BookingStartingPoint>;
