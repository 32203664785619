import * as E from 'fp-ts/lib/Either';
import { identity, pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import { NonEmptyString } from 'io-ts-types';

/**
 * Capitalize a string: first letter to uppercase, others to lowercase
 */
export const capitalize = (s: string): string => {
  return s.length > 0 ? `${s[0].toUpperCase()}${s.slice(1).toLowerCase()}` : s;
};

const OptionalString = t.union([t.string, t.undefined, t.null], 'OptionalString');

/**
 * Type that decodes to undefined from undefined, null, empty or whitespaces-only string
 */
export const UndefinedString = new t.Type<undefined, undefined, unknown>(
  'UndefinedString',
  t.undefined.is,
  (i, c) => {
    return pipe(
      i,
      OptionalString.decode,
      E.chain((s) => {
        if (s && s.trim()) {
          // string non empty
          return t.failure(
            i,
            c,
            `Failed to decode UndefinedString: undefined, empty or space-only string expected, got ${JSON.stringify(
              i,
            )}`,
          );
        }

        return t.success(undefined);
      }),
    );
  },
  () => undefined,
);

export interface DefinedStringBrand {
  readonly DefinedString: unique symbol;
}

/**
 * Type that has at least one non-whitespace symbol
 */
export type DefinedString = t.Branded<NonEmptyString, DefinedStringBrand>;

export const DefinedString: t.Type<DefinedString, string> = t.brand(
  NonEmptyString,
  (s: NonEmptyString): s is DefinedString => {
    return s.trim() !== '';
  },
  'DefinedString',
);

export interface DefinedTrimedStringBrand {
  readonly DefinedTrimedString: unique symbol;
}

/**
 * Type that has at least one non-whitespace symbols and does not start or end with a whitespace
 */
export type DefinedTrimedString = t.Branded<DefinedString, DefinedTrimedStringBrand>;

const isDefinedTrimedString = (s: unknown): s is DefinedTrimedString => {
  return DefinedString.is(s) && /^[^\s]$/g.test(s[0]) && /^[^\s]$/g.test(s[s.length - 1]);
};

export const DefinedTrimedString: t.Type<DefinedTrimedString, string> = DefinedString.pipe(
  new t.Type<DefinedTrimedString, DefinedString, DefinedString>(
    'DefinedTrimedString',
    isDefinedTrimedString,
    (i, c) => {
      const trimmed = i.trim();

      if (isDefinedTrimedString(trimmed)) {
        return t.success(trimmed);
      }

      return t.failure(i, c, `Failed to decode DefinedTrimedString from ${JSON.stringify(i)}`);
    },
    identity,
  ),
);

export const OptionalDefinedString = t.union([DefinedString, UndefinedString]);
export type OptionalDefinedString = t.TypeOf<typeof OptionalDefinedString>;
