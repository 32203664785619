import { fromRefinement } from './fromRefinement';
var isRegExp = function (u) { return Object.prototype.toString.call(u) === '[object RegExp]'; };
/**
 * @example
 * import { regexp } from 'io-ts-types/es6/regexp'
 * import { right } from 'fp-ts/es6/Either'
 *
 * const input1 = /\w+/
 * const input2 = new RegExp('\\w+')
 * assert.deepStrictEqual(regexp.decode(input1), right(input1))
 * assert.deepStrictEqual(regexp.decode(input2), right(input2))
 *
 * @since 0.4.4
 */
export var regexp = fromRefinement('RegExp', isRegExp);
