import { Price } from '../../services/price';
import { SavedService } from '../../services/saved-service';
import { ServiceId } from '../../services/service-id';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type BookedService = {
  readonly _id: ServiceId;
  readonly name: string;
  readonly description: string;
  readonly durationInMinutes: number;
  readonly private: boolean;
  readonly availableDays: number;
  readonly automaticApproval: boolean;
  readonly price: Price;
  readonly color: string;
};

const JSON: t.Type<BookedService, JSONable> = t.type(
  {
    _id: ServiceId,
    name: t.string,
    description: t.string,
    durationInMinutes: t.number,
    private: t.boolean,
    availableDays: t.number,
    automaticApproval: t.boolean,
    price: Price,
    color: t.string,
  },
  'CalendarEntry.BookedService',
);

const fromSavedService = (s: SavedService): BookedService => ({
  _id: s._id,
  name: s.name,
  description: s.description,
  durationInMinutes: s.durationInMinutes,
  private: s.private,
  availableDays: s.availableDays,
  automaticApproval: s.automaticApproval,
  price: s.price,
  color: s.color,
});

export const BookedService = {
  JSON,
  fromSavedService,
};
