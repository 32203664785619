import { UserOrderDetails } from './userOrderDetails';
import { UserOrderPaymentId } from './userOrderPaymentId';
import { UserOrderPaymentStatusPreview } from './userOrderPaymentStatusPreview';
import { MeroUnits, Money, Option, ScaledNumber } from '@mero/shared-sdk';

export type UserOrderPaymentDetails = {
  readonly _id: UserOrderPaymentId;
  readonly status: UserOrderPaymentStatusPreview;
  readonly byUser: {
    readonly firstname: Option<string>;
    readonly lastname: Option<string>;
    readonly phone: Option<string>;
  };
  /**
   * Payment amount
   */
  readonly amount: Money<ScaledNumber, MeroUnits.RON>;
  /**
   * Payment refunds total
   */
  readonly refunds: Option<Money<ScaledNumber, MeroUnits.RON>>;
  /**
   * Payment total (amount - refunds)
   */
  readonly total: Money<ScaledNumber, MeroUnits.RON>;
  readonly order: UserOrderDetails;
  readonly paidAt: Date;
};

export const UserOrderPaymentDetails = {};
