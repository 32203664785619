import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface FavoriteIdBrand {
  readonly FavoriteId: unique symbol;
}

export const FavoriteId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, FavoriteIdBrand> => true,
  'FavoriteId',
);

export type FavoriteId = t.TypeOf<typeof FavoriteId>;

export const HasFavoriteId = t.type(
  {
    _id: FavoriteId,
  },
  'HasFavoriteId',
);
export interface HasFavoriteId extends t.TypeOf<typeof HasFavoriteId> {}
