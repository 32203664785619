import { PageSearch } from '../pages/page-search';
import { FavoriteId } from './favorite-id';
import * as t from 'io-ts';

export const Favorite = t.type(
  {
    _id: FavoriteId,
    page: PageSearch,
  },
  'Favorite',
);

export type Favorite = t.TypeOf<typeof Favorite>;
