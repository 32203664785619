import { ClientId } from '../clients';
import { DiscountId } from '../discounts';
import { GiftCardId } from '../giftCards';
import { PageId } from '../pages';
import { ServiceId } from '../services';
import { UserId } from '../users/user-id';
import { WorkerId } from '../workers';
import { AppointedDayArray } from './appointed-day';
import { AppointmentHistoryRecordArray } from './appointment-history-record';
import { AppointmentId } from './appointment-id';
import { AppointmentStatus } from './appointment-status';
import { AppointmentHistoryType } from './appointmentHistoryType';
import { AppointmentNumericStatus } from './appointmentNumericStatus';
import { AvailableDayArray } from './available-day';
import { BlockedTimeReason } from './blocked-time-reason';
import { BookAppointment2Response } from './bookAppointment2Response';
import { BulkCalendarsResponse } from './bulkCalendarsResponse';
import { Calendar } from './calendar';
import { CalendarApi } from './calendar-api';
import { CalendarId } from './calendar-id';
import { CalendarSettings } from './calendar-settings';
import { CalendarAvailableDays } from './calendarAvailableDays';
import { CalendarDayAvailableTimeSlots } from './calendarDayAvailableTimeSlots';
import { CalendarEntry } from './calendarEntry';
import { CalendarEntryDetails } from './calendarEntryDetails';
import { HasAppointmentId, HasAppointmentIdSimple } from './has-appointment-id';
import { UserAppointment, UserAppointmentArray } from './user-appointment';
import { WaitingList } from './waiting-list';
import { WaitingListDetails } from './waitingListDetails';
import { optionull } from '@mero/shared-sdk';
import {
  DateString,
  DayTime,
  DecodeError,
  HasId,
  HttpClient,
  MeroUnits,
  Money,
  RecurrenceRule,
  ScaledNumber,
  UnknownApiError,
  unsafeRight,
  Paged,
} from '@mero/shared-sdk';
import * as A from 'fp-ts/lib/Array';
import * as E from 'fp-ts/lib/Either';
import * as Nea from 'fp-ts/lib/NonEmptyArray';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';

export const calendarHttpClient = (env: {
  apiBaseUrl: string;
  socketBaseUrl: string;
  http: HttpClient;
}): CalendarApi => {
  const { apiBaseUrl, socketBaseUrl, http } = env;

  const availabilityDecoder = http.decode.response(UnknownApiError, AvailableDayArray);
  const calendarsAvailabilityDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      calendars: t.array(CalendarAvailableDays.JSON),
    }),
  );
  const availableTimeSlotsDecoder = http.decode.response(UnknownApiError, DayTime.ARRAY_JSON);
  const availableTimeSlotsBulkDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      calendars: t.array(CalendarDayAvailableTimeSlots.JSON),
    }),
  );
  const bookAppointmentDecoder = http.decode.response(UnknownApiError, HasAppointmentIdSimple);
  const bookAppointment2Decoder = http.decode.response(UnknownApiError, BookAppointment2Response.JSON);
  const cancelAppointmentIntentDecoder = http.decode.response(UnknownApiError, t.unknown);
  const waitAppointmentPaymentFinishDecoder = http.decode.response(UnknownApiError, HasId.json(AppointmentId));
  const fetchUserAppointmentsDecoder = http.decode.response(UnknownApiError, UserAppointmentArray);
  const fetchUserAppointmentsToLeaveFeedbackDecoder = http.decode.response(UnknownApiError, UserAppointmentArray);
  const fetchUserAppointmentDecoder = http.decode.response(UnknownApiError, UserAppointment);
  const getUserNextAppointmentDecoder = http.decode.optionalResponse(
    UnknownApiError,
    t.union([t.undefined, UserAppointment]),
  );
  const cancelUserAppointmentDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getCalendarByIdDecoder = http.decode.response(UnknownApiError, Calendar);
  const getCalendarEntriesDecoder = http.decode.response(UnknownApiError, t.array(CalendarEntry.Any.JSON));
  const getBulkCalendarDataDecoder = http.decode.response(UnknownApiError, BulkCalendarsResponse.JSON);
  const createOwnAppointmentDecoder = http.decode.response(UnknownApiError, t.type({ _id: AppointmentId }));
  const getCalendarEntryByIdDecoder = http.decode.response(UnknownApiError, CalendarEntryDetails.Any.JSON);
  const updateAppointmentDecoder = http.decode.response(UnknownApiError, t.unknown);
  const createBlockedTimeDecoder = http.decode.response(UnknownApiError, t.type({ _id: AppointmentId }));
  const updateBlockedTimeDecoder = http.decode.response(UnknownApiError, t.unknown);
  const cancelBlockedTimeDecoder = http.decode.response(UnknownApiError, t.unknown);
  const updateSettingsDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getAppointedDaysDecoder = http.decode.response(UnknownApiError, AppointedDayArray);
  const updateCalendarAppointmentStatusDecoder = http.decode.response(UnknownApiError, t.unknown);
  const cancelAppointmentDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getWaitingListDecoder = http.decode.response(UnknownApiError, t.array(WaitingList.JSON));
  const checkWaitingListLimitReachedDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      limitReached: t.boolean,
    }),
  );
  const getPageWaitingListDecoder = http.decode.response(UnknownApiError, t.array(WaitingListDetails.JSON));
  const getPagedWaitingListDecoder = http.decode.response(
    UnknownApiError,
    Paged.json(t.array(WaitingListDetails.JSON)),
  );
  const setWaitingListDecoder = http.decode.response(UnknownApiError, WaitingList.JSON);
  const deleteWaitingListDecoder = http.decode.response(UnknownApiError, t.unknown);
  const socketDefaultDecoder = http.decode.response(UnknownApiError, t.unknown);
  const fetchClientUserAppointmentsDecoder = http.decode.response(
    UnknownApiError,
    t.type({ result: UserAppointmentArray }),
  );
  const fetchPagedClientUserAppointmentsDecoder = http.decode.response(
    UnknownApiError,
    t.intersection([
      Paged.json(UserAppointmentArray),
      t.type({
        futureBookingsCount: optionull(t.number),
        pastBookingsCount: optionull(t.number),
      }),
    ]),
  );

  return {
    getAvailability: async ({ calendarId, from, to, requiredServiceIds }) => {
      const params = { from, to, requiredServiceIds: requiredServiceIds.join(',') };
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/calendar/${encodeURIComponent(calendarId)}/available-day`,
            params: params,
          },
          availabilityDecoder,
        ),
      );
    },

    getCalendarsAvailability: async ({ pageId, calendarIds, from, to, requiredServiceIds }) => {
      const requestBody: {
        calendarIds: CalendarId[];
        from: DateString;
        to: DateString;
        requiredServiceIds: ServiceId[];
      } = {
        calendarIds,
        from,
        to,
        requiredServiceIds,
      };

      const { calendars } = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/calendar/page/${encodeURIComponent(pageId)}/available-day`,
            data: requestBody,
          },
          calendarsAvailabilityDecoder,
        ),
      );

      return calendars;
    },

    getAvailableTimeSlots: async ({ calendarId, day, requiredServiceIds }) => {
      const params = { day, requiredServiceIds: requiredServiceIds.join(',') };

      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/calendar/${encodeURIComponent(calendarId)}/time`,
            params: params,
          },
          availableTimeSlotsDecoder,
        ),
      );
    },

    getCalendarsAvailableTimeSlots: async ({ pageId, calendarIds, day, requiredServiceIds }) => {
      const requestBody: {
        day: string;
        calendarIds: string[];
        requiredServiceIds: string[];
      } = {
        day,
        calendarIds,
        requiredServiceIds,
      };

      const { calendars } = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/calendar/page/${encodeURIComponent(pageId)}/available-time`,
            data: requestBody,
          },
          availableTimeSlotsBulkDecoder,
        ),
      );

      return calendars;
    },

    bookAppointment: async ({
      calendarId,
      start,
      pageId,
      workerId,
      bookedServiceIds,
      giftCardIds,
      discountIds,
      bookingStartingPoint,
      campaignId,
      campaignSource,
    }) => {
      const requestBody: {
        start: Date;
        pageId: PageId;
        workerId: WorkerId;
        bookedServiceIds: ServiceId[];
        giftCardIds: GiftCardId[] | undefined;
        discountIds: DiscountId[] | undefined;
        bookingStartingPoint: string | undefined;
        campaignId: string | undefined;
        campaignSource: string | undefined;
      } = {
        start,
        pageId,
        workerId,
        bookedServiceIds,
        giftCardIds,
        discountIds,
        bookingStartingPoint,
        campaignId,
        campaignSource,
      };

      const appointment = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/calendar/${encodeURIComponent(calendarId)}/appointment`,
            data: requestBody,
          },
          bookAppointmentDecoder,
        ),
      );

      return appointment.id;
    },

    bookPrepaidAppointment: async ({
      pageId,
      workerId,
      start,
      bookedServiceIds,
      giftCardIds,
      discountIds,
      payment,
      bookingStartingPoint,
      campaignId,
      campaignSource,
    }) => {
      const requestBody: {
        start: Date;
        workerId: WorkerId;
        bookedServiceIds: ServiceId[];
        giftCardIds: GiftCardId[] | undefined;
        discountIds: DiscountId[] | undefined;
        payment: {
          paymentMethodId: string;
          amount: Money<ScaledNumber, MeroUnits.RON>;
        };
        bookingStartingPoint: string | undefined;
        campaignId: string | undefined;
        campaignSource: string | undefined;
      } = {
        start,
        workerId,
        bookedServiceIds,
        giftCardIds,
        discountIds,
        payment,
        bookingStartingPoint,
        campaignId,
        campaignSource,
      };

      const response = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/calendar/page/${encodeURIComponent(pageId)}/prepaid-appointment`,
            data: requestBody,
          },
          bookAppointment2Decoder,
        ),
      );

      return response;
    },

    cancelAppointmentIntent: async ({ pageId, appointmentIntentId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/calendar/page/${encodeURIComponent(pageId)}/appointment-intent/${encodeURIComponent(
              appointmentIntentId,
            )}`,
          },
          cancelAppointmentIntentDecoder,
        ),
      );
    },

    waitAppointmentIntentFinish: async ({ pageId, appointmentIntentId }) => {
      const response = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/calendar/page/${encodeURIComponent(
              pageId,
            )}/appointment-intent/${appointmentIntentId}/wait-finish`,
          },
          waitAppointmentPaymentFinishDecoder,
        ),
      );

      return response._id;
    },

    fetchUserAppointments: async ({ pageId, limit, offset, includeDeleted }) => {
      const params: {
        pageId?: string;
        limit?: number;
        offset?: number;
        includeDeleted?: boolean;
      } = { pageId, limit, offset, includeDeleted };

      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/calendar/appointment`,
            params: params,
          },
          fetchUserAppointmentsDecoder,
        ),
      );
    },

    fetchUserAppointmentsToLeaveFeedback: async () => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/calendar/appointments-to-leave-feedback`,
          },
          fetchUserAppointmentsToLeaveFeedbackDecoder,
        ),
      );
    },

    fetchUserAppointment: async (id, occurrenceIndex) => {
      const query = {
        occurrenceIndex: occurrenceIndex,
      };
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/calendar/appointment/${encodeURIComponent(id)}`,
            params: query,
          },
          fetchUserAppointmentDecoder,
        ),
      );
    },

    getUserNextAppointment: async () =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/calendar/user-next-appointment`,
          },
          getUserNextAppointmentDecoder,
        ),
      ),

    cancelUserAppointment: async ({ calendarId, appointmentId, reason }) => {
      const params: {
        reason: string;
      } = { reason };

      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/calendar/${encodeURIComponent(calendarId)}/appointment/${encodeURIComponent(
              appointmentId,
            )}`,
            params: params,
          },
          cancelUserAppointmentDecoder,
        ),
      );
    },

    getCalendarById: async (id) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/calendar/${encodeURIComponent(id)}`,
          },
          getCalendarByIdDecoder,
        ),
      ),

    getCalendarEntries: async ({ calendarId, from, to, includeDeleted }) => {
      const params: {
        from: Date;
        to: Date;
        includeDeleted?: boolean;
      } = { from, to, includeDeleted };

      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/calendar/${encodeURIComponent(calendarId)}/management/entry`,
            params: params,
          },
          getCalendarEntriesDecoder,
        ),
      );
    },

    getBulkCalendarData: async ({ pageId, calendarIds, from, to, includeDeleted, hasFinishedCheckoutTransactions }) => {
      const params: {
        calendarIds: string[];
        from: Date;
        to: Date;
        includeDeleted: boolean | undefined;
        hasFinishedCheckoutTransactions: boolean | undefined;
      } = {
        from,
        to,
        includeDeleted,
        calendarIds: t.array(CalendarId).encode(calendarIds),
        hasFinishedCheckoutTransactions,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/calendar/page/${encodeURIComponent(pageId)}/management/calendars-entries`,
            params: params,
          },
          getBulkCalendarDataDecoder,
        ),
      );
    },

    createOwnAppointment: async ({ calendarId, ...rest }) => {
      const requestBody: {
        start: Date;
        end: Date;
        recurrenceRule?: RecurrenceRule.Any;
        pageId: PageId;
        workerId: WorkerId;
        userId?: UserId;
        clientId?: ClientId;
        bookedServiceIds: ServiceId[];
        note?: string;
        override: boolean;
      } = rest;

      const appointment = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/calendar/${encodeURIComponent(calendarId)}/management/appointment`,
            data: requestBody,
          },
          createOwnAppointmentDecoder,
        ),
      );

      return appointment._id;
    },

    getCalendarEntryById: async ({ pageId, entryId, occurrenceIndex }) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/calendar/page/${encodeURIComponent(
              pageId,
            )}/management/calendar-entries/${encodeURIComponent(entryId)}`,
            params: {
              occurrenceIndex: occurrenceIndex,
            },
          },
          getCalendarEntryByIdDecoder,
        ),
      ),

    updateAppointment: async ({ calendarId, appointmentId, ...rest }) => {
      const requestBody: {
        start: Date;
        end: Date;
        recurrent: boolean;
        recurrenceRule?: RecurrenceRule.Any;
        bookedServiceIds: ServiceId[];
        workerId: WorkerId;
        note?: string;
        override: boolean;
        occurrenceIndex?: number;
        onlyOnce: boolean;
      } = rest;

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/calendar/${encodeURIComponent(calendarId)}/management/appointment/${encodeURIComponent(
              appointmentId,
            )}`,
            data: requestBody,
          },
          updateAppointmentDecoder,
        ),
      );
    },

    createBlockedTime: async ({ calendarId, ...rest }) => {
      const requestBody: {
        start: Date;
        end: Date;
        recurrenceRule?: RecurrenceRule.Any;
        pageId: PageId;
        workerId: WorkerId;
        reason: BlockedTimeReason;
        override: boolean;
      } = rest;

      const blockedTime = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/calendar/${encodeURIComponent(calendarId)}/management/blocked-time`,
            data: requestBody,
          },
          createBlockedTimeDecoder,
        ),
      );

      return blockedTime._id;
    },

    cancelBlockedTime: async ({ calendarId, entryId, occurrenceIndex, onlyOnce }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/calendar/${encodeURIComponent(calendarId)}/management/blocked-time/${encodeURIComponent(
              entryId,
            )}`,
            params: {
              occurrenceIndex: occurrenceIndex,
              onlyOnce: onlyOnce,
            },
          },
          cancelBlockedTimeDecoder,
        ),
      );
    },

    updateBlockedTime: async ({ calendarId, appointmentId, ...rest }) => {
      const requestBody: {
        start: Date;
        end: Date;
        recurrent: boolean;
        recurrenceRule?: RecurrenceRule.Any;
        reason: BlockedTimeReason;
        override: boolean;
        occurrenceIndex?: number;
        onlyOnce: boolean;
      } = rest;

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/calendar/${encodeURIComponent(calendarId)}/management/blocked-time/${encodeURIComponent(
              appointmentId,
            )}`,
            data: requestBody,
          },
          updateBlockedTimeDecoder,
        ),
      );
    },

    updateSettings: async ({ calendarId, pageId, newSettings }) => {
      const requestBody: CalendarSettings = newSettings;

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/calendar/page/${encodeURIComponent(pageId)}/calendars/${encodeURIComponent(
              calendarId,
            )}/settings`,
            data: requestBody,
          },
          updateSettingsDecoder,
        ),
      );
    },

    getAppointedDays: async ({ calendarId, from, to }) => {
      const params: {
        from: Date;
        to: Date;
      } = {
        from,
        to,
      };
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/calendar/${encodeURIComponent(calendarId)}/appointed-days`,
            params: params,
          },
          getAppointedDaysDecoder,
        ),
      );
    },

    updateCalendarAppointmentStatus: async ({ calendarId, entryId, newStatus, occurrenceIndex }) => {
      const requestBody: {
        status: AppointmentNumericStatus.Any;
        occurrenceIndex: number;
      } = {
        status: AppointmentStatus.encode(newStatus),
        occurrenceIndex,
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/calendar/${encodeURIComponent(calendarId)}/management/appointment/${encodeURIComponent(
              entryId,
            )}/status`,
            data: requestBody,
          },
          updateCalendarAppointmentStatusDecoder,
        ),
      );
    },

    cancelAppointment: async ({ calendarId, entryId, occurrenceIndex, onlyOnce, reason }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/calendar/${encodeURIComponent(calendarId)}/management/appointment/${encodeURIComponent(
              entryId,
            )}`,
            params: {
              occurrenceIndex: occurrenceIndex,
              onlyOnce: onlyOnce,
              reason: reason,
            },
          },
          cancelAppointmentDecoder,
        ),
      );
    },

    getAppointmentWaitingList: async ({ workerId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/worker/${encodeURIComponent(workerId)}/appointment-opening-interest2`,
          },
          getWaitingListDecoder,
        ),
      );
    },

    checkWaitingListLimitReached: async ({ workerId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/worker/${encodeURIComponent(workerId)}/appointment-opening-interest-limit`,
          },
          checkWaitingListLimitReachedDecoder,
        ),
      );
    },

    setAppointmentWaitingList: async ({
      pageId,
      workerId,
      date,
      serviceIds,
      bookingStartingPoint,
      campaignId,
      campaignSource,
    }) => {
      const requestBody: {
        readonly pageId: PageId;
        readonly date: DateString;
        readonly serviceIds: Nea.NonEmptyArray<ServiceId>;
        readonly bookingStartingPoint: string | undefined;
        readonly campaignId: string | undefined;
        readonly campaignSource: string | undefined;
      } = {
        pageId: pageId,
        date: date,
        serviceIds: serviceIds,
        bookingStartingPoint: bookingStartingPoint,
        campaignId: campaignId,
        campaignSource: campaignSource,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/worker/${encodeURIComponent(workerId)}/appointment-opening-interest2`,
            data: requestBody,
          },
          setWaitingListDecoder,
        ),
      );
    },

    fetchPagedWaitingList: async ({ pageId, workerId, limit, page }) => {
      const params: {
        limit: number;
        page?: string;
        workerId?: WorkerId;
      } = {
        limit: limit,
        page: page,
        workerId: workerId,
      };

      const data = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/waiting-list/v2`,
            params: params,
          },
          getPagedWaitingListDecoder,
        ),
      );

      return data;
    },

    deleteAppointmentWaitingList: async ({ workerId, waitingListId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/worker/${encodeURIComponent(
              workerId,
            )}/appointment-opening-interest/${encodeURIComponent(waitingListId)}`,
          },
          deleteWaitingListDecoder,
        ),
      );
    },

    deleteAppointmentWaitingListByWorker: async ({ pageId, waitingListId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              pageId,
            )}/appointment-opening-interest/${encodeURIComponent(waitingListId)}`,
          },
          deleteWaitingListDecoder,
        ),
      );
    },

    socket: async ({ calendarId, handlers }) => {
      const getCodecs = (name: string) => {
        switch (name) {
          case 'calendar-entry-added':
            return HasAppointmentId;
          case 'calendar-entry-removed':
            return HasAppointmentId;
          default:
            return socketDefaultDecoder;
        }
      };

      const eventHandlers = Object.entries(handlers).map(([name, handler]) => ({
        name,
        handler,
        codec: getCodecs(name),
      }));
      return await http.socket(
        {
          route: `${socketBaseUrl}/calendar`,
          query: { calendarId },
        },
        eventHandlers,
      );
    },

    getUserAppointmentIcs: (id, userId) =>
      `${apiBaseUrl}/calendar/appointment/${encodeURIComponent(id)}/ics?userId=${encodeURIComponent(userId)}`,

    getCalendarIcsUrl: (calendarId, workerId) =>
      `${apiBaseUrl}/calendar/${encodeURIComponent(calendarId)}/management/ics?workerId=${encodeURIComponent(
        workerId,
      )}`,

    fetchClientUserAppointments: async ({ clientId, limit, offset }) => {
      const params: {
        limit?: number;
        offset?: number;
      } = { limit, offset };

      const { result } = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/calendar/management/clients/${encodeURIComponent(clientId)}/user-appointments`,
            params: params,
          },
          fetchClientUserAppointmentsDecoder,
        ),
      );

      return result;
    },
    fetchPagedClientUserAppointments: async ({ clientId, limit, page }) => {
      const data = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/calendar/management/clients/${encodeURIComponent(clientId)}/user-appointments/v2`,
            params: {
              limit,
              ...(page ? { page } : {}),
            },
          },
          fetchPagedClientUserAppointmentsDecoder,
        ),
      );

      return data;
    },
    fetchAppointmentHistory: async ({ appointmentId }) => {
      const unknownHistoryRecordArray = http.decode.response(UnknownApiError, t.array(t.unknown));

      const unknownHistoryRecords = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/calendar/appointment/${encodeURIComponent(appointmentId)}/history`,
          },
          unknownHistoryRecordArray,
        ),
      );
      const withHistoryTypeCodec = t.type({
        type: AppointmentHistoryType,
      });

      return pipe(
        unknownHistoryRecords,
        A.map(withHistoryTypeCodec.decode),
        A.chain(
          E.fold(
            () => [],
            (n) => [n],
          ),
        ),
        AppointmentHistoryRecordArray.decode,
        E.getOrElseW((e) => {
          throw new DecodeError(e);
        }),
      );
    },
  };
};
