import { DownloadEstimate } from '../download-estimate';
import { Invoice } from '../invoice';
import { PageMonthlyInvoiceArchive } from '../monthly-invoice-archive';
import { Order } from '../order';
import { PaymentsApi } from '../payments-api';
import { SavedCardPreview } from '../savedCardPreview';
import { StripeConnectedAccount } from '../stripe-connected-account';
import { StripePaymentIntentPreview } from '../stripe-payment-intent-preview';
import { SubscriptionInfo } from '../subscription-info';
import { SubscriptionSeat } from '../subscription-seat';
import { PaymentIntent, UserBillingDetails } from '../user-billings';
import * as PaymentsJson from './paymentsJson';
import { HttpClient, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const paymentsHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): PaymentsApi => {
  const { apiBaseUrl, http } = env;

  const getPageSubscriptionDecoder = http.decode.optionalResponse(
    UnknownApiError,
    t.union([t.undefined, SubscriptionInfo]),
  );
  const getCalendarAccessSubscriptionDecoder = http.decode.optionalResponse(
    UnknownApiError,
    t.union([t.undefined, SubscriptionInfo]),
  );
  const listUserSavedCardsDecoder = http.decode.response(
    UnknownApiError,
    t.type({ result: t.array(SavedCardPreview.JSON) }),
  );
  const chargeForAppointmentDecoder = http.decode.response(UnknownApiError, t.type({ result: PaymentIntent }));
  const chargeForGiftCardDecoder = http.decode.response(UnknownApiError, t.type({ result: PaymentIntent }));
  const createCardSetupIntentDecoder = http.decode.response(UnknownApiError, t.type({ result: t.string }));

  const getUserBillingDetailsDecoder = http.decode.response(UnknownApiError, t.partial({ result: UserBillingDetails }));
  const setUserBillingDetailsDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getPageStripeAccountDecoder = http.decode.response(UnknownApiError, t.type({ result: StripeConnectedAccount }));

  const updateBillingCycleDecoder = http.decode.response(UnknownApiError, t.unknown);
  const listPendingOrdersDecoder = http.decode.response(UnknownApiError, t.array(Order));

  const getSubscriptionOrderByIdDecoder = http.decode.response(UnknownApiError, Order);
  const getSubscriptionInvoicesDecoder = http.decode.response(UnknownApiError, t.array(Invoice));
  const getStripeConnectOauthLinkDecoder = http.decode.response(UnknownApiError, t.type({ result: t.string }));
  const connectStripeAccountDecoder = http.decode.response(UnknownApiError, t.unknown);
  const setSubscriptionAutoChargeDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getSubscriptionSeatUpdateInfoDecoder = http.decode.response(
    UnknownApiError,
    t.type({ result: SubscriptionSeat }),
  );
  const updateSubscriptionSeatCountDecoder = http.decode.response(UnknownApiError, t.unknown);
  const listSubscriptionSavedCardsDecoder = http.decode.response(
    UnknownApiError,
    PaymentsJson.ListSubscriptionSavedCardsResponse,
  );
  const updateSubscriptionSca3dsDecoder = http.decode.response(UnknownApiError, t.unknown);
  const downloadOrderEstimateDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getOrderEstimateUrlDecoder = http.decode.response(UnknownApiError, DownloadEstimate);
  const uploadPaymentProofDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getPageMonthlyInvoiceArchivesDecoder = http.decode.response(
    UnknownApiError,
    t.type({ result: t.array(PageMonthlyInvoiceArchive) }),
  );
  const setSubscriptionBillingDecoder = http.decode.response(UnknownApiError, t.unknown);
  const initSubscriptionStripePaymentDecoder = http.decode.response(UnknownApiError, StripePaymentIntentPreview);

  return {
    getPageSubscription: async (pageId) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/payment/page/${encodeURIComponent(pageId)}/subscription`,
          },
          getPageSubscriptionDecoder,
        ),
      ),
    setSubscriptionBilling: async (subscriptionId, billingDetails) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/payment/subscription/${encodeURIComponent(subscriptionId)}/billing`,
            data: billingDetails,
          },
          setSubscriptionBillingDecoder,
        ),
      );
    },
    getCalendarAccessSubscription: async (pageId) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/payment/page/${encodeURIComponent(pageId)}/calendar/subscription`,
          },
          getCalendarAccessSubscriptionDecoder,
        ),
      ),
    listUserSavedCards: async () =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/payment/user/card`,
          },
          listUserSavedCardsDecoder,
        ),
      ).result,
    chargeForAppointment: async (params: {
      appointmentId: string;
      occurrenceIndex: number | undefined;
      cardId?: string;
      amount: number;
      useApplePay?: boolean;
    }) =>
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/payment/appointment/${params.appointmentId}/charge`,
            data: {
              occurrenceIndex: params.occurrenceIndex,
              cardId: params.cardId,
              amount: params.amount,
              useApplePay: params.useApplePay,
            },
          },
          chargeForAppointmentDecoder,
        ),
      ).result,
    chargeForGiftCard: async (params) =>
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/payment/gift-cards/${params.giftCardId}/charge`,
            data: {
              cardId: params.cardId,
              useApplePay: params.useApplePay,
            },
          },
          chargeForGiftCardDecoder,
        ),
      ).result,
    chargeAnonymouslyForGiftCard: async (params) =>
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/payment/gift-cards/${params.giftCardId}/anonymous-charge`,
            data: {
              userId: params.userId,
              billing: params.billing,
            },
          },
          chargeForGiftCardDecoder,
        ),
      ).result,

    createCardSetupIntent: async () =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/payment/user/card-setup`,
          },
          createCardSetupIntentDecoder,
        ),
      ).result,
    getUserBillingDetails: async () =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/payment/user/billing`,
          },
          getUserBillingDetailsDecoder,
        ),
      ).result,

    setUserBillingDetails: async (userBillingDetails) => {
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/payment/user/billing`,
            data: userBillingDetails,
          },
          setUserBillingDetailsDecoder,
        ),
      );
    },

    getPageStripeAccount: async (pageId) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/payment/page/${pageId}/stripe`,
          },
          getPageStripeAccountDecoder,
        ),
      ).result,

    updateBillingCycle: async ({ subscriptionId, billingCycle }) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/payment/subscription/${subscriptionId}/billing-cycle`,
            data: {
              billingCycle,
            },
          },
          updateBillingCycleDecoder,
        ),
      );
    },

    listPendingOrders: async (subscriptionId) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/payment/subscription/${subscriptionId}/order`,
          },
          listPendingOrdersDecoder,
        ),
      ),

    getSubscriptionOrderById: async ({ subscriptionId, orderId }) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/payment/subscription/${subscriptionId}/order/${orderId}`,
          },
          getSubscriptionOrderByIdDecoder,
        ),
      ),

    getSubscriptionInvoices: async (subscriptionId) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/payment/subscription/${subscriptionId}/invoice`,
          },
          getSubscriptionInvoicesDecoder,
        ),
      ),

    getStripeConnectOauthLink: async ({ pageId, redirectUri }) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/payment/page/${pageId}/stripe-oauth`,
            params: {
              redirectUri,
            },
          },
          getStripeConnectOauthLinkDecoder,
        ),
      ),

    connectStripeAccount: async ({ pageId, code }) => {
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/payment/page/${pageId}/stripe-oauth`,
            data: {
              code,
            },
          },
          connectStripeAccountDecoder,
        ),
      );
    },

    setSubscriptionAutoCharge: async ({ subscriptionId, autoCharge }) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/payment/subscription/${subscriptionId}/autocharge`,
            data: {
              autoCharge,
            },
          },
          setSubscriptionAutoChargeDecoder,
        ),
      );
    },

    getSubscriptionSeatUpdateInfo: async ({ subscriptionId, newSeats }) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/payment/subscription/${subscriptionId}/seat`,
            params: {
              newSeats: newSeats,
            },
          },
          getSubscriptionSeatUpdateInfoDecoder,
        ),
      ),

    updateSubscriptionSeatCount: async ({ subscriptionId, newSeats }) => {
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/payment/subscription/${subscriptionId}/seat`,
            data: {
              newSeats: newSeats,
            },
          },
          updateSubscriptionSeatCountDecoder,
        ),
      );
    },

    listSubscriptionSavedCards: async ({ subscriptionId }) => {
      const { result } = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/payment/subscription/${encodeURIComponent(subscriptionId)}/saved-cards`,
          },
          listSubscriptionSavedCardsDecoder,
        ),
      );

      return result;
    },

    updateSubscriptionSca3ds: async (params) => {
      const { subscriptionId, ...sca3ds } = params;
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/payment/subscription/${subscriptionId}/sca3ds`,
            data: sca3ds,
          },
          updateSubscriptionSca3dsDecoder,
        ),
      );
    },

    downloadOrderEstimate: async ({ orderId }) =>
      unsafeRight(
        await http.request(
          {
            responseType: 'blob',
            method: 'GET',
            url: `${apiBaseUrl}/payment/order/${orderId}/estimate`,
          },
          downloadOrderEstimateDecoder,
        ),
      ),

    getOrderEstimateUrl: async ({ orderId }) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/payment/order/${orderId}/estimate-download-url`,
          },
          getOrderEstimateUrlDecoder,
        ),
      ),

    async uploadPaymentProof({ formData, subscriptionId }) {
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            url: `${apiBaseUrl}/payment/subscription/${subscriptionId}/payment-proof`,
            data: formData,
          },
          uploadPaymentProofDecoder,
        ),
      );
    },

    getPageMonthlyInvoiceArchives: async ({ pageId }) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/payment/page/${pageId}/monthly-invoice-archives`,
          },
          getPageMonthlyInvoiceArchivesDecoder,
        ),
      ),

    initSubscriptionStripePayment: async ({ pageId, subscriptionId, redirectUrl, paymentMethodId }) => {
      const data: {
        redirectUrl: string | undefined;
        paymentMethodId: string | undefined;
      } = {
        redirectUrl,
        paymentMethodId,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/payment/page/${encodeURIComponent(pageId)}/subscription/${encodeURIComponent(
              subscriptionId,
            )}/stripe/pay`,
            data,
          },
          initSubscriptionStripePaymentDecoder,
        ),
      );
    },
  };
};
