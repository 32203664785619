/**
 * Returns a clone of the given codec
 *
 * @example
 * import { clone } from 'io-ts-types/es6/clone'
 * import * as t from 'io-ts'
 *
 * assert.deepStrictEqual(clone(t.string), t.string)
 *
 * @since 0.4.3
 */
export function clone(t) {
    var r = Object.create(Object.getPrototypeOf(t));
    Object.assign(r, t);
    return r;
}
