/**
 * @since 0.5.11
 */
import * as t from 'io-ts';
import { pipe } from 'fp-ts/es6/pipeable';
import { chain } from 'fp-ts/es6/Either';
import { NonEmptyString } from './NonEmptyString';
/**
 * @example
 * import { BigIntFromString } from 'io-ts-types/es6/BigIntFromString'
 * import { right } from 'fp-ts/es6/Either'
 * import { PathReporter } from 'io-ts/es6/PathReporter'
 *
 * assert.deepStrictEqual(BigIntFromString.decode('1'), right(BigInt(1)))
 * assert.deepStrictEqual(PathReporter.report(BigIntFromString.decode('1.1')), ['Invalid value "1.1" supplied to : BigIntFromString'])
 * assert.deepStrictEqual(PathReporter.report(BigIntFromString.decode('')), ['Invalid value "" supplied to : BigIntFromString'])
 * assert.deepStrictEqual(PathReporter.report(BigIntFromString.decode(' ')), ['Invalid value " " supplied to : BigIntFromString'])
 *
 * @since 0.5.11
 */
export var BigIntFromString = new t.Type('BigIntFromString', 
// tslint:disable-next-line
function (u) { return typeof u === 'bigint'; }, function (u, c) {
    return pipe(t.string.validate(u, c), chain(function (s) {
        if (!NonEmptyString.is(s.trim())) {
            return t.failure(u, c);
        }
        try {
            return t.success(BigInt(s));
        }
        catch (error) {
            return t.failure(u, c);
        }
    }));
}, String);
