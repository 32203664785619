import * as MeroApi from '@mero/api-sdk';
import * as luxon from 'luxon';
import * as React from 'react';
import { View } from 'react-native';

import { colors } from '../../../styles';
import { formatRangePrice } from '../../../utils/format';
import Icon from '../../Icon';
import Column from '../../Layout/Column';
import Row from '../../Layout/Row';
import Line from '../../Line';
import Spacer from '../../Spacer';
import SmallBody from '../../Text/SmallBody';
import Title from '../../Text/Title';
import { styles } from './styles';

export type Props = {
  /**
   * Appointment to display.
   */
  appointment: MeroApi.calendar.UserAppointment;
  compact?: boolean;
};

/**
 * Componet used to display summary of an user appointment.
 * Summary includes services names, price, worker name and times
 */
export default function UserAppointmentSummary({ appointment, compact = true }: Props): React.ReactElement {
  const [servicesText, setServicesText] = React.useState('');
  const [workerNameText, setWorkerNameText] = React.useState('');
  const [appointmentDateText, setAppointmentDateText] = React.useState('');
  const [priceText, setPriceText] = React.useState('');
  const [discountedPricePaid, setDiscountedPricePaid] = React.useState(0);

  // init method to extract info strings from appointment
  React.useEffect(() => {
    let servicesNames = appointment.bookedServices[0]?.name ?? '';
    if (appointment.bookedServices.length > 1) {
      servicesNames += ` (+ alte ${appointment.bookedServices.length - 1})`;
    }
    setServicesText(servicesNames);

    setWorkerNameText(
      `${appointment.worker.firstname} ${appointment.worker.lastname ?? ''} - ${appointment.page.name}`,
    );

    const from = luxon.DateTime.fromJSDate(appointment.start).setZone(appointment.timezone);
    const to = luxon.DateTime.fromJSDate(appointment.end).setZone(appointment.timezone);
    setAppointmentDateText(`${from.toFormat('HH:mm')} - ${to.toFormat('HH:mm')} (${to.diff(from).toFormat('mm')} min)`);

    const discounts =
      (appointment.giftCards?.reduce((acc, { price }) => acc + price, 0) ?? 0) +
      (appointment.discounts?.reduce((acc, { fixedAmount }) => acc + fixedAmount, 0) ?? 0);

    // compute total price
    const totalPrice = appointment.bookedServices
      .map((sv) => sv.price)
      .reduce((sum, price) => MeroApi.services.price.sum(sum, price), MeroApi.services.Zero);

    if (totalPrice.type === 'fixed') {
      setPriceText(`${totalPrice.fixed} lei`);
      setDiscountedPricePaid(Math.max(totalPrice.fixed - discounts, 0));
    } else if (totalPrice.type === 'range') {
      setPriceText(`${formatRangePrice(totalPrice.range)} lei`);
    } else if (totalPrice.type === 'hidden') {
      setPriceText(`preț variabil`);
    }
  }, []);

  const hasGiftCards = (appointment.giftCards?.length ?? 0) > 0;
  const hasDiscounts = (appointment.discounts?.length ?? 0) > 0;

  return (
    <>
      <View style={[styles.container]}>
        {!compact && (hasGiftCards || hasDiscounts) && (
          <Column>
            <Icon color={colors.COMET} type="checked-outline" />
          </Column>
        )}

        {/* info */}
        <View style={[styles.infoContainer, { paddingLeft: compact || (!hasGiftCards && !hasDiscounts) ? 0 : 8 }]}>
          <Title>{servicesText}</Title>

          <SmallBody>{workerNameText}</SmallBody>
          <Spacer size="6" />
          <SmallBody style={styles.dateLabel}>{appointmentDateText}</SmallBody>
        </View>

        {/* prices */}
        <View style={[styles.priceContainer]}>
          {compact && (hasGiftCards || hasDiscounts) ? (
            <>
              <Title>{discountedPricePaid} lei</Title>
              <SmallBody style={{ color: colors.COMET, fontFamily: 'open-sans', textDecorationLine: 'line-through' }}>
                {priceText}
              </SmallBody>
            </>
          ) : (
            <Title>{priceText}</Title>
          )}
        </View>
      </View>
      {!compact && hasGiftCards && (
        <>
          {appointment.giftCards?.map((giftCard) => (
            <Row key={giftCard.code} style={styles.giftCard}>
              <Column>
                <Icon color={colors.COMET} type="gift" />
              </Column>
              <Column style={styles.giftCardText}>
                <Title>Card cadou {giftCard.displayCode.toUpperCase()}</Title>
              </Column>
              <Column>
                <Title>-{giftCard.price} lei</Title>
              </Column>
            </Row>
          ))}
        </>
      )}
      {!compact && hasDiscounts && (
        <>
          {appointment.discounts?.map((discount) => (
            <Row key={discount.code} style={styles.discount}>
              <Column>
                <Icon color={colors.COMET} type="discount" />
              </Column>
              <Column style={styles.discountText}>
                <Title>Cod reducere {discount.code.toUpperCase()}</Title>
              </Column>
              <Column>
                <Title>-{discount.fixedAmount} lei</Title>
              </Column>
            </Row>
          ))}
        </>
      )}
      {!compact && (hasGiftCards || hasDiscounts) && (
        <>
          <Spacer size={16} />
          <Line />
          <Row style={{ paddingTop: 16 }}>
            <Title style={{ fontFamily: 'open-sans-bold', flex: 1 }}>Total</Title>
            <Title style={{ fontFamily: 'open-sans-bold' }}>{discountedPricePaid} lei</Title>
          </Row>
        </>
      )}
    </>
  );
}
