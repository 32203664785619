/**
 * @since 0.4.4
 */
import * as t from 'io-ts';
/**
 * Returns a codec from a refinement
 *
 * @since 0.4.4
 */
export function fromRefinement(name, is) {
    return new t.Type(name, is, function (u, c) { return (is(u) ? t.success(u) : t.failure(u, c)); }, t.identity);
}
