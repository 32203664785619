/**
 * @since 0.5.13
 */
import * as t from 'io-ts';
import { pipe } from 'fp-ts/es6/pipeable';
import { map } from 'fp-ts/Either';
/**
 * @example
 * import { BooleanFromNumber } from 'io-ts-types/es6/BooleanFromNumber'
 * import { right } from 'fp-ts/es6/Either'
 * import { PathReporter } from 'io-ts/es6/PathReporter'
 *
 * assert.deepStrictEqual(BooleanFromNumber.decode(1), right(true))
 * assert.deepStrictEqual(BooleanFromNumber.decode(0), right(false))
 * assert.deepStrictEqual(BooleanFromNumber.decode(123), right(true))
 * assert.deepStrictEqual(PathReporter.report(BooleanFromNumber.decode('a')), ['Invalid value "a" supplied to : BooleanFromNumber'])
 *
 * @since 0.5.13
 */
export var BooleanFromNumber = new t.Type('BooleanFromNumber', t.boolean.is, function (u, c) {
    return pipe(t.number.validate(u, c), map(function (n) { return n !== 0; }));
}, Number);
