/**
 * Returns shuffled version of an array
 */
const shuffled = <T>(arr: T[]): T[] => {
  return arr
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
};

export const Arrays = {
  shuffled,
};
