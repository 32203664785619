import { CalendarEntry } from '../../calendar/calendarEntry';
import { PageBillingDetails } from '../../pro/pageBillingDetails/pageBillingDetails';
import { CheckoutCashRegisterDriverId } from '../cashRegisters';
import { CheckoutCashRegisterDriverConfig } from '../cashRegisters/checkoutCashRegisterDriverConfig';
import { CheckoutSavedCashRegister } from '../cashRegisters/checkoutSavedCashRegister';
import {
  CheckoutCashRegistry,
  CheckoutCashRegistryTransaction,
  CheckoutCashRegistryTransactionList,
  CheckoutCashRegistryTransactionType,
  DownloadCashRegistryExport,
} from '../cashRegistry';
import {
  CheckoutApi,
  CheckoutDraftRequest,
  CheckoutRequest,
  UpdateCheckoutDraftRequest,
  CreateCheckoutDraftRequest,
} from '../checkoutApi';
import { CheckoutCashRegisterPrintTicket } from '../checkoutCashRegisterPrintTicket';
import { CheckoutPageDefaults } from '../checkoutPageDefaults';
import { CheckoutPageSettings } from '../checkoutPageSettings';
import { CheckoutTransactionDetails } from '../checkoutTransactionDetails';
import { CheckoutTransactionPreview } from '../checkoutTransactionPreview';
import { SavedCheckoutCompany, SavedCheckoutCompanyWithRegisters } from '../companies';
import * as CheckoutJson from './checkoutJson';
import {
  HttpClient,
  unsafeRight,
  UnknownApiError,
  MeroUnits,
  PositiveInt,
  Zero,
  Paged,
  Money,
  ScaledNumber,
  Option,
} from '@mero/shared-sdk';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

/**
 * CheckoutApi HTTP client implementation
 */
export const checkoutHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): CheckoutApi => {
  const { apiBaseUrl, http } = env;

  const listPageDraftTransactionsDecoder = http.decode.response(
    UnknownApiError,
    Paged.json(CheckoutJson.CheckoutTransactionPreviewDraftArrayC),
  );
  const getPageDraftTransactionsCountDecoder = http.decode.response(
    UnknownApiError,
    t.type({ count: t.union([PositiveInt.JSON, Zero]) }, 'DaftsCountResponse'),
  );
  const listPageFinishedTransactionsDecoder = http.decode.response(
    UnknownApiError,
    Paged.json(CheckoutJson.CheckoutTransactionPreviewFinishedArrayC),
  );
  const listPageSubmitedTransactionsDecoder = http.decode.response(
    UnknownApiError,
    Paged.json(CheckoutJson.CheckoutTransactionPreviewSubmitedArrayC),
  );
  const listAppointmentTransactionsDecoder = http.decode.response(
    UnknownApiError,
    Paged.json(CheckoutJson.CheckoutTransactionPreviewArrayC),
  );
  const getTransactionDecoder = http.decode.response(UnknownApiError, CheckoutJson.CheckoutTransactionDetailsC);
  const getAppointmentTransactionByIdDecoder = http.decode.response(
    UnknownApiError,
    CheckoutJson.CheckoutTransactionDetailsC,
  );
  const deleteTransactionDecoder = http.decode.response(UnknownApiError, t.unknown);
  const listSavedCompaniesDecoder = http.decode.response(
    UnknownApiError,
    t.array(CheckoutJson.SavedCheckoutCompanyWithRegistersC),
  );
  const getPageSettingsDecoder = http.decode.response(UnknownApiError, CheckoutJson.CheckoutPageSettingsC);
  const savePageSettingsDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getPageDefaultsDecoder = http.decode.response(UnknownApiError, CheckoutJson.CheckoutPageDefaultsC);
  const registerCompanyDecoder = http.decode.response(UnknownApiError, CheckoutJson.SavedCheckoutCompanyC);
  const registerCompanyBillingDetailsDecoder = http.decode.response(
    UnknownApiError,
    CheckoutJson.SavedCheckoutCompanyC,
  );
  const updateCompanyEmitReceiptStatusDecoder = http.decode.response(UnknownApiError, t.unknown);
  const updateCompanyDecoder = http.decode.response(UnknownApiError, CheckoutJson.SavedCheckoutCompanyC);
  const updateCompanyBillingDetailsDecoder = http.decode.response(UnknownApiError, CheckoutJson.SavedCheckoutCompanyC);
  const getCompanyDetailsDecoder = http.decode.response(UnknownApiError, CheckoutJson.SavedCheckoutCompanyC);
  const deleteCompanyDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getCompanyAutocompleteDecoder = http.decode.optionalResponse(
    UnknownApiError,
    CheckoutJson.CheckoutCompanyAutocompleteC,
  );
  const getUnpaidAppointmentsDecoder = http.decode.response(UnknownApiError, t.array(CalendarEntry.Appointment.JSON));
  const listCashRegisterDriversDecoder = http.decode.response(
    UnknownApiError,
    t.array(CheckoutJson.CheckoutCashRegisterDriverDetailsC),
  );
  const registerCashRegisterDecoder = http.decode.response(UnknownApiError, CheckoutJson.CheckoutSavedCashRegisterC);
  const getCashRegisterDecoder = http.decode.response(UnknownApiError, CheckoutJson.CheckoutSavedCashRegisterC);
  const updateCashRegisterDecoder = http.decode.response(UnknownApiError, CheckoutJson.CheckoutSavedCashRegisterC);
  const deleteCashRegister = http.decode.response(UnknownApiError, t.unknown);
  const listCashRegistersDecoder = http.decode.response(
    UnknownApiError,
    t.array(CheckoutJson.CheckoutSavedCashRegisterC),
  );
  const printReceiptDecoder = http.decode.response(UnknownApiError, CheckoutJson.CheckoutCashRegisterPrintTicketC);
  const printZReportDecoder = http.decode.response(UnknownApiError, CheckoutJson.CheckoutCashRegisterPrintTicketC);
  const getCashRegistryForCompanyDecoder = http.decode.response(
    UnknownApiError,
    CheckoutJson.checkoutCashRegistryC(MeroUnits.RON.code),
  );
  const initializeCashRegistryDecoder = http.decode.response(
    UnknownApiError,
    CheckoutJson.checkoutCashRegistryC(MeroUnits.RON.code),
  );
  const updateCashRegistryDecoder = http.decode.response(
    UnknownApiError,
    CheckoutJson.checkoutCashRegistryC(MeroUnits.RON.code),
  );
  const registerCashRegistryTransactionDecoder = http.decode.response(
    UnknownApiError,
    CheckoutJson.checkoutCashRegistryTransactionC(MeroUnits.RON.code),
  );
  const updateCashRegistryTransactionDecoder = http.decode.response(
    UnknownApiError,
    CheckoutJson.checkoutCashRegistryTransactionC(MeroUnits.RON.code),
  );
  const deleteCashRegistryTransactionDecoder = http.decode.response(UnknownApiError, t.unknown);
  const listCashRegistryTransactionsDecoder = http.decode.response(
    UnknownApiError,
    CheckoutJson.checkoutCashRegistryTransactionListC(MeroUnits.RON.code),
  );
  const downloadCashRegistryExportDecoder = http.decode.response(UnknownApiError, DownloadCashRegistryExport);

  return {
    createDraft: async <Unit extends MeroUnits.Any>(
      transaction: CreateCheckoutDraftRequest<Unit>,
    ): Promise<CheckoutTransactionDetails.Draft<Unit>> => {
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(transaction.page._id)}/checkout/drafts`,
            data: CheckoutJson.createCheckoutDraftRequestC(transaction.unit).encode(transaction),
          },
          http.decode.response(UnknownApiError, CheckoutJson.checkoutTransactionDetailsDraftC(transaction.unit)),
        ),
      );
    },
    updateDraft: async <Unit extends MeroUnits.Any>(
      transaction: UpdateCheckoutDraftRequest<Unit>,
    ): Promise<CheckoutTransactionDetails.Draft<Unit>> => {
      return unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              transaction.page._id,
            )}/checkout/drafts/${encodeURIComponent(transaction._id)}`,
            data: CheckoutJson.updateCheckoutDraftRequestC(transaction.unit).encode(transaction),
          },
          http.decode.response(UnknownApiError, CheckoutJson.checkoutTransactionDetailsDraftC(transaction.unit)),
        ),
      );
    },
    checkoutDraft: async <Unit extends MeroUnits.Any>(
      transaction: CheckoutDraftRequest<Unit>,
    ): Promise<CheckoutTransactionDetails.Finished<Unit>> => {
      return unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              transaction.page._id,
            )}/checkout/drafts/${encodeURIComponent(transaction._id)}/finished`,
            data: CheckoutJson.checkoutDraftRequestC(transaction.unit).encode(transaction),
          },
          http.decode.response(UnknownApiError, CheckoutJson.checkoutTransactionDetailsFinishedC(transaction.unit)),
        ),
      );
    },
    checkout: async <Unit extends MeroUnits.Any>(
      transaction: CheckoutRequest<Unit>,
    ): Promise<CheckoutTransactionDetails.Finished<Unit>> => {
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(transaction.page._id)}/checkout/finished`,
            data: CheckoutJson.checkoutRequestC(transaction.unit).encode(transaction),
          },
          http.decode.response(UnknownApiError, CheckoutJson.checkoutTransactionDetailsFinishedC(transaction.unit)),
        ),
      );
    },
    listPageDraftTransactions: async (params): Promise<Paged<CheckoutTransactionPreview.AnyDraft[]>> => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(params.pageId)}/checkout/drafts`,
            params: {
              ...(params.page
                ? params.page.token
                  ? {
                      page: params.page.token,
                    }
                  : { limit: params.page.limit }
                : {}),
            },
          },
          listPageDraftTransactionsDecoder,
        ),
      );
    },
    getPageDraftTransactionsCount: async (params): Promise<Zero | PositiveInt> => {
      const { count } = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(params.pageId)}/checkout/drafts-count`,
          },
          getPageDraftTransactionsCountDecoder,
        ),
      );

      return count;
    },
    listPageFinishedTransactions: async (params): Promise<Paged<CheckoutTransactionPreview.AnyFinished[]>> => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(params.pageId)}/checkout/finished`,
            params: {
              'finishedAt.from': tt.DateFromISOString.encode(params.finishedAt.from),
              'finishedAt.to': tt.DateFromISOString.encode(params.finishedAt.to),
              ...(params.search ? { search: params.search } : {}),
              ...(params.page
                ? params.page.token
                  ? {
                      page: params.page.token,
                    }
                  : { limit: params.page.limit }
                : {}),
            },
          },
          listPageFinishedTransactionsDecoder,
        ),
      );
    },
    listPageSubmitedTransactions: async (
      params,
    ): Promise<Paged<(CheckoutTransactionPreview.AnyFinished | CheckoutTransactionPreview.AnyDeleted)[]>> => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(params.pageId)}/checkout/submited`,
            params: {
              'finishedAt.from': tt.DateFromISOString.encode(params.finishedAt.from),
              'finishedAt.to': tt.DateFromISOString.encode(params.finishedAt.to),
              ...(params.search ? { search: params.search } : {}),
              ...(params.page
                ? params.page.token
                  ? {
                      page: params.page.token,
                    }
                  : { limit: params.page.limit }
                : {}),
            },
          },
          listPageSubmitedTransactionsDecoder,
        ),
      );
    },
    getPageFinishedTransactionsStats: async (params) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(params.pageId)}/checkout/finished-stats`,
            params: {
              unit: params.unit,
              'finishedAt.from': tt.DateFromISOString.encode(params.finishedAt.from),
              'finishedAt.to': tt.DateFromISOString.encode(params.finishedAt.to),
            },
          },
          http.decode.response(UnknownApiError, CheckoutJson.checkoutFinishedTransactionsStatsC(params.unit)),
        ),
      );
    },
    listAppointmentTransactions: async (params): Promise<Paged<CheckoutTransactionPreview.Any[]>> => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/appointments/${encodeURIComponent(params.appointmentId)}/transactions`,
            params: {
              ...(params.occurrenceIndex ? { occurrenceIndex: params.occurrenceIndex } : {}),
            },
          },
          listAppointmentTransactionsDecoder,
        ),
      );
    },
    getAppointmentTransactionById: async (params): Promise<CheckoutTransactionDetails.Any> => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/appointments/${encodeURIComponent(params.appointmentId)}/transactions/${encodeURIComponent(
              params.transactionId,
            )}`,
            params: {
              ...(params.occurrenceIndex ? { occurrenceIndex: params.occurrenceIndex } : {}),
            },
          },
          getAppointmentTransactionByIdDecoder,
        ),
      );
    },
    getTransaction: async (params): Promise<CheckoutTransactionDetails.Any> => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/transactions/${encodeURIComponent(params.transactionId)}`,
          },
          getTransactionDecoder,
        ),
      );
    },
    deleteTransaction: async (params): Promise<void> => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/transactions/${encodeURIComponent(params.transactionId)}`,
          },
          deleteTransactionDecoder,
        ),
      );
    },
    deleteTransactions: async (params): Promise<void> => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(params.pageId)}/checkout/transactions`,
            params: {
              ids: params.transactionIds.map(CheckoutJson.CheckoutTransactionIdC.encode),
            },
          },
          deleteTransactionDecoder,
        ),
      );
    },
    listPageCompanies: async (params): Promise<SavedCheckoutCompanyWithRegisters[]> => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(params.pageId)}/checkout/companies`,
          },
          listSavedCompaniesDecoder,
        ),
      );
    },
    getCompanyDetails: async (params): Promise<SavedCheckoutCompany> => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/companies/${encodeURIComponent(params.companyId)}`,
          },
          getCompanyDetailsDecoder,
        ),
      );
    },
    getPageSettings: async (params): Promise<CheckoutPageSettings> => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(params.pageId)}/checkout/settings`,
          },
          getPageSettingsDecoder,
        ),
      );
    },
    savePageSettings: async (params): Promise<void> => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(params.pageId)}/checkout/settings`,
            data: CheckoutJson.CheckoutPageSettingsC.encode(params.settings),
          },
          savePageSettingsDecoder,
        ),
      );
    },
    getPageDefaults: async (params): Promise<CheckoutPageDefaults> => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(params.pageId)}/checkout/defaults`,
          },
          getPageDefaultsDecoder,
        ),
      );
    },
    registerCompany: async (params): Promise<SavedCheckoutCompany> => {
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(params.pageId)}/checkout/companies`,
            data: CheckoutJson.CheckoutCompanyDetailsC.encode(params.company),
          },
          registerCompanyDecoder,
        ),
      );
    },
    registerCompanyBillingDetails: async (params): Promise<SavedCheckoutCompany> => {
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(params.pageId)}/checkout/companies/billing-details`,
            data: {
              billingDetailsId: params.billingDetailsId,
              emitReceiptStatus: params.emitReceiptStatus,
            },
          },
          registerCompanyBillingDetailsDecoder,
        ),
      );
    },
    updateCompany: async (params): Promise<SavedCheckoutCompany> => {
      return unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/companies/${encodeURIComponent(params.companyId)}`,
            data: CheckoutJson.CheckoutCompanyDetailsC.encode(params.company),
          },
          updateCompanyDecoder,
        ),
      );
    },
    updateCompanyEmitReceiptStatus: async ({ pageId, companyId, emitReceiptStatus }): Promise<void> => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/checkout/companies/${encodeURIComponent(
              companyId,
            )}/emit-receipt-status`,
            data: {
              emitReceiptStatus: emitReceiptStatus,
            },
          },
          updateCompanyEmitReceiptStatusDecoder,
        ),
      );
    },
    updateCompanyBillingDetails: async (params): Promise<SavedCheckoutCompany> => {
      return unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/companies/${encodeURIComponent(params.companyId)}/billing-details`,
            data: {
              billingDetailsId: params.billingDetailsId,
              emitReceiptStatus: params.emitReceiptStatus,
            },
          },
          updateCompanyBillingDetailsDecoder,
        ),
      );
    },
    deleteCompany: async (params): Promise<void> => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/companies/${encodeURIComponent(params.companyId)}`,
          },
          deleteCompanyDecoder,
        ),
      );
    },
    getCompanyAutocomplete: async ({ pageId, cif }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              pageId,
            )}/checkout/company-autocomplete/${encodeURIComponent(cif)}`,
          },
          getCompanyAutocompleteDecoder,
        ),
      );
    },
    getUnpaidAppointments: async ({ pageId, calendarId, clientId }) => {
      const params: {
        calendarId: string | undefined;
        clientId: string | undefined;
      } = { calendarId, clientId };

      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/checkout/unpaid-appointments`,
            params: params,
          },
          getUnpaidAppointmentsDecoder,
        ),
      );
    },
    listCashRegisterDrivers: async (params) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(params.pageId)}/checkout/cash-register-drivers`,
            params: params,
          },
          listCashRegisterDriversDecoder,
        ),
      );
    },
    registerCashRegister: async (params): Promise<CheckoutSavedCashRegister> => {
      const request: {
        name: string | undefined;
        driverId: CheckoutCashRegisterDriverId;
        driverConfig: Option<CheckoutCashRegisterDriverConfig>;
      } = {
        name: params.name,
        driverId: params.driverId,
        driverConfig: params.driverConfig,
      };
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/companies/${encodeURIComponent(params.companyId)}/cash-registers`,
            data: request,
          },
          registerCashRegisterDecoder,
        ),
      );
    },
    getCashRegister: async (params): Promise<CheckoutSavedCashRegister> => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/companies/${encodeURIComponent(params.companyId)}/cash-registers/${encodeURIComponent(
              params.cashRegisterId,
            )}`,
          },
          getCashRegisterDecoder,
        ),
      );
    },
    updateCashRegister: async (params): Promise<CheckoutSavedCashRegister> => {
      const request: {
        name: string | undefined;
        driverId: CheckoutCashRegisterDriverId;
        driverConfig: Option<CheckoutCashRegisterDriverConfig>;
      } = {
        name: params.name,
        driverId: params.driverId,
        driverConfig: params.driverConfig,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/companies/${encodeURIComponent(params.companyId)}/cash-registers/${encodeURIComponent(
              params.cashRegisterId,
            )}`,
            data: request,
          },
          updateCashRegisterDecoder,
        ),
      );
    },
    deleteCashRegister: async (params): Promise<void> => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/companies/${encodeURIComponent(params.companyId)}/cash-registers/${encodeURIComponent(
              params.cashRegisterId,
            )}`,
          },
          deleteCashRegister,
        ),
      );
    },
    listCashRegisters: async (params): Promise<CheckoutSavedCashRegister[]> => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/companies/${encodeURIComponent(params.companyId)}/cash-registers`,
          },
          listCashRegistersDecoder,
        ),
      );
    },
    printReceipt: async (params): Promise<CheckoutCashRegisterPrintTicket> => {
      const request: {
        force: boolean;
      } = {
        force: params.force,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/transactions/${encodeURIComponent(params.transactionId)}/receipts`,
            data: request,
          },
          printReceiptDecoder,
        ),
      );
    },
    printZReport: async (params): Promise<CheckoutCashRegisterPrintTicket> => {
      const request: {
        force: boolean;
      } = {
        force: params.force,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/companies/${encodeURIComponent(params.companyId)}/cash-registers/${encodeURIComponent(
              params.cashRegisterId,
            )}/print-z`,
            data: request,
          },
          printZReportDecoder,
        ),
      );
    },
    getCashRegistryForCompany: async (params): Promise<CheckoutCashRegistry<MeroUnits.Any>> => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/companies/${encodeURIComponent(params.companyId)}/cash-registry`,
          },
          getCashRegistryForCompanyDecoder,
        ),
      );
    },
    initializeCashRegistry: async (params): Promise<CheckoutCashRegistry<MeroUnits.RON>> => {
      const request: {
        timezone: string;
        initialBalanceAmount: Money<ScaledNumber, MeroUnits.Any>;
        initialBalanceDate: Date;
      } = {
        timezone: params.timezone,
        initialBalanceAmount: params.initialBalanceAmount,
        initialBalanceDate: params.initialBalanceDate,
      };
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/companies/${encodeURIComponent(params.companyId)}/cash-registry`,
            data: request,
          },
          initializeCashRegistryDecoder,
        ),
      );
    },
    updateCashRegistry: async (params): Promise<CheckoutCashRegistry<MeroUnits.RON>> => {
      const request: {
        initialBalanceAmount: Money<ScaledNumber, MeroUnits.Any>;
        initialBalanceDate: Date;
      } = {
        initialBalanceAmount: params.initialBalanceAmount,
        initialBalanceDate: params.initialBalanceDate,
      };
      return unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/companies/${encodeURIComponent(params.companyId)}/cash-registry/${encodeURIComponent(
              params.cashRegistryId,
            )}`,
            data: request,
          },
          updateCashRegistryDecoder,
        ),
      );
    },
    getCashRegistry: async (params): Promise<CheckoutCashRegistry<MeroUnits.RON>> => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/companies/${encodeURIComponent(params.companyId)}/cash-registry`,
          },
          updateCashRegistryDecoder,
        ),
      );
    },
    addCashRegistryTransaction: async (params): Promise<CheckoutCashRegistryTransaction<MeroUnits.RON>> => {
      const request: {
        type: CheckoutCashRegistryTransactionType;
        details: string;
        docNo: string;
        amount: Money<ScaledNumber, MeroUnits.Any>;
        transactionDate: string;
      } = {
        type: params.type,
        details: params.details,
        docNo: params.docNo,
        amount: params.amount,
        transactionDate: params.transactionDate.toISOString(),
      };
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/companies/${encodeURIComponent(params.companyId)}/cash-registry/${encodeURIComponent(
              params.cashRegistryId,
            )}/transactions`,
            data: request,
          },
          registerCashRegistryTransactionDecoder,
        ),
      );
    },
    updateCashRegistryTransaction: async (params): Promise<CheckoutCashRegistryTransaction<MeroUnits.RON>> => {
      const request: {
        details: string;
        docNo: string;
        amount: Money<ScaledNumber, MeroUnits.Any>;
        transactionDate: Date;
      } = {
        details: params.details,
        docNo: params.docNo,
        amount: params.amount,
        transactionDate: params.transactionDate,
      };
      return unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/companies/${encodeURIComponent(params.companyId)}/cash-registry/${encodeURIComponent(
              params.cashRegistryId,
            )}/transactions/${encodeURIComponent(params.cashRegistryTransactionId)}`,
            data: request,
          },
          updateCashRegistryTransactionDecoder,
        ),
      );
    },
    deleteCashRegistryTransaction: async (params): Promise<void> => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/companies/${encodeURIComponent(params.companyId)}/cash-registry/${encodeURIComponent(
              params.cashRegistryId,
            )}/transactions/${encodeURIComponent(params.cashRegistryTransactionId)}`,
          },
          deleteCashRegistryTransactionDecoder,
        ),
      );
    },
    listCashRegistryTransactions: async (params): Promise<CheckoutCashRegistryTransactionList<MeroUnits.RON>> => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/companies/${encodeURIComponent(params.companyId)}/cash-registry/${encodeURIComponent(
              params.cashRegistryId,
            )}/transactions`,
            params: {
              fromDate: params.fromDate,
              toDate: params.toDate,
            },
          },
          listCashRegistryTransactionsDecoder,
        ),
      );
    },
    exportCashRegistryTransactions: async (params): Promise<DownloadCashRegistryExport> => {
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/checkout/companies/${encodeURIComponent(params.companyId)}/cash-registry/${encodeURIComponent(
              params.cashRegistryId,
            )}/transactions/export`,
            params: {
              fromDate: params.fromDate,
              toDate: params.toDate,
              format: params.format,
            },
          },
          downloadCashRegistryExportDecoder,
        ),
      );
    },
  };
};
