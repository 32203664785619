import { every, fromArray, toArray } from 'fp-ts/es6/Set';
import * as t from 'io-ts';
import { pipe } from 'fp-ts/es6/pipeable';
import { chain } from 'fp-ts/es6/Either';
/**
 * @since 0.5.0
 */
export function setFromArray(codec, O, name) {
    if (name === void 0) { name = "Set<" + codec.name + ">"; }
    var arr = t.array(codec);
    var toArrayO = toArray(O);
    var fromArrayO = fromArray(O);
    return new t.Type(name, function (u) { return u instanceof Set && every(codec.is)(u); }, function (u, c) {
        return pipe(arr.validate(u, c), chain(function (as) {
            var set = fromArrayO(as);
            return set.size !== as.length ? t.failure(u, c) : t.success(set);
        }));
    }, function (set) { return arr.encode(toArrayO(set)); });
}
