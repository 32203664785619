import { adsHttpClient } from './ads';
import { analyticsHttpClient } from './analytics';
import { boostHttpClient } from './boost';
import { businessHttpClient } from './business';
import { calendarHttpClient } from './calendar';
import { checkoutHttpClient } from './checkout';
import { checkoutReportsHttpClient } from './checkoutReports';
import { clientsHttpClient } from './clients';
import { discountsHttpClient } from './discounts';
import { favoritesHttpClient } from './favorites';
import { featureFlagsHttpClient } from './featureFlags';
import { giftCardsHttpClient } from './giftCards';
import { integrationsHttpClient } from './integrations';
import { invitesHttpClient } from './invites';
import { marketplaceBootHttpClient } from './marketplace';
import { marketplaceLinkingHttpClient } from './marketplace/linking/marketplaceLinkingHttpClient';
import { membershipsHttpClient } from './memberships/membershipsHttpClient';
import { notificationsHttpClient } from './notifications';
import { NovabookerApi } from './novabooker-api';
import { pagesHttpClient } from './pages';
import { paymentsHttpClient } from './payments';
import { meroProHttpClient } from './pro';
import { shopHttpClient } from './shop';
import { usersHttpClient, buildHttpAuthStorage } from './users';
import { GrantStorage } from './users/auth';
import { emptyAuth, httpClient, Logger, MeroAppPlatform, MeroAppType } from '@mero/shared-sdk';
import axios from 'axios';

export const novabookerHttpClient = (env: {
  readonly apiBaseUrl: string;
  readonly socketBaseUrl: string;
  readonly auth: GrantStorage;
  readonly oauthClient: {
    readonly id: string;
    readonly secret?: string;
  };
  readonly log: Logger;
  readonly app: {
    readonly version: string;
    readonly platform: MeroAppPlatform;
    readonly type: MeroAppType;
    readonly onForceUpdate?: () => void;
  };
}): NovabookerApi => {
  const { apiBaseUrl, socketBaseUrl, auth, oauthClient, log } = env;

  const axiosInstance = axios.create({
    baseURL: apiBaseUrl,
    timeout: 30000,
  });

  const http = httpClient({
    auth: buildHttpAuthStorage({
      auth,
      apiBaseUrl,
      http: httpClient({
        auth: emptyAuth,
        http: axiosInstance,
        log: log,
      }),
      oauthClient,
      log: log,
    }),
    http: axiosInstance,
    log: log,
    app: env.app,
  });

  const users = usersHttpClient({ apiBaseUrl, http, auth, oauthClient });
  const pages = pagesHttpClient({ apiBaseUrl, http });
  const favorites = favoritesHttpClient({ apiBaseUrl, http });
  const clients = clientsHttpClient({ apiBaseUrl, http, users });
  const calendar = calendarHttpClient({ apiBaseUrl, socketBaseUrl, http });
  const notifications = notificationsHttpClient({ apiBaseUrl, http });
  const payments = paymentsHttpClient({ apiBaseUrl, http });
  const checkout = checkoutHttpClient({ apiBaseUrl, http });
  const checkoutReports = checkoutReportsHttpClient({ apiBaseUrl, http });
  const invites = invitesHttpClient({ apiBaseUrl, http });
  const giftCards = giftCardsHttpClient({ apiBaseUrl, http });
  const discounts = discountsHttpClient({ apiBaseUrl, http });
  const integrations = integrationsHttpClient({ apiBaseUrl, http });
  const analytics = analyticsHttpClient({ apiBaseUrl, http });
  const ads = adsHttpClient({ apiBaseUrl, http });
  const business = businessHttpClient({ apiBaseUrl, http });
  const shop = shopHttpClient({ apiBaseUrl, http });
  const boost = boostHttpClient({ apiBaseUrl, http });
  const featureFlags = featureFlagsHttpClient({ apiBaseUrl, http });
  const marketplaceLinking = marketplaceLinkingHttpClient({ apiBaseUrl, http });
  const memberships = membershipsHttpClient({ apiBaseUrl, http });
  const marketplaceBoot = marketplaceBootHttpClient({ apiBaseUrl, http });
  const pro = meroProHttpClient({ apiBaseUrl, http });

  return {
    users,
    pages,
    favorites,
    clients,
    calendar,
    notifications,
    payments,
    checkout,
    checkoutReports,
    invites,
    giftCards,
    discounts,
    integrations,
    analytics,
    ads,
    business,
    shop,
    boost,
    featureFlags,
    marketplace: {
      linking: marketplaceLinking,
      boot: marketplaceBoot,
    },
    pro,
    memberships,
  };
};
