import { AdsApi } from './ads-api';
import { BookingCompletedBanner } from './booking-completed-banner';
import { HttpClient, unsafeRight, UnknownApiError } from '@mero/shared-sdk';

/**
 * AdsApi HTTP client implementation
 */
export const adsHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): AdsApi => {
  const { apiBaseUrl, http } = env;

  const getBookingCompletedBannerDecoder = http.decode.response(UnknownApiError, BookingCompletedBanner);

  return {
    getBookingCompletedBanner: async () => {
      const meta = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/ads/booking-completed-banner`,
          },
          getBookingCompletedBannerDecoder,
        ),
      );

      return meta;
    },
  };
};
