import { removeConsequentialDuplicateCharacters } from '@mero/shared-sdk';

export const BannedWords: string[] = [
  'afrodisiac',
  'antibiotic',
  'arma',
  'aspect fizic',
  'avans',
  'buletin',
  'caca',
  'caca-m-as',
  'cacat',
  'card',
  'carte de identitate',
  'casatorie',
  'coi',
  'cont',
  'credit',
  'cur',
  'curva',
  'dating',
  'erotic',
  'escorta',
  'etnobotanice',
  'fals',
  'filelist',
  'flirt',
  'furat',
  'futu-i',
  'gaoaza',
  'gaozar',
  'imprumut',
  'imprumuturi',
  'matrimoniale',
  'medicament',
  'medicamente',
  'muie',
  'muist',
  'muista',
  'muncesti de acasa',
  'organ',
  'organe',
  'penis',
  'petarde',
  'pistol',
  'pizda',
  'pocker',
  'poponar',
  'porno',
  'pornografic',
  'pornografie',
  'prezervativ',
  'prostituata',
  'prostitutie',
  'pula',
  'pula bleaga',
  'pusca',
  'relatii',
  'rinichi',
  'sani',
  'sot',
  'sotie',
  'tutun',
  'urgent',
  'venin',
  'videochat',
  'vipera',
  'vipere',
  //remove non alphabetic characters from banned words
].map((word) => removeConsequentialDuplicateCharacters(word.replace(/[^A-Za-z]/g, ''), false));
