import { StyleSheet } from 'react-native';

import * as colors from './colors';

export const styles = StyleSheet.create({
  d1: {
    fontFamily: 'merriweather-bold',
    fontSize: 40,
    color: colors.BLACK,
  },
  d2: {
    fontFamily: 'merriweather-bold',
    fontSize: 27,
    color: colors.BLACK,
  },
  h1: {
    fontFamily: 'merriweather-bold',
    fontSize: 24,
    color: colors.BLACK,
  },
  h2: {
    fontFamily: 'merriweather-bold',
    fontSize: 18,
    color: colors.BLACK,
  },
  h2s: {
    fontFamily: 'open-sans-bold',
    fontSize: 20,
    color: colors.BLACK,
  },
  h3s: {
    fontFamily: 'open-sans-bold',
    fontSize: 17,
    color: colors.BLACK,
  },
  title: {
    fontFamily: 'open-sans-semibold',
    fontSize: 16,
    lineHeight: 22,
    color: colors.BLACK,
  },
  body: {
    fontFamily: 'open-sans',
    fontSize: 16,
    lineHeight: 22,
    color: colors.BLACK,
  },
  smallBody: {
    fontFamily: 'open-sans',
    fontSize: 14,
    lineHeight: 19,
    color: colors.BLACK,
  },
  label: {
    fontFamily: 'open-sans',
    fontSize: 12,
    lineHeight: 17,
    textTransform: 'uppercase',
    color: colors.BLACK,
  },
  link: {
    color: colors.DARK_BLUE,
  },
  hint: {
    color: colors.COMET,
  },
  semibold: {
    fontFamily: 'open-sans-semibold',
  },
  bold: {
    fontFamily: 'open-sans-bold',
  },
  alignLeft: {
    textAlign: 'left',
  },
  alignCenter: {
    textAlign: 'center',
  },
  alignRight: {
    textAlign: 'right',
  },
});
