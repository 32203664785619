import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface DiscountIdBrand {
  readonly DiscountId: unique symbol;
}

export const DiscountId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, DiscountIdBrand> => true,
  'DiscountId',
);

export type DiscountId = t.TypeOf<typeof DiscountId>;
