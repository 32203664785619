import * as t from 'io-ts';
var leftLiteral = t.literal('Left');
var rightLiteral = t.literal('Right');
/**
 * Given a codec representing a type `L` and a codec representing a type `A`, returns a codec representing `Either<L, A>` that is able to deserialize
 * the JSON representation of an `Either`.
 *
 * @example
 * import { either } from 'io-ts-types/es6/either'
 * import { left, right } from 'fp-ts/es6/Either'
 * import * as t from 'io-ts'
 * import { PathReporter } from 'io-ts/es6/PathReporter'
 *
 * const T = either(t.string, t.number)
 *
 * assert.deepStrictEqual(T.decode(right(1)), right(right(1)))
 * assert.deepStrictEqual(T.decode(left('a')), right(left('a')))
 * assert.deepStrictEqual(PathReporter.report(T.decode(right('a'))), ['Invalid value "a" supplied to : Either<string, number>/1: Right<string>/right: number'])
 *
 * @since 0.5.0
 */
export function either(leftCodec, rightCodec, name) {
    if (name === void 0) { name = "Either<" + leftCodec.name + ", " + rightCodec.name + ">"; }
    return t.union([
        t.strict({
            _tag: leftLiteral,
            left: leftCodec
        }, "Left<" + leftCodec.name + ">"),
        t.strict({
            _tag: rightLiteral,
            right: rightCodec
        }, "Right<" + leftCodec.name + ">")
    ], name);
}
