import { UserId, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const AppointmentReminderUnit = t.union(
  [t.literal('h'), t.literal('d'), t.literal('w'), t.literal('m')],
  'AppointmentReminderUnit',
);

export type AppointmentReminderUnit = t.TypeOf<typeof AppointmentReminderUnit>;

export const PageSmsNotificationSettings = t.union(
  [
    /**
     * SMS notifications are disabled
     */
    t.type(
      {
        type: t.literal('Off'),
      },
      'Off',
    ),
    /**
     * SMS sent via Mero SMS cloud provider
     */
    t.type(
      {
        type: t.literal('MeroCloudSms'),
      },
      'MeroCloudSms',
    ),
    /**
     * SMS sent via Mero SMS app
     */
    t.type(
      {
        type: t.literal('MeroSmsApp'),
      },
      'MeroSmsApp',
    ),
    /**
     * SMS sent manually
     */
    t.type(
      {
        type: t.literal('ManualSms'),
        sender: t.union(
          [
            t.type(
              {
                type: t.literal('individual'),
              },
              'IndividualManualSmsSender',
            ),
            t.type(
              {
                type: t.literal('global'),
                userId: UserId,
              },
              'GlobalManualSmsSender',
            ),
          ],
          'ManualSmsSender',
        ),
      },
      'ManualSms',
    ),
  ],
  'PageSmsNotificationSettings',
);

export type PageSmsNotificationSettings = t.TypeOf<typeof PageSmsNotificationSettings>;

export const PageNotificationSettings = t.type(
  {
    clientAppointmentReminders: t.array(
      t.type({
        unit: AppointmentReminderUnit,
        value: t.number,
      }),
    ),
    meroSmsSendForInvitedWorkers: t.boolean,
    appointmentReviewReminder: t.boolean,
    /**
     * if set to false, only AppointmentMadeByPage push notification will be sent
     */
    appointmentMadeByPage: optionull(t.boolean),
    /**
     * if set to false, only AppointmentModified push notification will be sent
     */
    appointmentModified: optionull(t.boolean),
    sms: PageSmsNotificationSettings,
    includeAppointmentLink: optionull(t.boolean),
  },
  'PageNotificationSettings',
);

export type PageNotificationSettings = t.TypeOf<typeof PageNotificationSettings>;
