import { Favorite } from './favorite';
import { FavoritesApi } from './favorites-api';
import { HttpClient, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const favoritesHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): FavoritesApi => {
  const { apiBaseUrl, http } = env;

  const addUserFavoriteDecoder = http.decode.response(UnknownApiError, t.unknown);
  const deleteUserFavoriteDecoder = http.decode.response(UnknownApiError, t.unknown);
  const listUserFavoritesDecoder = http.decode.response(UnknownApiError, t.array(Favorite));
  const isFavoriteDecoder = http.decode.response(UnknownApiError, t.type({ isFavorite: t.boolean }));

  return {
    addUserFavorite: async (pageId) => {
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/user/favorites/${encodeURIComponent(pageId)}`,
          },
          addUserFavoriteDecoder,
        ),
      );
    },
    removeUserFavorite: async (pageId) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/user/favorites/${encodeURIComponent(pageId)}`,
          },
          deleteUserFavoriteDecoder,
        ),
      );
    },
    listUserFavorites: async (query) => {
      const favorites = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/user/favorites`,
            params: query,
          },
          listUserFavoritesDecoder,
        ),
      );

      return favorites;
    },
    isFavorite: async (pageId) => {
      const response = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/user/favorites/${encodeURIComponent(pageId)}/is-favorite`,
          },
          isFavoriteDecoder,
        ),
      );

      return response.isFavorite;
    },
  };
};
