import { PageId } from '../pages';
import { UserId } from '../users';
import { DiscountId } from './discount-id';
import { DiscountType } from './discount-type';
import * as t from 'io-ts';

export const Discount = t.intersection(
  [
    t.type({
      _id: DiscountId,
      pageId: PageId,
      code: t.string,
      type: DiscountType,
      percentage: t.number,
      fixedAmount: t.number,
      usedByUserIds: t.array(UserId), // userIds that have used this code
    }),
    t.partial({
      minAppointmentAmount: t.number, // minimum amount for an appointment for which this discount code can be applied
      maxUsages: t.number, // how many times this code can be used
    }),
  ],
  'Discount',
);

export type Discount = t.TypeOf<typeof Discount>;
