/**
 * @since 0.5.0
 */
import * as t from 'io-ts';
import { fromArray, map } from 'fp-ts/es6/NonEmptyArray';
import { isNonEmpty } from 'fp-ts/es6/Array';
import { pipe } from 'fp-ts/es6/pipeable';
import { chain } from 'fp-ts/es6/Either';
import { isNone } from 'fp-ts/es6/Option';
/**
 * @since 0.5.0
 */
export function nonEmptyArray(codec, name) {
    if (name === void 0) { name = "NonEmptyArray<" + codec.name + ">"; }
    var arr = t.array(codec);
    return new t.Type(name, function (u) { return arr.is(u) && isNonEmpty(u); }, function (u, c) {
        return pipe(arr.validate(u, c), chain(function (as) {
            var onea = fromArray(as);
            return isNone(onea) ? t.failure(u, c) : t.success(onea.value);
        }));
    }, map(codec.encode));
}
