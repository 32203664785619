import { PositiveNumber } from './positiveNumber';
import * as Eq from 'fp-ts/Eq';
import * as E from 'fp-ts/lib/Either';
import { identity, pipe } from 'fp-ts/lib/function';
import * as Num from 'fp-ts/number';
import * as t from 'io-ts';

export type PositiveInt = PositiveNumber & t.Int;

export const JSON = t.intersection([t.Int, PositiveNumber.JSON], 'PositiveInt');

/**
 * Decodes input to a PositiveInt or fails with an error
 */
const unsafeFrom = (n: number): PositiveInt =>
  pipe(
    n,
    JSON.decode,
    E.fold(() => {
      throw new Error(`Failed to decode PositiveInt from ${n}`);
    }, identity),
  );

const add = (a: PositiveInt, b: PositiveInt): PositiveInt => {
  return unsafeFrom(a + b);
};

const mul = (a: PositiveInt, b: PositiveInt): PositiveInt => {
  return unsafeFrom(a * b);
};

const eq: Eq.Eq<PositiveInt> = Num.Eq;

export const PositiveInt = {
  unsafeFrom,
  JSON,
  equals: eq.equals,
  add,
  mul,
};
