import { One } from '../../numbers';
import { RecurrenceTimeUnit } from '../recurrenceTimeUnit';
import * as t from 'io-ts';

export type Yearly = {
  readonly value: One;
  readonly unit: RecurrenceTimeUnit.Year;
};

const JSON = t.type(
  {
    value: One,
    unit: RecurrenceTimeUnit.Year.JSON,
  },
  'Yearly',
);

export const Yearly = {
  JSON,
  is: JSON.is,
};
