import { PortionPercent, PortionPercentModule } from './portionPercent';
import { Positive, PositiveModule } from './positive';
import { ScaledNumber } from './scaledNumber';
import * as t from 'io-ts';

export type PortionPercentScaledNumber = PortionPercent<ScaledNumber>;

export type PortionPercentScaledNumberModule = PortionPercentModule<ScaledNumber> & {
  /**
   * Create a value from a number {@link n} rouding to given {@link decimals} or throw an error
   */
  readonly unsafeFromNumber: (n: number, decimals: number) => PortionPercentScaledNumber;

  /**
   * Create a value from a number {@link n} rouding to given {@link decimals} or throw an error
   */
  readonly unsafeFromPositiveNumber: (n: number, decimals: number) => Positive<PortionPercentScaledNumber>;

  /**
   * Check if given {@link PortionPercentScaledNumber} is positive (> 0)
   */
  readonly isPositive: (n: PortionPercentScaledNumber) => n is Positive<PortionPercentScaledNumber>;

  readonly JSON: t.Type<PortionPercent<ScaledNumber>, ScaledNumber>;
};

const PortionPercentN = PortionPercent.build(ScaledNumber);

const JSON: t.Type<PortionPercent<ScaledNumber>, ScaledNumber> = PortionPercentN.json(
  t.exact(
    t.intersection(
      [
        t.type(
          {
            value: t.number,
          },
          '!',
        ),
        t.partial(
          {
            scale: t.number,
          },
          '?',
        ),
      ],
      'PortionPercent<ScaledNumber>',
    ),
    'Exact<PortionPercent<ScaledNumber>>',
  ),
);

const unsafeFromNumber = (n: number, decimals: number): PortionPercentScaledNumber => {
  return PortionPercentN.unsafeFrom(ScaledNumber.fromNumber(n, decimals));
};

const PortionPercentScaledNumberPositive: PositiveModule<PortionPercentScaledNumber> =
  Positive.build<PortionPercentScaledNumber>({
    zero: PortionPercentN.zero,
    greaterThan: ScaledNumber.greaterThan,
  });

const isPositive = PortionPercentScaledNumberPositive.is;

const unsafeFromPositiveNumber = (n: number, decimals: number): Positive<PortionPercentScaledNumber> => {
  return PortionPercentScaledNumberPositive.unsafeFrom(unsafeFromNumber(n, decimals));
};

export const PortionPercentScaledNumber: PortionPercentScaledNumberModule = {
  ...PortionPercentN,
  unsafeFromNumber,
  unsafeFromPositiveNumber,
  isPositive,
  JSON: JSON,
};
