import { MembershipPurchaseId } from '../../memberships/membershipPurchaseId';
import { CheckoutItemTotal } from '../checkoutItemTotal';
import { CheckoutMembershipItemTotal } from '../checkoutMembershipItemTotal';
import { CheckoutMembershipItemTotalScaledNumber } from '../checkoutMembershipItemTotalScaledNumber';
import { CheckoutTotals } from '../checkoutTotals';
import { CheckoutTransactionMembershipTemplateDetails } from '../checkoutTransactionMembershipTemplateDetails';
import { CheckoutTransactionServiceDetails } from '../checkoutTransactionServiceDetails';
import { CheckoutUserPreview } from '../checkoutUserPreview';
import { CompanyVatStatus } from '../companies';
import { MeroUnits, One, PositiveInt, ScaledNumber } from '@mero/shared-sdk';

export type Membership<Unit extends MeroUnits.Any> = {
  readonly type: 'Membership';
  readonly transactionItemId: string | undefined;
  readonly membershipTemplate: CheckoutTransactionMembershipTemplateDetails<Unit>;
  readonly membershipPurchaseId: MembershipPurchaseId;
  /**
   * TODO: want to support quantity > 1? Make sure all usages works properly
   */
  readonly quantity: One;
  /**
   * This is total amount paid by client for this membership(s) in this transaction
   * SHOULD NOT be multiplied by quantity to get the total
   * Membership item does not support additional discount
   */
  readonly total: CheckoutMembershipItemTotal<ScaledNumber, Unit>;
  readonly saleOwner: CheckoutUserPreview;
};

export type MembershipItemService<Unit extends MeroUnits.Any> = {
  readonly type: 'Service';
  readonly service: CheckoutTransactionServiceDetails<Unit>;
  /**
   * Service sessions quantity >= 1
   */
  readonly quantity: MembershipItemServiceQuantity;
  /**
   * Total to pay for give quantity
   */
  readonly total: CheckoutItemTotal<ScaledNumber, Unit>;
};

export type MembershipItemServiceQuantity =
  | {
      /**
       * Unlimited quantity, usually limited to a time period, ex. gym membership
       */
      readonly type: 'Unlimited';
    }
  | {
      /**
       * Exact quantity value
       */
      readonly type: 'Limited';
      readonly value: PositiveInt;
    };

export type MembershipItemProduct = {
  readonly type: 'Product';
  // FIXME: implement product item support
};

export type MembershipItem<Unit extends MeroUnits.Any> = MembershipItemService<Unit> | MembershipItemProduct;

export const membership = <Unit extends MeroUnits.Any>(params: {
  transactionItemId?: string;
  membershipTemplate: CheckoutTransactionMembershipTemplateDetails<Unit>;
  membershipPurchaseId: MembershipPurchaseId;
  quantity: One;
  total: CheckoutMembershipItemTotal<ScaledNumber, Unit>;
  saleOwner: CheckoutUserPreview;
}): Membership<Unit> => {
  return {
    type: 'Membership',
    transactionItemId: params.transactionItemId,
    membershipTemplate: params.membershipTemplate,
    membershipPurchaseId: params.membershipPurchaseId,
    quantity: params.quantity,
    total: params.total,
    saleOwner: params.saleOwner,
  };
};

/**
 * Get membership total price to pay, with discount applied
 */
const getPrice = <Unit extends MeroUnits.Any>(
  item: Membership<Unit>,
  unit: Unit,
  companyVat: CompanyVatStatus.Any<ScaledNumber>,
  decimals: number,
): CheckoutTotals<ScaledNumber, Unit> => {
  return CheckoutTransactionMembershipTemplateDetails.getPrice(item.membershipTemplate, unit, companyVat, decimals);
};

/**
 * Get membership total payment for current transaction
 * This value may be less than membership purchase price, {@see getPrice}
 */
const getTotals = <Unit extends MeroUnits.Any>(
  item: Membership<Unit>,
  unit: Unit,
  companyVat: CompanyVatStatus.Any<ScaledNumber>,
  decimals: number,
): CheckoutTotals<ScaledNumber, Unit> => {
  unit;
  return CheckoutMembershipItemTotalScaledNumber.getTotals(item.total, companyVat, decimals);
};

export const Membership = {
  getPrice,
  getTotals,
};
