/**
 * @since 0.4.4
 */
import * as t from 'io-ts';
import { NumberFromString } from './NumberFromString';
import { pipe } from 'fp-ts/es6/pipeable';
import { chain } from 'fp-ts/es6/Either';
/**
 * A codec that succeeds if a string can be parsed to an integer
 *
 * @example
 * import { IntFromString } from 'io-ts-types/es6/IntFromString'
 * import { right } from 'fp-ts/es6/Either'
 * import { PathReporter } from 'io-ts/es6/PathReporter'
 *
 * assert.deepStrictEqual(IntFromString.decode('1'), right(1))
 * assert.deepStrictEqual(PathReporter.report(IntFromString.decode('1.1')), ['Invalid value "1.1" supplied to : IntFromString'])
 *
 * @since 0.4.4
 */
export var IntFromString = new t.Type('IntFromString', t.Int.is, function (u, c) {
    return pipe(NumberFromString.validate(u, c), chain(function (n) { return (t.Int.is(n) ? t.success(n) : t.failure(u, c)); }));
}, NumberFromString.encode);
