import * as t from 'io-ts';

export interface NotBrand {
  readonly Not: unique symbol;
}

export type Not<T> = t.Branded<T, NotBrand>;

export type NotModule<T> = {
  /**
   * Checks if value is a valid Not<T>
   */
  readonly is: (a: T) => a is Not<T>;
  /**
   * Build new JSON codec for Negative<Num>
   */
  readonly json: <O, I>(codec: t.Type<T, O, I>) => t.Type<Not<T>, O, I>;
};

const build = <T>(isT: (a: T) => a is T): NotModule<T> => {
  const is = (a: T): a is Not<T> => !isT(a);

  const json = <O, I>(codec: t.Type<T, O, I>): t.Type<Not<T>, O, I> => t.brand(codec, is, `Not`);

  return {
    is: is,
    json: json,
  };
};

export const Not = {
  build,
};
