var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as RA from 'fp-ts/es6/ReadonlyArray';
import * as RR from 'fp-ts/es6/ReadonlyRecord';
import { constant, flow, identity } from 'fp-ts/es6/function';
import * as O from 'fp-ts/es6/Option';
import * as E from 'fp-ts/es6/Either';
import { pipe } from 'fp-ts/es6/pipeable';
// -------------------------------------------------------------------------------------
// Iso
// -------------------------------------------------------------------------------------
/** @internal */
export var iso = function (get, reverseGet) { return ({
    get: get,
    reverseGet: reverseGet
}); };
/** @internal */
export var isoAsLens = function (sa) { return lens(sa.get, flow(sa.reverseGet, constant)); };
/** @internal */
export var isoAsPrism = function (sa) { return prism(flow(sa.get, O.some), sa.reverseGet); };
/** @internal */
export var isoAsOptional = function (sa) {
    return optional(flow(sa.get, O.some), flow(sa.reverseGet, constant));
};
/** @internal */
export var isoAsTraversal = function (sa) {
    return traversal(function (F) { return function (f) { return function (s) {
        return F.map(f(sa.get(s)), function (a) { return sa.reverseGet(a); });
    }; }; });
};
// -------------------------------------------------------------------------------------
// Lens
// -------------------------------------------------------------------------------------
/** @internal */
export var lens = function (get, set) { return ({ get: get, set: set }); };
/** @internal */
export var lensAsOptional = function (sa) { return optional(flow(sa.get, O.some), sa.set); };
/** @internal */
export var lensAsTraversal = function (sa) {
    return traversal(function (F) { return function (f) { return function (s) { return F.map(f(sa.get(s)), function (a) { return sa.set(a)(s); }); }; }; });
};
/** @internal */
export var lensComposeLens = function (ab) { return function (sa) {
    return lens(function (s) { return ab.get(sa.get(s)); }, function (b) { return function (s) { return sa.set(ab.set(b)(sa.get(s)))(s); }; });
}; };
/** @internal */
export var prismComposePrism = function (ab) { return function (sa) {
    return prism(flow(sa.getOption, O.chain(ab.getOption)), flow(ab.reverseGet, sa.reverseGet));
}; };
/** @internal */
export var lensComposePrism = function (ab) { return function (sa) {
    return optionalComposeOptional(prismAsOptional(ab))(lensAsOptional(sa));
}; };
/** @internal */
export var lensId = function () { return lens(identity, constant); };
/** @internal */
export var lensProp = function (prop) { return function (sa) {
    return lens(function (s) { return sa.get(s)[prop]; }, function (ap) { return function (s) {
        var _a;
        var oa = sa.get(s);
        if (ap === oa[prop]) {
            return s;
        }
        return sa.set(Object.assign({}, oa, (_a = {}, _a[prop] = ap, _a)))(s);
    }; });
}; };
/** @internal */
export var lensProps = function () {
    var props = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        props[_i] = arguments[_i];
    }
    return function (sa) {
        return lens(function (s) {
            var a = sa.get(s);
            var r = {};
            for (var _i = 0, props_1 = props; _i < props_1.length; _i++) {
                var k = props_1[_i];
                r[k] = a[k];
            }
            return r;
        }, function (a) { return function (s) {
            var oa = sa.get(s);
            for (var _i = 0, props_2 = props; _i < props_2.length; _i++) {
                var k = props_2[_i];
                if (a[k] !== oa[k]) {
                    return sa.set(Object.assign({}, oa, a))(s);
                }
            }
            return s;
        }; });
    };
};
/** @internal */
export var lensComponent = function (prop) { return function (sa) {
    return lens(function (s) { return sa.get(s)[prop]; }, function (ap) { return function (s) {
        var oa = sa.get(s);
        if (ap === oa[prop]) {
            return s;
        }
        var copy = oa.slice();
        copy[prop] = ap;
        return sa.set(copy)(s);
    }; });
}; };
/** @internal */
export var lensAtKey = function (key) { return function (sa) {
    return pipe(sa, lensComposeLens(atReadonlyRecord().at(key)));
}; };
// -------------------------------------------------------------------------------------
// Prism
// -------------------------------------------------------------------------------------
/** @internal */
export var prism = function (getOption, reverseGet) { return ({ getOption: getOption, reverseGet: reverseGet }); };
/** @internal */
export var prismAsOptional = function (sa) { return optional(sa.getOption, function (a) { return prismSet(a)(sa); }); };
/** @internal */
export var prismAsTraversal = function (sa) {
    return traversal(function (F) { return function (f) { return function (s) {
        return pipe(sa.getOption(s), O.fold(function () { return F.of(s); }, function (a) { return F.map(f(a), function (a) { return prismSet(a)(sa)(s); }); }));
    }; }; });
};
/** @internal */
export var prismModifyOption = function (f) { return function (sa) { return function (s) {
    return pipe(sa.getOption(s), O.map(function (o) {
        var n = f(o);
        return n === o ? s : sa.reverseGet(n);
    }));
}; }; };
/** @internal */
export var prismModify = function (f) { return function (sa) {
    var g = prismModifyOption(f)(sa);
    return function (s) {
        return pipe(g(s), O.getOrElse(function () { return s; }));
    };
}; };
/** @internal */
export var prismSet = function (a) { return prismModify(function () { return a; }); };
/** @internal */
export var prismComposeLens = function (ab) { return function (sa) {
    return optionalComposeOptional(lensAsOptional(ab))(prismAsOptional(sa));
}; };
/** @internal */
export var prismFromNullable = function () { return prism(O.fromNullable, identity); };
/** @internal */
export var prismFromPredicate = function (predicate) {
    return prism(O.fromPredicate(predicate), identity);
};
/** @internal */
export var prismSome = function () { return prism(identity, O.some); };
/** @internal */
export var prismRight = function () { return prism(O.fromEither, E.right); };
/** @internal */
export var prismLeft = function () {
    return prism(function (s) { return (E.isLeft(s) ? O.some(s.left) : O.none); }, // TODO: replace with E.getLeft in v3
    E.left);
};
// -------------------------------------------------------------------------------------
// Optional
// -------------------------------------------------------------------------------------
/** @internal */
export var optional = function (getOption, set) { return ({
    getOption: getOption,
    set: set
}); };
/** @internal */
export var optionalAsTraversal = function (sa) {
    return traversal(function (F) { return function (f) { return function (s) {
        return pipe(sa.getOption(s), O.fold(function () { return F.of(s); }, function (a) { return F.map(f(a), function (a) { return sa.set(a)(s); }); }));
    }; }; });
};
/** @internal */
export var optionalModifyOption = function (f) { return function (optional) { return function (s) {
    return pipe(optional.getOption(s), O.map(function (a) {
        var n = f(a);
        return n === a ? s : optional.set(n)(s);
    }));
}; }; };
/** @internal */
export var optionalModify = function (f) { return function (optional) {
    var g = optionalModifyOption(f)(optional);
    return function (s) {
        return pipe(g(s), O.getOrElse(function () { return s; }));
    };
}; };
/** @internal */
export var optionalComposeOptional = function (ab) { return function (sa) {
    return optional(flow(sa.getOption, O.chain(ab.getOption)), function (b) { return optionalModify(ab.set(b))(sa); });
}; };
/** @internal */
export var optionalIndex = function (i) { return function (sa) {
    return pipe(sa, optionalComposeOptional(indexReadonlyArray().index(i)));
}; };
/** @internal */
export var optionalIndexNonEmpty = function (i) { return function (sa) { return pipe(sa, optionalComposeOptional(indexReadonlyNonEmptyArray().index(i))); }; };
/** @internal */
export var optionalKey = function (key) { return function (sa) {
    return pipe(sa, optionalComposeOptional(indexReadonlyRecord().index(key)));
}; };
/** @internal */
export var optionalFindFirst = function (predicate) {
    return optional(RA.findFirst(predicate), function (a) { return function (s) {
        return pipe(RA.findIndex(predicate)(s), O.fold(function () { return s; }, function (i) { return RA.unsafeUpdateAt(i, a, s); }));
    }; });
};
var unsafeUpdateAt = function (i, a, as) {
    if (as[i] === a) {
        return as;
    }
    else {
        var xs = __spreadArray([as[0]], as.slice(1), true);
        xs[i] = a;
        return xs;
    }
};
/** @internal */
export var optionalFindFirstNonEmpty = function (predicate) {
    return optional(RA.findFirst(predicate), function (a) { return function (as) {
        return pipe(RA.findIndex(predicate)(as), O.fold(function () { return as; }, function (i) { return unsafeUpdateAt(i, a, as); }));
    }; });
};
// -------------------------------------------------------------------------------------
// Traversal
// -------------------------------------------------------------------------------------
/** @internal */
export var traversal = function (modifyF) { return ({
    modifyF: modifyF
}); };
/** @internal */
export function traversalComposeTraversal(ab) {
    return function (sa) { return traversal(function (F) { return function (f) { return sa.modifyF(F)(ab.modifyF(F)(f)); }; }); };
}
/** @internal */
export var ApplicativeIdentity = {
    URI: 'Identity',
    map: function (fa, f) { return f(fa); },
    of: identity,
    ap: 
    /* istanbul ignore next */
    function (fab, fa) { return fab(fa); }
};
var isIdentity = function (F) { return F.URI === 'Identity'; };
export function fromTraversable(T) {
    return function () {
        return traversal(function (F) {
            // if `F` is `Identity` then `traverseF = map`
            var traverseF = isIdentity(F)
                ? T.map
                : T.traverse(F);
            return function (f) { return function (s) { return traverseF(s, f); }; };
        });
    };
}
/** @internal */
export function traversalTraverse(T) {
    return traversalComposeTraversal(fromTraversable(T)());
}
// -------------------------------------------------------------------------------------
// Ix
// -------------------------------------------------------------------------------------
/** @internal */
export var index = function (index) { return ({ index: index }); };
/** @internal */
export var indexReadonlyArray = function () {
    return index(function (i) {
        return optional(function (as) { return RA.lookup(i, as); }, function (a) { return function (as) {
            return pipe(RA.lookup(i, as), O.fold(function () { return as; }, function () { return RA.unsafeUpdateAt(i, a, as); }));
        }; });
    });
};
/** @internal */
export var indexReadonlyNonEmptyArray = function () {
    return index(function (i) {
        return optional(function (as) { return RA.lookup(i, as); }, function (a) { return function (as) {
            return pipe(RA.lookup(i, as), O.fold(function () { return as; }, function () { return unsafeUpdateAt(i, a, as); }));
        }; });
    });
};
/** @internal */
export var indexReadonlyRecord = function () {
    return index(function (k) {
        return optional(function (r) { return RR.lookup(k, r); }, function (a) { return function (r) {
            if (r[k] === a || O.isNone(RR.lookup(k, r))) {
                return r;
            }
            return RR.insertAt(k, a)(r);
        }; });
    });
};
// -------------------------------------------------------------------------------------
// At
// -------------------------------------------------------------------------------------
/** @internal */
export var at = function (at) { return ({ at: at }); };
/** @internal */
export function atReadonlyRecord() {
    return at(function (key) {
        return lens(function (r) { return RR.lookup(key, r); }, O.fold(function () { return RR.deleteAt(key); }, function (a) { return RR.insertAt(key, a); }));
    });
}
