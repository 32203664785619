import { CreateCheckoutTransactionPayment } from './checkoutApi/createCheckoutTransactionPayment';
import { CheckoutTransactionItem } from './checkoutTransactionItem';
import { CheckoutTransactionPayment } from './checkoutTransactionPayment';
import { MeroUnits, ScaledNumber, isDefined } from '@mero/shared-sdk';

const assignIdsToItems = <Unit extends MeroUnits.Any>(
  items: CheckoutTransactionItem.Any<Unit>[],
): CheckoutTransactionItem.Any<Unit>[] => {
  let transactionItemId = 1;
  const mappedItems = [];
  for (const item of items) {
    if (item.type === 'Booking') {
      mappedItems.push({
        ...item,
        transactionItemId: (transactionItemId++).toString(),
        items: item.items.map((bookingItem) => {
          return {
            ...bookingItem,
            transactionItemId: (transactionItemId++).toString(),
          };
        }),
      });
    } else {
      mappedItems.push({
        ...item,
        transactionItemId: (transactionItemId++).toString(),
      });
    }
  }
  return mappedItems;
};

const assignIdsToMembershipPaymentsItems = <Unit extends MeroUnits.Any>(params: {
  items: CheckoutTransactionItem.Any<Unit>[];
  payments: CreateCheckoutTransactionPayment.Any<ScaledNumber, Unit>[];
}): CheckoutTransactionPayment.Any<ScaledNumber, Unit>[] => {
  const transactionItemIds = new Set();
  const transactionServices: CheckoutTransactionItem.Service<Unit>[] = params.items.flatMap((item) =>
    item.type === 'Service' ? item : item.type === 'Booking' ? item.items : [],
  );
  const payments = [];
  for (const payment of params.payments) {
    if (payment.type !== 'Membership') {
      payments.push(payment);
    } else {
      const membershipItems = [];
      for (const membershipItem of payment.items) {
        if (membershipItem.type === 'Service') {
          const found = transactionServices.find(
            (service) =>
              service.quantity === membershipItem.quantity &&
              service.service._id === membershipItem.service._id &&
              service.transactionItemId &&
              !transactionItemIds.has(service.transactionItemId),
          );
          const transactionItemId = found ? found.transactionItemId : undefined;
          if (!transactionItemId) {
            throw new Error(`Could not map membership ${payment.membership._id} service ${membershipItem.service._id}`);
          }

          membershipItems.push({
            ...membershipItem,
            transactionItemId,
          });
          transactionItemIds.add(transactionItemId);
        } else {
          membershipItems.push(membershipItem);
        }
      }
      payments.push({
        ...payment,
        items: membershipItems,
      });
    }
  }
  return payments;
};

const hasDuplicateIdsInTransactionItems = <Unit extends MeroUnits.Any>(
  items: CheckoutTransactionItem.Any<Unit>[],
): boolean => {
  const transactionItemIds = new Set();

  for (const item of items) {
    if (isDefined(item.transactionItemId)) {
      if (transactionItemIds.has(item.transactionItemId)) {
        return true;
      }
      transactionItemIds.add(item.transactionItemId);
    }
    if (item.type === 'Booking') {
      for (const bookingItem of item.items) {
        if (isDefined(bookingItem.transactionItemId)) {
          if (transactionItemIds.has(bookingItem.transactionItemId)) {
            return true;
          }
          transactionItemIds.add(bookingItem.transactionItemId);
        }
      }
    }
  }

  return false;
};

const hasDuplicateTransactionItemIdsInMembershipPayments = <Unit extends MeroUnits.Any>(
  payments: CheckoutTransactionPayment.Any<ScaledNumber, Unit>[],
): boolean => {
  const transactionItemIds = new Set();
  const membershipPayments = payments.filter(CheckoutTransactionPayment.isMembership);
  for (const membershipPayment of membershipPayments) {
    for (const membershipItem of membershipPayment.items) {
      if (membershipItem.type === 'Service') {
        if (transactionItemIds.has(membershipItem.transactionItemId)) {
          return true;
        }
        transactionItemIds.add(membershipItem.transactionItemId);
      }
    }
  }

  return false;
};

export const TransactionItemId = {
  assignIdsToItems,
  assignIdsToMembershipPaymentsItems,
  hasDuplicateIdsInTransactionItems,
  hasDuplicateTransactionItemIdsInMembershipPayments,
};
