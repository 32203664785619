import { CheckoutReportTotals } from './checkoutReportTotals';
import { JSONable, MeroUnits, PositiveInt, Zero } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutClientSalesReportTotals<Unit extends MeroUnits.Any> = {
  /**
   * Number of sold bookings that was assigned to this sale owner (PRO)
   */
  readonly appointmentsCount: Zero | PositiveInt;
  /**
   * Number of sold memberships that was assigned to this client
   */
  readonly membershipsCount: Zero | PositiveInt;
  /**
   * Number of sold products that was assigned to this client
   */
  readonly productsCount: number;
  /**
   * Total number of sold services that was assigned to this (including services on booking)
   */
  readonly servicesCount: Zero | PositiveInt;
  /**
   * Items totals
   */
  readonly totals: CheckoutReportTotals<Unit>;
};

const json = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<CheckoutClientSalesReportTotals<Unit>, JSONable> => {
  return t.type(
    {
      appointmentsCount: t.union([Zero, PositiveInt.JSON]),
      membershipsCount: t.union([Zero, PositiveInt.JSON]),
      productsCount: t.number,
      servicesCount: t.union([Zero, PositiveInt.JSON]),
      totals: CheckoutReportTotals.json(unit),
    },
    `CheckoutClientSalesReportTotals<${unit}>`,
  );
};

export const CheckoutClientSalesReportTotals = {
  json,
};
