export type New = {
  readonly type: 'new';
};

export type Started = {
  readonly type: 'started';
};

export type Succeeded = {
  readonly type: 'succeeded';
};

export type Failed = {
  readonly type: 'failed';
};

export type UserOrderPaymentStatusPreview = New | Started | Succeeded | Failed;

export const UserOrderPaymentStatusPreview = {};
