import { PageId } from '../pages';
import { BannedWords } from './banned-words';
import { CustomizedNotificationTemplateId } from './customized-notification-template-id';
import { CustomizedNotificationType } from './customized-notification-type';
import { JSONable, removeConsequentialDuplicateCharacters, removeNonAlphabeticalCharacters } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CustomizedNotificationTemplate = {
  readonly _id: CustomizedNotificationTemplateId;
  readonly pageId: PageId;
  readonly template: string;
  readonly type: CustomizedNotificationType;
};

const JSON: t.Type<CustomizedNotificationTemplate, JSONable> = t.type(
  {
    _id: CustomizedNotificationTemplateId.JSON,
    pageId: PageId,
    template: t.string,
    type: CustomizedNotificationType.JSON,
  },
  'CustomizedNotificationTemplate',
);

const includesBannedWords = (template: string): boolean => {
  //TODO: add more replacements
  const letterReplacements = [
    { letter: 'o', replacement: '0' },
    { letter: 'i', replacement: '1' },
    { letter: 'z', replacement: '2' },
    { letter: 'a', replacement: '@' },
    { letter: 's', replacement: '$' },
    { letter: 'e', replacement: '€' },
  ];

  let cleanTemplate = template;
  let templateWithLettersReplaced = cleanTemplate;
  for (const replacement of letterReplacements) {
    templateWithLettersReplaced = templateWithLettersReplaced.replace(
      new RegExp(`${replacement.replacement}`, 'g'),
      replacement.letter,
    );
  }

  //remove non alphabetic and repeated characters from template
  cleanTemplate = removeConsequentialDuplicateCharacters(removeNonAlphabeticalCharacters(cleanTemplate), false);
  templateWithLettersReplaced = removeConsequentialDuplicateCharacters(
    removeNonAlphabeticalCharacters(templateWithLettersReplaced),
    false,
  );

  for (const word of BannedWords) {
    if (
      new RegExp(`${word}`, 'i').test(templateWithLettersReplaced) ||
      new RegExp(`${word}`, 'i').test(cleanTemplate)
    ) {
      return true;
    }
  }

  return false;
};

export const CustomizedNotificationTemplate = {
  JSON,
  includesBannedWords,
};
