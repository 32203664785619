import { UserOrderPaymentId } from '../../../pro/userPayments/userOrderPaymentId';
import { UserOrderPayment } from './userOrderPayment';

export type Any = UserOrderPayment;

export const userOrderPayment = (params: { readonly paymentId: UserOrderPaymentId }): UserOrderPayment => {
  return {
    type: 'UserOrderPayment',
    paymentId: params.paymentId,
  };
};
