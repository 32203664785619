import { Zero, PositiveInt, Numbers } from '../numbers';
import * as t from 'io-ts';

export type OccurrenceIndex = Zero | PositiveInt;

const ZERO = Numbers[0];

const JSON = t.union([Zero, PositiveInt.JSON], 'OccurrenceIndex');

const unsafeFromNumber = (n: number): OccurrenceIndex => {
  if (!JSON.is(n)) {
    throw new Error(`Invalid OccurrenceIndex value ${n}`);
  }

  return n;
};

export const OccurrenceIndex = {
  ZERO,
  JSON,
  unsafeFromNumber,
};
