import { RoIBANish } from '../roIBANish';
import { SavedPageBillingDetails } from '../savedPageBillingDetails';
import { JSONable, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type GetPageMeroGiftCardsBillingResponse = {
  readonly billingDetails: SavedPageBillingDetails;
  readonly settlementAccount: Option<RoIBANish>;
};

const JSON: t.Type<GetPageMeroGiftCardsBillingResponse, JSONable> = t.type(
  {
    billingDetails: SavedPageBillingDetails.JSON,
    settlementAccount: Option.json(RoIBANish.JSON),
  },
  'GetPageMeroGiftCardsBillingResponse',
);

export const GetPageMeroGiftCardsBillingResponse = {
  JSON,
};
