/**
 * @since 0.5.19
 */
import * as A from 'fp-ts/es6/Array';
import { chain } from 'fp-ts/es6/Either';
import { fromFoldable, toArray } from 'fp-ts/es6/Map';
import { getLastSemigroup } from 'fp-ts/es6/Semigroup';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
var every = function (pk, pv) { return function (ma) {
    var entries = ma.entries();
    var e;
    while (!(e = entries.next()).done) {
        if (pk(e.value[0]) === false || pv(e.value[1]) === false) {
            return false;
        }
    }
    return true;
}; };
/**
 * @since 0.5.19
 */
export function mapFromEntries(keyCodec, KO, valueCodec, name) {
    if (name === void 0) { name = "Map<" + keyCodec.name + ", " + valueCodec.name + ">"; }
    var arr = t.array(t.tuple([keyCodec, valueCodec]));
    var toArrayO = toArray(KO);
    var fromArrayO = fromFoldable(KO, getLastSemigroup(), A.Foldable);
    var everyO = every(keyCodec.is, valueCodec.is);
    return new t.Type(name, function (u) { return u instanceof Map && everyO(u); }, function (u, c) {
        return pipe(arr.validate(u, c), chain(function (as) {
            var map = fromArrayO(as);
            return map.size !== as.length ? t.failure(u, c) : t.success(map);
        }));
    }, function (a) { return arr.encode(toArrayO(a)); });
}
