import { CheckoutReportTotals } from './checkoutReportTotals';
import { JSONable, MeroUnits, PositiveInt, Zero } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutServiceSalesReportTotals<Unit extends MeroUnits.Any> = {
  /**
   * Total number of times service was found in checkout transaction (including services on a booking)
   */
  readonly count: Zero | PositiveInt;
  /**
   * Total quantity of service in checkout transaction (including services on a booking)
   */
  readonly quantity: Zero | PositiveInt;
  /**
   * Items totals
   */
  readonly totals: CheckoutReportTotals<Unit>;
};

const json = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<CheckoutServiceSalesReportTotals<Unit>, JSONable> => {
  return t.type(
    {
      count: t.union([Zero, PositiveInt.JSON]),
      quantity: t.union([Zero, PositiveInt.JSON]),
      totals: CheckoutReportTotals.json(unit),
    },
    `CheckoutServiceSalesReportTotals<${unit}>`,
  );
};

export const CheckoutServiceSalesReportTotals = {
  json,
};
