import { InventoryOperationId } from '../inventoryOperationId';
import { StockDecrease } from './stockDecrease';
import { StockIncrease } from './stockIncrease';
import { StockManagementStatusUpdate } from './stockManagementStatusUpdate';
import { TransactionCancelledConsumption } from './transactionCancelledConsumption';
import { TransactionStockConsumption } from './transactionStockConsumption';

export * from './stockDecrease';
export * from './stockIncrease';
export * from './transactionCancelledConsumption';
export * from './transactionStockConsumption';
export * from './stockManagementStatusUpdate';

export type Any =
  | StockIncrease
  | StockDecrease
  | StockManagementStatusUpdate
  | TransactionStockConsumption
  | TransactionCancelledConsumption;

export const isStockIncrease = (operation: Any): operation is StockIncrease => {
  return operation.type === 'StockIncrease';
};

export const isStockDecrease = (operation: Any): operation is StockDecrease => {
  return operation.type === 'StockDecrease';
};

export const isStockManagementStatusUpdate = (operation: Any): operation is StockManagementStatusUpdate => {
  return operation.type === 'StockManagementStatusUpdate';
};

export const isTransactionStockConsumption = (operation: Any): operation is TransactionStockConsumption => {
  return operation.type === 'TransactionStockConsumption';
};

export const isTransactionCancelledConsumption = (operation: Any): operation is TransactionCancelledConsumption => {
  return operation.type === 'TransactionCancelledConsumption';
};
