import { BusinessCategoryId } from '../../services/businessCategoryId';
import { PageId } from '../page-id';
import { PageSearchWithHasPromoPrice } from '../page-search';
import { PagesSearch2Config } from '../pagesSearch2Config';
import { PagesSearch2Debug, PagesSearch2Request } from '../pagesSearch2Request';
import { PagesSearch2Response } from '../pagesSearch2Response';
import { DefinedTrimedString, JSONable, LatLng, Paged, PositiveInt, Zero } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const PageIdC: t.Type<PageId, string> = PageId;

export const PageSearchWithHasPromoPriceC: t.Type<PageSearchWithHasPromoPrice, JSONable> = PageSearchWithHasPromoPrice;

export const PagesSearch2ConfigC: t.Type<PagesSearch2Config, JSONable> = t.type(
  {
    filters: t.type(
      {
        hasPromoPrice: t.boolean,
        isTabyaCertified: t.boolean,
        hasGiftcards: t.boolean,
        acceptsCardPayments: t.boolean,
      },
      'Filters',
    ),
  },
  'PagesSearch2Config',
);

const PagesSearch2DebugC: t.Type<PagesSearch2Debug, JSONable> = t.type(
  {
    debugToken: t.string,
    customWeights: t.type(
      {
        cityNameMatchBoost: t.number,
        areaNameMatchBoost: t.number,
        surroundingCityNameMatchBoost: t.number,
        namePrefixMatchBoost: t.number,
        nameExactMatchBoost: t.number,
        nameFuzzyMatchBoost: t.number,
        categoryNameMatchBoost: t.number,
        workerFuzzyMatchBoost: t.number,
        serviceFuzzyMatchBoost: t.number,
      },
      'CustomWeight',
    ),
  },
  'PagesSearch2Debug',
);

export const PagesSearch2RequestC: t.Type<PagesSearch2Request, JSONable> = t.partial(
  {
    query: DefinedTrimedString,
    categories: t.array(BusinessCategoryId),
    geo: t.union(
      [
        t.type(
          {
            type: t.literal('City'),
            name: t.string,
          },
          'City',
        ),
        t.type(
          {
            type: t.literal('Nearby'),
            point: LatLng.JSON,
          },
          'Nearby',
        ),
      ],
      'Geo',
    ),
    hasPromoPrice: t.literal(true),
    isTabyaCertified: t.literal(true),
    hasGiftcards: t.literal(true),
    acceptsCardPayments: t.literal(true),
    forPromoSearch: t.boolean,
    page: t.string,
    debug: PagesSearch2DebugC,
  },
  'PagesSearch2Request',
);

export const PagesSearch2ResponseC: t.Type<PagesSearch2Response, JSONable> = t.type(
  {
    hits: Paged.json(t.array(PageSearchWithHasPromoPrice)),
    totalHitCount: t.union([Zero, PositiveInt.JSON]),
    config: PagesSearch2ConfigC,
  },
  'PagesSearch2Response',
);
