import * as t from 'io-ts';

/**
 * sent to a worker when a page requested him to join
 */
export const WorkerInviteNotificationType = t.literal(0);
export type WorkerInviteNotificationType = t.TypeOf<typeof WorkerInviteNotificationType>;

/**
 * sent to page managers when a worker fulfills a work invite
 */
export const WorkerInviteFulfilledNotificationType = t.literal(1);
export type WorkerInviteFulfilledNotificationType = t.TypeOf<typeof WorkerInviteFulfilledNotificationType>;

/**
 * sent when a client requests to join a private worker
 */
export const PrivateWorkerClientJoinRequestNotificationType = t.literal(2);
export type PrivateWorkerClientJoinRequestNotificationType = t.TypeOf<
  typeof PrivateWorkerClientJoinRequestNotificationType
>;

/**
 * sent to a user when a worker accepts a client join invite
 */
export const PrivateWorkerClientJoinRequestFulfilledNotificationType = t.literal(3);
export type PrivateWorkerClientJoinRequestFulfilledNotificationType = t.TypeOf<
  typeof PrivateWorkerClientJoinRequestFulfilledNotificationType
>;

/**
 * sent to page managers when a user makes a pending appointment
 */
export const AppointmentRequestedNotificationType = t.literal(4);
export type AppointmentRequestedNotificationType = t.TypeOf<typeof AppointmentRequestedNotificationType>;

/**
 * sent to a user when an appointment is confirmed
 */
export const AppointmentConfirmedNotificationType = t.literal(5);
export type AppointmentConfirmedNotificationType = t.TypeOf<typeof AppointmentConfirmedNotificationType>;

/**
 * sent to a user when a page makes an appointment for a user
 */
export const AppointmentMadeByPageNotificationType = t.literal(6);
export type AppointmentMadeByPageNotificationType = t.TypeOf<typeof AppointmentMadeByPageNotificationType>;

/**
 * sent to page managers when a client cancells an appointment
 */
export const AppointmentCancelledByClientNotificationType = t.literal(7);
export type AppointmentCancelledByClientNotificationType = t.TypeOf<
  typeof AppointmentCancelledByClientNotificationType
>;

/**
 * send to a user when a page cancells his appointment
 */
export const AppointmentCancelledByWorkerNotificationType = t.literal(8);
export type AppointmentCancelledByWorkerNotificationType = t.TypeOf<
  typeof AppointmentCancelledByWorkerNotificationType
>;

/**
 * sent to a user when a page edits one of his appointments
 */
export const AppointmentModifiedNotificationType = t.literal(9);
export type AppointmentModifiedNotificationType = t.TypeOf<typeof AppointmentModifiedNotificationType>;

/**
 * sent when an appointment was cancelled to those in "waiting list"
 */
export const AppointmentOpenningNotificationType = t.literal(10);
export type AppointmentOpenningNotificationType = t.TypeOf<typeof AppointmentOpenningNotificationType>;

/**
 * sent to a user when an appointment reminder is triggered
 */
export const AppointmentReminderNotificationType = t.literal(11);
export type AppointmentReminderNotificationType = t.TypeOf<typeof AppointmentReminderNotificationType>;

/**
 * sent to page managers when a client makes an appointment
 */
export const ClientAppointmentMadeNotificationType = t.literal(12);
export type ClientAppointmentMadeNotificationType = t.TypeOf<typeof ClientAppointmentMadeNotificationType>;

/**
 * sent to a user when his payment was succesfully processed
 */
export const PaymentSuccessfulNotificationType = t.literal(13);
export type PaymentSuccessfulNotificationType = t.TypeOf<typeof PaymentSuccessfulNotificationType>;

/**
 * sent to workers and page admins when a client has made a successful payment
 */
export const ClientAppointmentPaymentReceivedNotificationType = t.literal(14);
export type ClientAppointmentPaymentReceivedNotificationType = t.TypeOf<
  typeof ClientAppointmentPaymentReceivedNotificationType
>;

/**
 * sent to MP users with a generic text embedded in the payload
 */
export const GenericMarketplaceNotificationType = t.literal(15);
export type GenericMarketplaceNotificationType = t.TypeOf<typeof GenericMarketplaceNotificationType>;

/**
 * sent to Pro users with a generic text embedded in the payload
 */
export const GenericProNotificationType = t.literal(16);
export type GenericProNotificationType = t.TypeOf<typeof GenericProNotificationType>;

/**
 * sent to a user when an appointment review reminder is triggered
 */
export const AppointmentReviewReminderNotificationType = t.literal(17);
export type AppointmentReviewReminderNotificationType = t.TypeOf<typeof AppointmentReviewReminderNotificationType>;

/**
 * sent to Pro users when appointment is added by another pro
 */
export const AppointmentMadeByAnotherProNotificationType = t.literal(18);
export type AppointmentMadeByAnotherProNotificationType = t.TypeOf<typeof AppointmentMadeByAnotherProNotificationType>;

/**
 * sent to Pro users when there is an issue with mero sms app
 */
export const MeroSmsIssueNotificationType = t.literal(19);
export type MeroSmsIssueNotificationType = t.TypeOf<typeof MeroSmsIssueNotificationType>;

/**
 * send to page managers when a pro cancelled an appointment
 */
export const AppointmentCancelledByAnotherProNotificationType = t.literal(20);
export type AppointmentCancelledByAnotherProNotificationType = t.TypeOf<
  typeof AppointmentCancelledByAnotherProNotificationType
>;

/**
 * send to page managers when a pro modified an appointment
 */
export const AppointmentModifiedByAnotherProNotificationType = t.literal(21);
export type AppointmentModifiedByAnotherProNotificationType = t.TypeOf<
  typeof AppointmentModifiedByAnotherProNotificationType
>;

/**
 * send to page managers when an appointment action is made (added, modified, cancelled, accepted or rejected an appointment)
 */
export const GenericAppointmentActionNotificationType = t.literal(22);
export type GenericAppointmentActionNotificationType = t.TypeOf<typeof GenericAppointmentActionNotificationType>;

/**
 * sent to worker when a client was added to his waiting list for a specific day
 */
export const ClientAddedToWaintingListNotificationType = t.literal(23);
export type ClientAddedToWaintingListNotificationType = t.TypeOf<typeof ClientAddedToWaintingListNotificationType>;

export const NotificationType = t.union(
  [
    WorkerInviteNotificationType,
    WorkerInviteFulfilledNotificationType,
    PrivateWorkerClientJoinRequestNotificationType,
    PrivateWorkerClientJoinRequestFulfilledNotificationType,
    AppointmentRequestedNotificationType,
    AppointmentConfirmedNotificationType,
    AppointmentMadeByPageNotificationType,
    AppointmentCancelledByClientNotificationType,
    AppointmentCancelledByWorkerNotificationType,
    AppointmentModifiedNotificationType,
    AppointmentOpenningNotificationType,
    AppointmentReminderNotificationType,
    ClientAppointmentMadeNotificationType,
    PaymentSuccessfulNotificationType,
    ClientAppointmentPaymentReceivedNotificationType,
    GenericMarketplaceNotificationType,
    GenericProNotificationType,
    AppointmentReviewReminderNotificationType,
    AppointmentMadeByAnotherProNotificationType,
    MeroSmsIssueNotificationType,
    AppointmentCancelledByAnotherProNotificationType,
    AppointmentModifiedByAnotherProNotificationType,
    GenericAppointmentActionNotificationType,
    ClientAddedToWaintingListNotificationType,
  ],
  'NotificationType',
);

export type NotificationType = t.TypeOf<typeof NotificationType>;

export const WorkerNotificationType = t.union(
  [
    WorkerInviteNotificationType,
    WorkerInviteFulfilledNotificationType,
    PrivateWorkerClientJoinRequestNotificationType,
    AppointmentRequestedNotificationType,
    AppointmentCancelledByClientNotificationType,
    ClientAppointmentMadeNotificationType,
    ClientAppointmentPaymentReceivedNotificationType,
    GenericProNotificationType,
    AppointmentMadeByAnotherProNotificationType,
    MeroSmsIssueNotificationType,
    AppointmentCancelledByAnotherProNotificationType,
    AppointmentModifiedByAnotherProNotificationType,
    GenericAppointmentActionNotificationType,
    ClientAddedToWaintingListNotificationType,
  ],
  'WorkerNotificationType',
);

export type WorkerNotificationType = t.TypeOf<typeof WorkerNotificationType>;

export const ClientNotificationType = t.union(
  [
    PrivateWorkerClientJoinRequestFulfilledNotificationType,
    AppointmentConfirmedNotificationType,
    AppointmentMadeByPageNotificationType,
    AppointmentCancelledByWorkerNotificationType,
    AppointmentModifiedNotificationType,
    AppointmentOpenningNotificationType,
    AppointmentReminderNotificationType,
    PaymentSuccessfulNotificationType,
    GenericMarketplaceNotificationType,
    AppointmentReviewReminderNotificationType,
  ],
  'ClientNotificationType',
);
export type ClientNotificationType = t.TypeOf<typeof ClientNotificationType>;
