/**
 * @since 0.5.0
 */
import * as t from 'io-ts';
import { pipe } from 'fp-ts/es6/pipeable';
import { chain } from 'fp-ts/es6/Either';
/**
 * @example
 * import { DateFromISOString } from 'io-ts-types/es6/DateFromISOString'
 * import { right } from 'fp-ts/es6/Either'
 *
 * const date = new Date(1973, 10, 30)
 * const input = date.toISOString()
 * assert.deepStrictEqual(DateFromISOString.decode(input), right(date))
 *
 * @since 0.5.0
 */
export var DateFromISOString = new t.Type('DateFromISOString', function (u) { return u instanceof Date; }, function (u, c) {
    return pipe(t.string.validate(u, c), chain(function (s) {
        var d = new Date(s);
        return isNaN(d.getTime()) ? t.failure(u, c) : t.success(d);
    }));
}, function (a) { return a.toISOString(); });
