import { BasicCalculator } from './basicCalculator';
import { HasFromNumber } from './fromNumber';
import * as t from 'io-ts';

export interface PercentBrand {
  readonly Percent: unique symbol;
}

export type Percent<Num> = t.Branded<Num, PercentBrand>;

export type PercentModule<Num> = {
  /**
   * Checks if value is a valid Percent
   * Any numeric value is a valid percent, depending on situation it may be negative or greater than 100,
   * ex. "last year growth was 120%"
   */
  readonly is: (a: Num) => a is Percent<Num>;
  /**
   * Parses a number to a Percent or throws when invalid.
   */
  readonly unsafeFrom: (n: Num) => Percent<Num>;
  /**
   * Returns the value representing {@link percent} of given {@link value}
   */
  readonly ofValue: (value: Num, percent: Percent<Num>, decimals: number) => Num;
  /**
   * Build new JSON codec for DiscountPercent<Num>
   */
  readonly json: <O, I>(codec: t.Type<Num, O, I>) => t.Type<Percent<Num>, O, I>;
};

const build = <Num>(num: BasicCalculator<Num> & HasFromNumber<Num>): PercentModule<Num> => {
  const is = <Num>(n: Num): n is Percent<Num> => true;

  const unsafeFrom = <Num>(n: Num): Percent<Num> => {
    if (!is(n)) {
      throw new Error('Invalid Percent value');
    }

    return n;
  };

  const json = <Num, O, I>(codec: t.Type<Num, O, I>): t.Type<Percent<Num>, O, I> => t.brand(codec, is, 'Percent');

  const ofValue = (value: Num, percent: Percent<Num>, decimals: number): Num => {
    return num.div(num.mul(value, percent), num.fromNumber(100, 0), decimals);
  };

  return {
    is: is,
    unsafeFrom,
    ofValue,
    json,
  };
};

export const Percent = {
  build,
};
