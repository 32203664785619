import { PageId } from '../pages';
import { Discount } from './discount';
import { HttpClient, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export * from './discount-id';
export * from './discount-type';
export * from './discount';

export interface DiscountsApi {
  /**
   * Find a discount by code.
   */
  readonly findDiscount: (params: { pageId: PageId; code: string }) => Promise<Discount>;
}

export const discountsHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): DiscountsApi => {
  const { apiBaseUrl, http } = env;

  const findDiscountDecoder = http.decode.optionalResponse(UnknownApiError, t.type({ result: Discount }));

  return {
    findDiscount: async ({ pageId, code }) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${pageId.toString()}/discounts`,
            params: {
              code,
            },
          },
          findDiscountDecoder,
        ),
      ).result,
  };
};
