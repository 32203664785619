import { AppointmentId } from '../calendar';
import { CheckoutTransactionServicePrice } from '../checkout';
import {
  CheckoutTransactionIdC,
  CheckoutUserPreviewC,
  appliedDiscountC,
} from '../checkout/checkoutHttpClient/checkoutJson';
import { PageId } from '../pages';
import { HasServiceId, ServiceGroupId, ServiceId } from '../services';
import { AvailableMembershipItems } from './membershipAvailableItems';
import { MembershipConsumedItem, MembershipConsumptionPerTransaction } from './membershipConsumptionPerTransaction';
import { MembershipItem, ProductItem, ServiceItem } from './membershipItem';
import { MembershipItemDetails, ProductItemDetails, ServiceItemDetails } from './membershipItemDetails';
import { DeletedCheckoutTransaction, FinishedCheckoutTransaction, MembershipPayment } from './membershipPayment';
import { MembershipPaymentId } from './membershipPaymentId';
import { MembershipPurchaseId } from './membershipPurchaseId';
import { MembershipTemplate } from './membershipTemplate';
import { MembershipTemplateDetails } from './membershipTemplateDetails';
import { MembershipTemplateId } from './membershipTemplateId';
import { MembershipTemplateValidity } from './membershipTemplateValidity';
import { PurchasedMembership } from './purchasedMembership';
import { PurchasedMembershipDetails } from './purchasedMembershipDetails';
import {
  PurchasedMembershipItemDetails,
  PurchasedMembershipProductItemDetails,
  PurchasedMembershipServiceItemDetails,
} from './purchasedMembershipItemDetails';
import { PurchasedMembershipTemplate, PurchasedMembershipTemplateDetails } from './purchasedMembershipTemplate';
import { PurchasedMembershipValidity } from './purchasedMembershipValidity';
import { PurchasedMembershipsTotals } from './purchasedMembershipsTotals';
import { CreateMembershipTemplateRequest, UpdateMembershipTemplateRequest } from './requests';
import { HasOptionalFirstLastName, MeroUnits, Zero, optionull } from '@mero/shared-sdk';
import { JSONable, Money, PositiveInt, ScaledNumber } from '@mero/shared-sdk';
import { DefinedString } from '@mero/shared-sdk';
import { OptionalDefinedString } from '@mero/shared-sdk';
import { UserId } from '@mero/shared-sdk';
import { OptionalFirstname } from '@mero/shared-sdk';
import { OptionalLastname } from '@mero/shared-sdk';
import { StrictPhoneNumber } from '@mero/shared-sdk';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';
import * as tt from 'io-ts-types';

const MeroUnitsC: { [Unit in MeroUnits.Any]: t.Type<Unit> } = {
  [MeroUnits.RON.code]: MeroUnits.RON.JSON,
  [MeroUnits.EUR.code]: MeroUnits.EUR.JSON,
};

export const unitC = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<Unit> => MeroUnitsC[unit];

const MeroMoneyC: { [Unit in MeroUnits.Any]: t.Type<Money<ScaledNumber, Unit>, JSONable> } = {
  [MeroUnits.RON.code]: Money.json(ScaledNumber.JSON, unitC(MeroUnits.RON.code)),
  [MeroUnits.EUR.code]: Money.json(ScaledNumber.JSON, unitC(MeroUnits.EUR.code)),
};

export const moneyC = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<Money<ScaledNumber, Unit>, JSONable> => {
  return MeroMoneyC[unit];
};

export const createMembershipTemplateRequestC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CreateMembershipTemplateRequest<Unit>, JSONable> => {
  return t.strict(
    {
      name: DefinedString,
      unit: unitC(unit),
      items: t.array(
        t.type(
          {
            type: t.literal('Service'),
            service: HasServiceId,
            sellingPrice: t.type(
              {
                amount: ScaledNumber.JSON,
                unit: unitC(unit),
              },
              'SellingPrice',
            ),
            quantity: t.union(
              [
                t.type(
                  {
                    type: t.literal('Limited'),
                    value: PositiveInt.JSON,
                  },
                  'Limited',
                ),
                t.type(
                  {
                    type: t.literal('Unlimited'),
                  },
                  'Unlimited',
                ),
              ],
              'Quantity',
            ),
          },
          'Item',
        ),
      ),
      validFor: t.union(
        [
          t.type(
            {
              type: t.literal('Limited'),
              days: PositiveInt.JSON,
            },
            'Limited',
          ),
          t.type(
            {
              type: t.literal('Unlimited'),
            },
            'Unlimited',
          ),
        ],
        'ValidFor',
      ),
      description: OptionalDefinedString,
      discount: optionull(appliedDiscountC(unit)),
      termsAndConditions: OptionalDefinedString,
    },
    `CreateMembershipTemplateRequest<${unit}>`,
  );
};

export const updateMembershipTemplateRequestC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<UpdateMembershipTemplateRequest<Unit>, JSONable> => {
  return t.strict(
    {
      name: DefinedString,
      items: t.array(
        t.type(
          {
            type: t.literal('Service'),
            service: HasServiceId,
            sellingPrice: t.type(
              {
                amount: ScaledNumber.JSON,
                unit: unitC(unit),
              },
              'SellingPrice',
            ),
            quantity: t.union(
              [
                t.type(
                  {
                    type: t.literal('Limited'),
                    value: PositiveInt.JSON,
                  },
                  'Limited',
                ),
                t.type(
                  {
                    type: t.literal('Unlimited'),
                  },
                  'Unlimited',
                ),
              ],
              'Quantity',
            ),
          },
          'Item',
        ),
      ),
      validFor: t.union(
        [
          t.type(
            {
              type: t.literal('Limited'),
              days: PositiveInt.JSON,
            },
            'Limited',
          ),
          t.type(
            {
              type: t.literal('Unlimited'),
            },
            'Unlimited',
          ),
        ],
        'ValidFor',
      ),
      status: t.union([t.literal('Active'), t.literal('Inactive')], 'Status'),
      description: OptionalDefinedString,
      discount: optionull(appliedDiscountC(unit)),
      termsAndConditions: OptionalDefinedString,
    },
    `UpdateMembershipTemplateRequest<${unit}>`,
  );
};

export const MembershipDiscountC = <Unit extends MeroUnits.Any>(unit: Unit) => {
  return t.union(
    [
      t.type(
        {
          type: t.literal('Percent'),
          percent: ScaledNumber.JSON,
        },
        'DiscountPercent',
      ),
      t.type(
        {
          type: t.literal('Value'),
          value: t.type(
            {
              unit: t.literal(unit),
              amount: ScaledNumber.JSON,
            },
            'Value',
          ),
        },
        'DiscountValue',
      ),
    ],
    `MembershipDiscountC<${unit}>`,
  );
};

const MembershipItemC = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<MembershipItem<Unit>, JSONable> => {
  const serviceItemC = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<ServiceItem<Unit>, JSONable> => {
    return t.strict(
      {
        service: HasServiceId,
        type: t.literal('Service'),
        sellingPrice: moneyC(unit),
        quantity: t.union(
          [
            t.type(
              {
                type: t.literal('Limited'),
                value: PositiveInt.JSON,
              },
              'Limited',
            ),
            t.type(
              {
                type: t.literal('Unlimited'),
              },
              'Unlimited',
            ),
          ],
          'Quantity',
        ),
      },
      `ServiceItemC<${unit}>`,
    );
  };

  const productItemC: t.Type<ProductItem, JSONable> = t.strict(
    {
      type: t.literal('Product'),
    },
    `ProductItemC`,
  );

  return t.union([serviceItemC(unit), productItemC], 'MembershipItem');
};

export const membershipItemServicePriceC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutTransactionServicePrice<ScaledNumber, Unit>, JSONable> => {
  return t.union(
    [
      t.strict(
        {
          type: t.literal('Hidden'),
        },
        `Hidden<${unit}>`,
      ),
      t.strict(
        {
          type: t.literal('Fixed'),
          fixed: moneyC(unit),
        },
        `Fixed<${unit}>`,
      ),
      t.strict(
        {
          type: t.literal('Range'),
          range: t.partial(
            {
              from: moneyC(unit),
              to: moneyC(unit),
            },
            'Range',
          ),
        },
        `Range<${unit}>`,
      ),
    ],
    `MembershipItemServicePrice<${unit}>`,
  );
};

const MembershipItemDetailsC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<MembershipItemDetails<Unit>, JSONable> => {
  const serviceItemDetailsC = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<ServiceItemDetails<Unit>, JSONable> => {
    return t.intersection(
      [
        t.type(
          {
            service: t.type(
              {
                _id: ServiceId,
                durationInMinutes: PositiveInt.JSON,
                name: DefinedString,
                price: membershipItemServicePriceC(unit),
                groupIds: optionull(t.array(ServiceGroupId)),
              },
              'Service',
            ),
            type: t.literal('Service'),
            sellingPrice: moneyC(unit),
          },
          'ServiceItem',
        ),
        t.union(
          [
            t.type(
              {
                quantity: t.type(
                  {
                    type: t.literal('Limited'),
                    value: PositiveInt.JSON,
                  },
                  'LimitedQuantityType',
                ),
                discount: optionull(appliedDiscountC(unit)),
              },
              'LimitedQuantity',
            ),
            t.type(
              {
                quantity: t.type(
                  {
                    type: t.literal('Unlimited'),
                  },
                  'UnlimitedQuantityType',
                ),
              },
              'UnlimitedQuantity',
            ),
          ],
          `MembershipItemQuantityC<${unit}>`,
        ),
      ],
      `ServiceItemDetailsC<${unit}>`,
    );
  };

  const productItemDetailsC: t.Type<ProductItemDetails, JSONable> = t.strict(
    {
      type: t.literal('Product'),
    },
    `ProductItemDetailsC<${unit}>`,
  );

  return t.union([serviceItemDetailsC(unit), productItemDetailsC], 'MembershipItemDetails');
};

export const MembershipTemplateC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<MembershipTemplate<Unit>, JSONable> => {
  return t.strict(
    {
      _id: MembershipTemplateId.JSON,
      unit: unitC(unit),
      pageId: PageId,
      name: DefinedString,
      validFor: MembershipTemplateValidity.JSON,
      items: t.array(MembershipItemC(unit), 'Items'),
      status: t.union([t.literal('Active'), t.literal('Inactive'), t.literal('Deleted')], 'Status'),
      description: OptionalDefinedString,
      termsAndConditions: OptionalDefinedString,
      discount: optionull(appliedDiscountC(unit)),
    },
    `MembershipTemplateC<${unit}>`,
  );
};

export const PurchasedMembershipTemplateC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<PurchasedMembershipTemplate<Unit>, JSONable> => {
  return t.strict(
    {
      _id: MembershipTemplateId.JSON,
      pageId: PageId,
      name: DefinedString,
      validFor: PurchasedMembershipValidity.JSON,
      items: t.array(MembershipItemC(unit), 'Items'),
      description: OptionalDefinedString,
      termsAndConditions: OptionalDefinedString,
      discount: optionull(appliedDiscountC(unit)),
      sellingPrice: moneyC(unit),
    },
    `PurchasedMembershipTemplateC<${unit}>`,
  );
};

export const AnyMembershipTemplateC: t.Type<
  MembershipTemplate<MeroUnits.RON> | MembershipTemplate<MeroUnits.EUR>,
  JSONable
> = t.union(
  [MembershipTemplateC(MeroUnits.RON.code), MembershipTemplateC(MeroUnits.EUR.code)],
  'AnyMembershipTemplateC',
);

const PurchasedMembershipItemDetailsC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<PurchasedMembershipItemDetails<Unit>, JSONable> => {
  const serviceItemDetailsC = <Unit extends MeroUnits.Any>(
    unit: Unit,
  ): t.Type<PurchasedMembershipServiceItemDetails<Unit>, JSONable> => {
    return t.intersection(
      [
        t.type(
          {
            service: t.type(
              {
                _id: ServiceId,
                durationInMinutes: PositiveInt.JSON,
                name: DefinedString,
                price: membershipItemServicePriceC(unit),
                groupIds: optionull(t.array(ServiceGroupId)),
              },
              'Service',
            ),
            type: t.literal('Service'),
            sellingPrice: moneyC(unit),
            consumed: t.union([Zero, PositiveInt.JSON]),
          },
          'ServiceItem',
        ),
        t.union(
          [
            t.type(
              {
                quantity: t.type(
                  {
                    type: t.literal('Limited'),
                    value: PositiveInt.JSON,
                  },
                  'Quantity',
                ),
              },
              'LimitedQuantity',
            ),
            t.type(
              {
                quantity: t.type(
                  {
                    type: t.literal('Unlimited'),
                  },
                  'Quantity',
                ),
              },
              'UnlimitedQuantity',
            ),
          ],
          `MembershipItemQuantityC<${unit}>`,
        ),
      ],
      `ServiceItemDetailsC<${unit}>`,
    );
  };

  const productItemDetailsC: t.Type<PurchasedMembershipProductItemDetails, JSONable> = t.strict(
    {
      type: t.literal('Product'),
    },
    `ProductItemDetailsC<${unit}>`,
  );

  return t.union([serviceItemDetailsC(unit), productItemDetailsC], 'PurchasedMembershipItemDetails');
};

export const PurchasedMembershipTemplateDetailsC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<PurchasedMembershipTemplateDetails<Unit>, JSONable> => {
  return t.strict(
    {
      _id: MembershipTemplateId.JSON,
      pageId: PageId,
      name: DefinedString,
      validFor: PurchasedMembershipValidity.JSON,
      items: t.array(PurchasedMembershipItemDetailsC(unit), 'Items'),
      sellingPrice: moneyC(unit),
      description: OptionalDefinedString,
      termsAndConditions: OptionalDefinedString,
      discount: optionull(appliedDiscountC(unit)),
    },
    `PurchasedMembershipTemplateDetailsC<${unit}>`,
  );
};

export const MembershipTemplateDetailsC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<MembershipTemplateDetails<Unit>, JSONable> => {
  return t.strict(
    {
      _id: MembershipTemplateId.JSON,
      unit: unitC(unit),
      pageId: PageId,
      name: DefinedString,
      validFor: MembershipTemplateValidity.JSON,
      items: t.array(MembershipItemDetailsC(unit), 'Items'),
      status: t.union([t.literal('Active'), t.literal('Inactive'), t.literal('Deleted')], 'Status'),
      sellingPrice: moneyC(unit),
      description: OptionalDefinedString,
      termsAndConditions: OptionalDefinedString,
      discount: optionull(appliedDiscountC(unit)),
    },
    `MembershipTemplateDetails<${unit}>`,
  );
};

export const AnyMembershipTemplateDetailsC: t.Type<
  MembershipTemplateDetails<MeroUnits.RON> | MembershipTemplateDetails<MeroUnits.EUR>,
  JSONable
> = t.union(
  [MembershipTemplateDetailsC(MeroUnits.RON.code), MembershipTemplateDetailsC(MeroUnits.EUR.code)],
  'AnyMembershipTemplateDetailsC',
);

export const PurchasedMembershipC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<PurchasedMembership<Unit>, JSONable> => {
  return t.type(
    {
      _id: MembershipPurchaseId.JSON,
      unit: unitC(unit),
      user: t.type(
        {
          _id: UserId,
          phone: StrictPhoneNumber,
          profile: t.type(
            {
              firstname: OptionalFirstname,
              lastname: OptionalLastname,
            },
            'UserProfile',
          ),
        },
        'User',
      ),
      purchasedOn: DateFromISOString,
      template: PurchasedMembershipTemplateC(unit),
      status: t.union([t.literal('Active'), t.literal('Consumed'), t.literal('Cancelled')], 'Status'),
      debt: moneyC(unit),
      note: OptionalDefinedString,
    },
    `PurchasedMembershipC<${unit}>`,
  );
};

export const AnyPurchasedMembershipC: t.Type<
  PurchasedMembership<MeroUnits.RON> | PurchasedMembership<MeroUnits.EUR>,
  JSONable
> = t.union(
  [PurchasedMembershipC(MeroUnits.RON.code), PurchasedMembershipC(MeroUnits.EUR.code)],
  'AnyPurchasedMembershipC',
);

export const PurchasedMembershipDetailsC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<PurchasedMembershipDetails<Unit>, JSONable> => {
  return t.type(
    {
      _id: MembershipPurchaseId.JSON,
      unit: unitC(unit),
      user: t.type(
        {
          _id: UserId,
          phone: StrictPhoneNumber,
          profile: t.type(
            {
              firstname: OptionalFirstname,
              lastname: OptionalLastname,
            },
            'UserProfile',
          ),
        },
        'User',
      ),
      purchasedOn: DateFromISOString,
      template: PurchasedMembershipTemplateDetailsC(unit),
      status: t.union([t.literal('Active'), t.literal('Consumed'), t.literal('Cancelled')], 'Status'),
      paid: moneyC(unit),
      note: OptionalDefinedString,
    },
    `PurchasedMembershipDetailsC<${unit}>`,
  );
};

export const AnyPurchasedMembershipDetailsC: t.Type<
  PurchasedMembershipDetails<MeroUnits.RON> | PurchasedMembershipDetails<MeroUnits.EUR>,
  JSONable
> = t.union(
  [PurchasedMembershipDetailsC(MeroUnits.RON.code), PurchasedMembershipDetailsC(MeroUnits.EUR.code)],
  'AnyPurchasedMembershipDetailsC',
);

const FinishedCheckoutTransactionC = (): t.Type<FinishedCheckoutTransaction, JSONable> => {
  return t.type(
    {
      _id: CheckoutTransactionIdC,
      finishedAt: DateFromISOString,
      paymentMethod: t.union(
        [
          t.literal('Card'),
          t.literal('Cash'),
          t.literal('BankTransfer'),
          t.literal('Giftcard'),
          t.literal('Online'),
          t.literal('Mix'),
        ],
        'PaymentMethod',
      ),
      status: t.literal('Finished'),
    },
    `FinishedCheckoutTransactionC`,
  );
};

const DeletedCheckoutTransactionC = (): t.Type<DeletedCheckoutTransaction, JSONable> => {
  return t.type(
    {
      _id: CheckoutTransactionIdC,
      finishedAt: DateFromISOString,
      paymentMethod: t.union(
        [
          t.literal('Card'),
          t.literal('Cash'),
          t.literal('BankTransfer'),
          t.literal('Giftcard'),
          t.literal('Online'),
          t.literal('Mix'),
        ],
        'PaymentMethod',
      ),
      status: t.literal('Deleted'),
      deletedAt: DateFromISOString,
    },
    `DeletedCheckoutTransactionC`,
  );
};

export const MembershipPaymentC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<MembershipPayment<Unit>, JSONable> => {
  return t.type(
    {
      _id: MembershipPaymentId.JSON,
      membershipPurchaseId: MembershipPurchaseId.JSON,
      unit: unitC(unit),
      amount: moneyC(unit),
      checkoutTransaction: t.union(
        [FinishedCheckoutTransactionC(), DeletedCheckoutTransactionC()],
        'CheckoutTransaction',
      ),
    },
    `MembershipPaymentC<${unit}>`,
  );
};

export const AnyMembershipPaymentC: t.Type<
  MembershipPayment<MeroUnits.RON> | MembershipPayment<MeroUnits.EUR>,
  JSONable
> = t.union([MembershipPaymentC(MeroUnits.RON.code), MembershipPaymentC(MeroUnits.EUR.code)], 'AnyMembershipPaymentC');

export const membershipConsumptionPerTransactionC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<MembershipConsumptionPerTransaction<Unit>, JSONable> => {
  const consumedMembershipItemC = <Unit extends MeroUnits.Any>(
    unit: Unit,
  ): t.Type<MembershipConsumedItem<Unit>, JSONable> => {
    return t.strict(
      {
        item: t.type(
          {
            type: t.literal('Service'),
            service: t.type(
              {
                _id: ServiceId,
                name: t.string,
                effectivePrice: moneyC(unit),
                price: membershipItemServicePriceC(unit),
              },
              'Service',
            ),
          },
          'Item',
        ),
        quantity: PositiveInt.JSON,
        saleOwner: CheckoutUserPreviewC,
      },
      `MembershipConsumedItem<${unit}>`,
    );
  };

  return t.type(
    {
      transaction: t.type(
        {
          _id: CheckoutTransactionIdC,
          code: t.string,
          date: tt.DateFromISOString,
        },
        'Transaction',
      ),
      consumption: t.array(
        t.intersection([
          t.type(
            {
              items: t.array(consumedMembershipItemC(unit)),
            },
            '!',
          ),
          t.partial(
            {
              appointment: t.type(
                {
                  _id: AppointmentId,
                  start: tt.DateFromISOString,
                },
                'Appointment',
              ),
            },
            '?',
          ),
        ]),
        'Consumption',
      ),
    },
    `MembershipConsumptionPerTransactionC<${unit}>`,
  );
};

export const AnyMembershipConsumptionPerTransactionC: t.Type<
  MembershipConsumptionPerTransaction<MeroUnits.RON> | MembershipConsumptionPerTransaction<MeroUnits.EUR>,
  JSONable
> = t.union(
  [membershipConsumptionPerTransactionC(MeroUnits.RON.code), membershipConsumptionPerTransactionC(MeroUnits.EUR.code)],
  'AnyMembershipConsumptionPerTransactionC',
);

export const AvailableMembershipItemsC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<AvailableMembershipItems<Unit>, JSONable> => {
  return t.strict(
    {
      membership: t.type(
        {
          _id: MembershipPurchaseId.JSON,
          name: t.string,
          validFor: PurchasedMembershipValidity.JSON,
          sellingPrice: moneyC(unit),
          debt: moneyC(unit),
        },
        'Membership',
      ),
      items: t.array(
        t.union(
          [
            t.type(
              {
                type: t.literal('Service'),
                service: t.type(
                  {
                    _id: ServiceId,
                    name: t.string,
                    durationInMinutes: t.number,
                  },
                  'Service',
                ),
                effectivePrice: moneyC(unit),
                availableQuantity: t.union(
                  [
                    t.type(
                      {
                        type: t.literal('Limited'),
                        remaining: t.union([Zero, PositiveInt.JSON], 'Remaining'),
                      },
                      'LimitedAvailableQuantity',
                    ),
                    t.type(
                      {
                        type: t.literal('Unlimited'),
                      },
                      'UnlimitedAvailableQuantity',
                    ),
                  ],
                  'AvailableQuantity',
                ),
                saleOwner: optionull(
                  t.type(
                    {
                      _id: UserId,
                      profile: HasOptionalFirstLastName,
                    },
                    'SaleOwner',
                  ),
                ),
              },
              'ServiceItem',
            ),
            t.type(
              {
                type: t.literal('Product'),
              },
              'ProductItem',
            ),
          ],
          'Item',
        ),
        'Items',
      ),
    },
    `AvailableMembershipItemsC<${unit}>`,
  );
};

export const AnyAvailableMembershipItemsC: t.Type<
  AvailableMembershipItems<MeroUnits.RON> | AvailableMembershipItems<MeroUnits.EUR>,
  JSONable
> = t.union(
  [AvailableMembershipItemsC(MeroUnits.RON.code), AvailableMembershipItemsC(MeroUnits.EUR.code)],
  'AnyAvailableMembershipItemsC',
);

export const PurchasedMembershipsTotalsC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<PurchasedMembershipsTotals<Unit>, JSONable> => {
  return t.type(
    {
      totalSellingPrice: moneyC(unit),
      totalPayments: moneyC(unit),
      totalCount: t.number,
    },
    `PurchasedMembershipsTotalsC<${unit}>`,
  );
};
