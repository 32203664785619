/**
 * @since 0.5.0
 */
import { pipe } from 'fp-ts/es6/pipeable';
import { map } from 'fp-ts/es6/Either';
import * as O from 'fp-ts/es6/Option';
import * as t from 'io-ts';
import { option } from './option';
/**
 * @since 0.5.0
 */
export function optionFromNullable(codec, name) {
    if (name === void 0) { name = "Option<" + codec.name + ">"; }
    return new t.Type(name, option(codec).is, function (u, c) {
        return u == null
            ? t.success(O.none)
            : pipe(codec.validate(u, c), map(O.some));
    }, function (a) {
        return O.toNullable(pipe(a, O.map(codec.encode)));
    });
}
