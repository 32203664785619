import { ExtendedCalculator } from './extendedCalculator';
import { HasFromNumber } from './fromNumber';
import { NumberCalculator } from './numberCalculator';
import { PositiveInt } from './positiveInt';
import { HasToNumber } from './toNumber';
import * as t from 'io-ts';

export const Zero = t.intersection([t.literal(0), t.Int], '0');
export type Zero = t.TypeOf<typeof Zero>;

export const One = t.intersection([t.literal(1), PositiveInt.JSON], '1');
export type One = t.TypeOf<typeof One>;

export const Two = t.intersection([t.literal(2), PositiveInt.JSON], '2');
export type Two = t.TypeOf<typeof Two>;

export const Three = t.intersection([t.literal(3), PositiveInt.JSON], '3');
export type Three = t.TypeOf<typeof Three>;

export const Four = t.intersection([t.literal(4), PositiveInt.JSON], '4');
export type Four = t.TypeOf<typeof Four>;

export const Five = t.intersection([t.literal(5), PositiveInt.JSON], '5');
export type Five = t.TypeOf<typeof Five>;

const toNumber = (a: number): number => a;

const fromNumber = (a: number, decimals: number): number =>
  Math.round(a * Math.pow(10, decimals)) / Math.pow(10, decimals);

export type NumbersMudule = ExtendedCalculator<number> &
  HasFromNumber<number> &
  HasToNumber<number> & {
    readonly 0: Zero;
    readonly 1: One;
    readonly 2: Two;
    readonly 3: Three;
    readonly 4: Four;
    readonly 5: Five;
  };

export const Numbers: NumbersMudule = {
  0: 0 as Zero,
  1: 1 as One,
  2: 2 as Two,
  3: 3 as Three,
  4: 4 as Four,
  5: 5 as Five,
  ...NumberCalculator,
  fromNumber,
  toNumber,
};
