/**
 * @since 0.5.14
 */
import * as t from 'io-ts';
/**
 * @since 0.5.15
 */
export var JsonArray = t.recursion('JsonArray', function () { return t.readonlyArray(Json); });
/**
 * @since 0.5.15
 */
export var JsonRecord = t.recursion('JsonRecord', function () { return t.record(t.string, Json); });
/**
 * @since 0.5.15
 */
export var Json = t.union([t.boolean, t.number, t.string, t.null, JsonArray, JsonRecord], 'Json');
/**
 * @since 0.5.14
 */
export var JsonFromString = new t.Type('JsonFromString', Json.is, function (s, c) {
    try {
        return t.success(JSON.parse(s));
    }
    catch (e) {
        return t.failure(s, c);
    }
}, function (json) { return JSON.stringify(json); });
