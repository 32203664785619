import { InventoriesApi } from './inventoriesApi';
import {
  InventoryArrayC,
  PagedSavedInventoryOperationsArrayC,
  ProductStockC,
  ProductsStocksMapC,
  StockDescriptionC,
} from './inventoriesJson';
import { HttpClient, Paged, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const inventoriesHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): InventoriesApi => {
  const { apiBaseUrl, http } = env;

  const updateStockManagementStatusDecoder = http.decode.response(UnknownApiError, t.unknown);
  const updateProductStockDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getAllInventoriesDecoder = http.decode.response(UnknownApiError, InventoryArrayC);
  const getProductOperationsDecoder = http.decode.response(UnknownApiError, PagedSavedInventoryOperationsArrayC);
  const getProductStockDecoder = http.decode.response(UnknownApiError, StockDescriptionC);
  const getProductTotalStockDecoder = http.decode.response(UnknownApiError, StockDescriptionC);
  const getProductStatusDecoder = http.decode.response(UnknownApiError, t.boolean);
  const getProductsInventoryStockDecoder = http.decode.response(UnknownApiError, ProductsStocksMapC);

  return {
    updateStockManagementStatus: async ({ pageId, productId, isStockManagementEnabled }) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/inventories/products/${encodeURIComponent(
              productId,
            )}/status`,
            data: {
              isStockManagementEnabled: isStockManagementEnabled,
            },
          },
          updateStockManagementStatusDecoder,
        ),
      );
    },

    updateProductStockValue: async ({
      pageId,
      inventoryId,
      productId,
      newStockValue,
      oldStockValue,
      adjustmentValue,
      type,
      reason,
    }) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              pageId,
            )}/inventories/${inventoryId}/products/${encodeURIComponent(productId)}/stock`,
            data: {
              newStockValue: newStockValue,
              adjustmentValue: adjustmentValue,
              oldStockValue: oldStockValue,
              type: type,
              reason: reason,
            },
          },
          updateProductStockDecoder,
        ),
      );
    },

    getAllInventories: async (pageId) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/inventories`,
          },
          getAllInventoriesDecoder,
        ),
      );
    },

    getProductOperationsHistory: async ({ productId, pageId, inventoryId, limit, page }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/inventories/${encodeURIComponent(
              inventoryId,
            )}/products/${encodeURIComponent(productId)}/operations-history`,
            params: {
              limit: limit,
              page: page,
            },
          },
          getProductOperationsDecoder,
        ),
      );
    },

    searchProductsInventoryStock: async ({ productIds, inventoryId, pageId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/inventories/${encodeURIComponent(
              inventoryId,
            )}/products-stocks`,
            data: {
              productIds: productIds,
            },
          },
          getProductsInventoryStockDecoder,
        ),
      );
    },

    getProductInventoryStock: async ({ productId, inventoryId, pageId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/inventories/${encodeURIComponent(
              inventoryId,
            )}/products/${encodeURIComponent(productId)}`,
          },
          getProductStockDecoder,
        ),
      );
    },

    getProductTotalStock: async ({ productId, pageId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/inventories/products/${encodeURIComponent(
              productId,
            )}/total`,
          },
          getProductTotalStockDecoder,
        ),
      );
    },

    getProductStatus: async ({ productId, pageId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/inventories/products/${encodeURIComponent(
              productId,
            )}/status`,
          },
          getProductStatusDecoder,
        ),
      );
    },
  };
};
