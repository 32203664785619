import * as MeroApi from '@mero/api-sdk';
import { DateTime } from 'luxon';
import * as React from 'react';
import { View } from 'react-native';

import { colors } from '../../../styles';
import sizes from '../../../styles/sizes';
import { styles as text } from '../../../styles/text';
import { formatTimeDiff } from '../../../utils/format';
import HSpacer from '../../HSpacer';
import Icon from '../../Icon';
import Body from '../../Text/Body';
import SmallBody from '../../Text/SmallBody';
import NotImplemented from '../NotImplemented';
import { styles } from '../styles';

export type Props = {
  readonly notification: MeroApi.notifications.AppointmentRequestedNotification;
};

const AppointmentRequestedNotification: React.FC<Props> = ({ notification }: Props) => {
  const { payload } = notification;
  const date = DateTime.fromJSDate(payload.appointment.start, { zone: payload.appointment.timezone });
  const fulfillmentText =
    payload.appointment.status === 'accepted'
      ? 'acceptată'
      : payload.appointment.status === 'rejected'
      ? 'refuzată'
      : '';

  if (payload.appointment.status === 'pending') return <></>;

  const isAccepted = payload.appointment.status === 'accepted';

  return (
    <View style={styles.notificationContainer}>
      <Icon type={isAccepted ? 'notification_calendar' : 'notification_calendar_cancel'} />
      <View style={styles.notificationBody}>
        <Body>
          Programarea pentru{' '}
          <Body style={text.semibold}>
            {payload.client.firstname} {payload.client.lastname}
          </Body>{' '}
          din <Body style={text.semibold}>{date.toFormat('d LLL yyyy', { locale: 'ro' })}</Body> ora{' '}
          <Body style={text.semibold}>{date.toFormat('H:mm', { locale: 'ro' })}</Body> a fost{' '}
          <Body style={text.semibold}>{fulfillmentText}</Body>.
          {(payload.appointment.giftCardIds?.length ?? 0) > 0 ? <Body> Un card cadou a fost adăugat.</Body> : null}
          {(payload.appointment.discountIds?.length ?? 0) > 0 ? <Body> Un cod de reducere a fost adăugat.</Body> : null}
        </Body>
        <SmallBody style={styles.timeText}>
          {formatTimeDiff(notification.createdAt, new Date())} • {payload.worker.firstname}{' '}
          {payload.worker.lastname ?? ''} {/* Appointment duration not available in notification payload! */}
        </SmallBody>
      </View>
      <HSpacer left={8} />
      <Icon type="next" size={sizes[24]} color={colors.DARK_BLUE} />
    </View>
  );
};

export default AppointmentRequestedNotification;
