import { Picker } from '@react-native-picker/picker';
import { flow } from 'fp-ts/function';
import * as React from 'react';
import { LegacyRef } from 'react';
import {
  Platform,
  TextInputProps,
  View,
  ViewProps,
  TextStyle,
  Text,
  TouchableOpacity,
  Modal,
  Pressable,
  ViewStyle,
} from 'react-native';
import uuid from 'react-native-uuid';

import { colors } from '../../styles';
import { GEYSER, COMET, RADICAL_RED, DARK_BLUE } from '../../styles/colors';
import Button from '../Button';
import Icon from '../Icon';
import ModalOverlay from '../ModalOverlay';
import Spacer from '../Spacer';

type StyledViewProps = React.PropsWithChildren<{
  isError?: boolean;
  isFocused?: boolean;
  rounded?: boolean;
}>;

const StyledView: React.FC<StyledViewProps & ViewProps> = ({
  isError,
  isFocused,
  rounded,
  style,
  children,
  ...props
}) => {
  return (
    <View
      style={[
        {
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: isError ? RADICAL_RED : isFocused ? DARK_BLUE : GEYSER,
          backgroundColor: colors.WHITE,
          borderRadius: rounded ? 20 : 5,
          alignItems: 'center',
          height: 45,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          position: 'relative',
          overflow: 'hidden',
        },
        style,
      ]}
      {...props}
    >
      {children}
    </View>
  );
};

type GenerateStyle = {
  placeholderColor: string;
  isPlaceholder: boolean;
  style?: ViewStyle;
  iconContainerStyle?: ViewStyle;
};

const generateStyle = ({ placeholderColor, style, isPlaceholder }: GenerateStyle) => ({
  fontSize: 16,
  fontFamily: 'open-sans',
  height: 43,
  color: isPlaceholder ? placeholderColor : '#172B4D',
  borderWidth: 0,
  ...(Platform.OS === 'web' ? { appearance: 'none' } : {}),
  ...style,
});

const isUndefined = (value: unknown): value is undefined => typeof value === 'undefined';

const DropdownIcon: React.FC = () => <Icon type="dropdown" />;

export type SelectItem<A> = {
  readonly value: A;
  readonly label: string;
};

type Primitive = number | string | boolean | bigint | symbol | null | undefined;

/**
 * Limiting item type to primitive types for now as Picker component is only doing object identity comparison
 * which may lead to infinite render cycle
 */
export type Props<A extends Primitive> = {
  selectRef?: LegacyRef<Picker<A>>;
  value?: A;
  items: SelectItem<A>[];
  isError?: boolean;
  isFocused?: boolean;
  placeholder?: string;
  placeholderColor?: string;
  style?: ViewStyle;
  selectStyle?: TextStyle;
  iconContainerStyle?: ViewStyle;
  rounded?: boolean;
  editable?: TextInputProps['editable'];
  onChange?: (value: A) => void;
  onOpen?: () => void;
  onClose?: () => void;
  closeText?: string;
  LeftComponent?: React.ComponentType;
  RightComponent?: React.ComponentType;
};

const getPlaceholderValue = () => {
  const placeholderValue = uuid.v4();
  return Array.isArray(placeholderValue) ? placeholderValue.join('') : placeholderValue;
};

function Select<A extends Primitive>({
  selectRef,
  value,
  items,
  isError,
  placeholder,
  placeholderColor = COMET,
  style,
  selectStyle,
  iconContainerStyle,
  rounded = false,
  editable = true,
  onChange = () => undefined,
  onOpen = () => undefined,
  onClose = () => undefined,
  closeText = 'Gata',
  LeftComponent,
  RightComponent = DropdownIcon,
}: Props<A>): React.ReactElement {
  const [isOpen, setIsOpen] = React.useState(false);

  const [tempValue, setTempValue] = React.useState(value);

  const placeholderValue = React.useRef(getPlaceholderValue());

  const isPlaceholder = (value: A | string | undefined): value is string => value === placeholderValue.current;

  const styles = React.useMemo(
    () =>
      generateStyle({
        placeholderColor,
        style: selectStyle,
        iconContainerStyle,
        isPlaceholder: isUndefined(value),
      }),
    [isError, value, placeholder],
  );

  const onOpenHandle = () => {
    setIsOpen(true);
    setTempValue(value ?? items[0]?.value);
    onOpen();
  };

  const onCloseHandle = () => {
    setIsOpen(false);
    onClose();
  };

  const selectedLabel = () => {
    const selectedItem = items.find((item) => item.value === value);
    return selectedItem?.label ?? placeholder ?? items[0]?.label ?? '';
  };

  const itemsWithPlaceholder = React.useMemo(() => {
    if (placeholder && (isPlaceholder(value) || !items.some((item) => item.value === value))) {
      return [{ label: placeholder, value: placeholderValue.current }, ...items];
    }

    return items;
  }, [items, placeholder, value]);

  return (
    <>
      <StyledView rounded={rounded} isError={isError} style={style} isFocused={isOpen}>
        {LeftComponent && <LeftComponent />}
        <View
          style={{
            flex: 1,
            height: styles.height,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            position: 'relative',
          }}
        >
          {Platform.OS === 'ios' ? (
            <TouchableOpacity
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                height: styles.height,
                flexDirection: 'row',
                ...(!editable && { backgroundColor: colors.ATHENS_GRAY }),
              }}
              disabled={!editable}
              onPress={onOpenHandle}
            >
              <Text
                style={{
                  fontSize: styles.fontSize,
                  fontFamily: styles.fontFamily,
                  color: styles.color,
                  paddingLeft: 8,
                  flex: 1,
                }}
              >
                {selectedLabel()}
              </Text>
              {RightComponent && (
                <View
                  style={{
                    backgroundColor: style?.backgroundColor ?? colors.WHITE,
                    height: styles.height,
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 4,
                    paddingRight: 8,
                    ...(!editable && { backgroundColor: colors.ATHENS_GRAY }),
                  }}
                >
                  <RightComponent />
                </View>
              )}
            </TouchableOpacity>
          ) : (
            <>
              <Picker
                ref={selectRef}
                dropdownIconColor={style?.backgroundColor ?? colors.WHITE}
                selectedValue={value}
                onValueChange={(_, itemIndex) => {
                  const itemValue = itemsWithPlaceholder[itemIndex].value;
                  if (!isPlaceholder(itemValue)) {
                    onChange(itemValue);
                  }
                }}
                enabled={editable}
                style={[{ flex: 1, opacity: 0, height: styles.height }]}
                itemStyle={styles}
              >
                {itemsWithPlaceholder.map((item) => (
                  <Picker.Item key={`${item.label}-${String(item.value)}`} label={item.label} value={item.value} />
                ))}
              </Picker>
              <View
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  ...(!editable && { backgroundColor: colors.ATHENS_GRAY }),
                }}
                pointerEvents="none"
              >
                <Text
                  style={{
                    fontSize: styles.fontSize,
                    fontFamily: styles.fontFamily,
                    color: styles.color,
                    paddingLeft: 8,
                    flex: 1,
                  }}
                >
                  {selectedLabel()}
                </Text>
                {RightComponent && (
                  <View
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingLeft: 4,
                      paddingRight: 8,
                      ...(!editable && { backgroundColor: colors.ATHENS_GRAY }),
                    }}
                  >
                    <RightComponent />
                  </View>
                )}
              </View>
            </>
          )}
        </View>
      </StyledView>
      <Modal animationType="slide" transparent={true} visible={isOpen} onRequestClose={onCloseHandle}>
        <ModalOverlay>
          <Pressable style={{ flex: 1 }} onPress={onCloseHandle} />
          <View style={{ backgroundColor: 'white' }}>
            <Picker
              selectedValue={tempValue}
              onValueChange={(_, itemIndex) => {
                const itemValue = itemsWithPlaceholder[itemIndex].value;
                if (!isPlaceholder(itemValue)) {
                  setTempValue(itemValue);
                }
              }}
            >
              {itemsWithPlaceholder.map((item) => (
                <Picker.Item
                  key={`${item.label}-${String(item.value)}`}
                  label={item.label}
                  value={item.value}
                  style={{ fontSize: styles.fontSize }}
                />
              ))}
            </Picker>
            <Button
              size="small"
              backgroundColor={colors.WHITE}
              color={colors.DARK_BLUE}
              text={closeText}
              onClick={flow(() => {
                if (typeof tempValue !== 'undefined') {
                  onChange(tempValue);
                }
              }, onCloseHandle)}
            />
            <Spacer size={32} />
          </View>
        </ModalOverlay>
      </Modal>
    </>
  );
}

export default Select;
