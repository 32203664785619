import { ExtendedCalculator } from './extendedCalculator';
import * as t from 'io-ts';

export interface NegativeBrand {
  readonly Negative: unique symbol;
}

export type Negative<Num> = t.Branded<Num, NegativeBrand>;

export type NegativeModule<Num> = {
  /**
   * Checks if value is a valid Negative
   */
  readonly is: (a: Num) => a is Negative<Num>;
  /**
   * Parses a Num to a Negative or throws when invalid.
   */
  readonly unsafeFrom: (n: Num) => Negative<Num>;
  /**
   * Build new JSON codec for Negative<Num>
   */
  readonly json: <O, I>(codec: t.Type<Num, O, I>) => t.Type<Negative<Num>, O, I>;
};

const build = <Num>(num: ExtendedCalculator<Num>): NegativeModule<Num> => {
  const is = (a: Num): a is Negative<Num> => num.lessThan(a, num.zero());

  const unsafeFrom = (n: Num): Negative<Num> => {
    if (!is(n)) {
      throw new Error('Invalid Negative<Num> value');
    }

    return n;
  };

  const json = <O, I>(codec: t.Type<Num, O, I>): t.Type<Negative<Num>, O, I> => t.brand(codec, is, `Negative`);

  return {
    is: is,
    unsafeFrom: unsafeFrom,
    json: json,
  };
};

export const Negative = {
  build,
};
