/**
 * @since 0.5.0
 */
import * as t from 'io-ts';
import { pipe } from 'fp-ts/es6/pipeable';
import { chain } from 'fp-ts/es6/Either';
/**
 * @example
 * import { NumberFromString } from 'io-ts-types/es6/NumberFromString'
 *
 * NumberFromString.decode('1') // right(1)
 * NumberFromString.decode('1.1') // right(1.1)
 *
 * @since 0.5.0
 */
export var NumberFromString = new t.Type('NumberFromString', t.number.is, function (u, c) {
    return pipe(t.string.validate(u, c), chain(function (s) {
        var n = +s;
        return isNaN(n) || s.trim() === '' ? t.failure(u, c) : t.success(n);
    }));
}, String);
