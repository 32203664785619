/**
 * @since 0.5.2
 */
import { iso } from 'newtype-ts';
import * as t from 'io-ts';
import { pipe } from 'fp-ts/es6/pipeable';
import { map } from 'fp-ts/es6/Either';
/**
 * Returns a codec from a newtype
 *
 * @example
 * import { fromNewtype } from 'io-ts-types/es6/fromNewtype'
 * import * as t from 'io-ts'
 * import { right } from 'fp-ts/es6/Either'
 * import { PathReporter } from 'io-ts/es6/PathReporter'
 * import { Newtype, iso } from 'newtype-ts'
 *
 * interface Token extends Newtype<{ readonly Token: unique symbol }, string> {}
 *
 * const T = fromNewtype<Token>(t.string)
 *
 * assert.deepStrictEqual(T.decode('sometoken'), right(iso<Token>().wrap('sometoken')))
 * assert.deepStrictEqual(PathReporter.report(T.decode(42)), ['Invalid value 42 supplied to : fromNewtype(string)'])
 *
 * @since 0.5.2
 */
export function fromNewtype(codec, name) {
    if (name === void 0) { name = "fromNewtype(" + codec.name + ")"; }
    var i = iso();
    return new t.Type(name, function (u) { return codec.is(u); }, function (u, c) {
        return pipe(codec.validate(u, c), map(i.wrap));
    }, function (a) { return codec.encode(i.unwrap(a)); });
}
