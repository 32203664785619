import { Lens } from 'monocle-ts';
function getProps(codec) {
    switch (codec._tag) {
        case 'InterfaceType':
            return codec.props;
        case 'ExactType':
            return getProps(codec.type);
    }
}
var fromProp = Lens.fromProp();
/**
 * Return a `Lens` for each prop
 *
 * @example
 * import * as t from 'io-ts'
 * import { getLenses } from 'io-ts-types/es6/getLenses'
 *
 * const Person = t.type({
 *   name: t.string,
 *   age: t.number
 * })
 *
 * const lenses = getLenses(Person)
 * assert.strictEqual(lenses.age.get({ name: 'Giulio', age: 44 }), 44)
 *
 * @since 0.5.0
 */
export function getLenses(codec) {
    var r = {};
    for (var k in getProps(codec)) {
        r[k] = fromProp(k);
    }
    return r;
}
