/**
 * @since 0.5.0
 */
import * as t from 'io-ts';
import { pipe } from 'fp-ts/es6/pipeable';
import { chain } from 'fp-ts/es6/Either';
/**
 * @example
 * import { DateFromNumber } from 'io-ts-types/es6/DateFromNumber'
 * import { right } from 'fp-ts/es6/Either'
 *
 * const date = new Date(1973, 10, 30)
 * const input = date.getTime()
 * assert.deepStrictEqual(DateFromNumber.decode(input), right(date))
 *
 * @since 0.5.0
 */
export var DateFromNumber = new t.Type('DateFromNumber', function (u) { return u instanceof Date; }, function (u, c) {
    return pipe(t.number.validate(u, c), chain(function (n) {
        var d = new Date(n);
        return isNaN(d.getTime()) ? t.failure(u, c) : t.success(d);
    }));
}, function (a) { return a.getTime(); });
