import { Firstname, OptionalLastname } from '../common';
import { WorkerId } from '../workers';
import { PageId } from './page-id';
import { ProfileImage } from '@mero/shared-sdk/dist/assets';
import * as t from 'io-ts';

export const PageAutocompleteWorker = t.type(
  {
    _id: WorkerId.JSON,
    user: t.type({
      firstname: Firstname,
      lastname: OptionalLastname,
    }),
    profilePhoto: ProfileImage,
  },
  'PageAutocompleteWorker',
);

const Hightlight = t.type({
  value: t.string,
  matchLevel: t.string,
  matchedWords: t.array(t.string),
});

export const PageAutocomplete = t.type(
  {
    _id: PageId,
    slug: t.string,
    name: t.string,
    profilePhoto: ProfileImage,
    categoryIds: t.array(t.string),
    location: t.type({
      type: t.string,
      coordinates: t.array(t.number),
    }),
    city: t.union([t.string, t.undefined, t.null]),
    address: t.union([t.string, t.undefined, t.null]),
    workers: t.array(PageAutocompleteWorker),
    feedback: t.type({
      score: t.number,
      total: t.number,
    }),
    hasOnlinePaymentsEnabled: t.union([t.boolean, t.undefined, t.null]),
    _highlightResult: t.type({
      name: Hightlight,
      workers: t.union([
        t.undefined,
        t.array(t.type({ user: t.type({ firstname: Hightlight, lastname: Hightlight }) })),
      ]),
    }),
    hasPromoPrice: t.boolean,
  },
  'PageAutocomplete',
);

export type PageSearch = t.TypeOf<typeof PageAutocomplete>;

export const PageAutocompleteResults = t.type({
  hits: t.array(PageAutocomplete),
  queryId: t.string,
  totalHitCount: t.number,
});

export type PageAutocompleteResults = t.TypeOf<typeof PageAutocompleteResults>;
