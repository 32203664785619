import { UserPaymentsProApi } from '../userPaymentsProApi';
import * as UserPaymentsProJson from './userPaymentsProJson';
import { HttpClient, MeroUnits, Money, Paged, ScaledNumber, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const userPaymentsProHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): UserPaymentsProApi => {
  const { apiBaseUrl, http } = env;

  const enc = encodeURIComponent;
  const listAppointmentPaymentsDecoder = http.decode.response(
    UnknownApiError,
    Paged.json(t.array(UserPaymentsProJson.UserOrderPaymentPreviewC)),
  );
  const getUserOrderDetailsDecoder = http.decode.response(UnknownApiError, UserPaymentsProJson.UserOrderDetailsC);
  const getUserOrderPaymentDetailsDecoder = http.decode.response(
    UnknownApiError,
    UserPaymentsProJson.UserOrderPaymentDetailsC,
  );
  const refundPaymentDecoder = http.decode.response(UnknownApiError, t.unknown);

  return {
    listAppointmentPayments: async ({ pageId, appointmentId, occurrenceIndex }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${enc(pageId)}/appointments/${enc(appointmentId)}/user-payments`,
            params: {
              occurrenceIndex: occurrenceIndex,
            },
          },
          listAppointmentPaymentsDecoder,
        ),
      );
    },
    getUserOrderDetails: async ({ pageId, userOrderId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${enc(pageId)}/user-orders/${enc(userOrderId)}`,
          },
          getUserOrderDetailsDecoder,
        ),
      );
    },
    getUserOrderPaymentDetails: async ({ pageId, userOrderPaymentId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${enc(pageId)}/user-payments/${enc(userOrderPaymentId)}`,
          },
          getUserOrderPaymentDetailsDecoder,
        ),
      );
    },
    refundPayment: async ({ pageId, userOrderPaymentId, amount }) => {
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${enc(pageId)}/user-payments/${enc(userOrderPaymentId)}/refund`,
            data: {
              amount: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON).encode(amount),
            },
          },
          refundPaymentDecoder,
        ),
      );
    },
  };
};
