import { Unit } from './unit';
import { PositiveInt, Zero, JSONable, PositiveScaledNumber } from '@mero/shared-sdk';
import * as t from 'io-ts';

export { Spec } from './spec';
export { Unit } from './unit';

export type Any = {
  readonly unit: Unit;
  readonly value: PositiveScaledNumber;
  readonly exponent: PositiveInt | Zero;
};

export const JSON: t.Type<Any, JSONable> = t.type(
  {
    unit: Unit.JSON,
    value: PositiveScaledNumber.JSON,
    exponent: t.union([PositiveInt.JSON, Zero]),
  },
  `ProductMeasure`,
);
